/* eslint-disable @typescript-eslint/no-unused-vars */
import { useRecoilState } from "recoil";
import RoleSelector from "../../../components/Role/RoleSelector";
import SiteSelector from "../../../components/Site/SiteSelector";
import Typography from "../../../components/Typography";
import Blob from "../../../components/common/Blob";
import grid from "../../../styles/partials/_grid.module.scss";
import { jobCreationState } from "../../../state/atoms/jobCreationAtom";
import ButtonList from "../../../components/common/ButtonsList";
import { DatePicker } from "@mui/x-date-pickers";
import { InputAdornment, MenuItem, Select, TextField } from "@mui/material";
import dayjs from "dayjs";

function JEPeripherals() {
    const [jobData, setJobData] = useRecoilState(jobCreationState);

    const handleSiteChange = (event) => {
        const site_id = event.target.value;
        setJobData((prevJobData) => ({
            ...prevJobData,
            site_id,
        }));
    };

    const handleRoleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const role_id = event.target.value as number;
        setJobData((prevJobData) => ({
            ...prevJobData,
            role_id,
        }));
    };
    const handleContractType = (contract_type) => {
        setJobData((prevJobData) => ({
            ...prevJobData,
            contract_type,
        }));
    };

    const handleStartDateChange = (value) => {
        const start_date = dayjs(value);
        setJobData((prevJobData) => ({
            ...prevJobData,
            start_date,
        }));
    };
    return (
        <>
            <Blob title="Job Breakdown" size={"full"} padded_inner={false}>
                <div className={grid.form_inner}>
                    <div className={grid.form_header}>
                        <Typography variant="small">
                            Let us know more about the job role and where the
                            slinger will be taking their trial shift.
                        </Typography>
                    </div>

                    <div className={grid.row}>
                        <div className={grid.half}>
                            <label>Site:</label>
                            <SiteSelector
                                onChange={handleSiteChange}
                                default={jobData.site_id.toString()}
                            />
                            <Typography variant="small" className={[]}>
                                <a
                                    style={{
                                        float: "left",
                                        color: "grey",
                                        fontSize: "12px",
                                        marginTop: "8px",
                                    }}
                                    href="#"
                                >
                                    Create a new site
                                </a>
                            </Typography>
                        </div>
                        <div className={grid.half}>
                            <label>Role:</label>
                            <RoleSelector
                                onChange={handleRoleChange}
                                default={jobData.role_id.toString()}
                            />
                        </div>
                    </div>
                </div>
            </Blob>

            <div className={grid.row}>
                <div className={grid.full}>
                    <Blob
                        size="full"
                        title="Contract info"
                        padded_inner={false}
                    >
                        <div className={grid.form_inner}>
                            <div className={grid.form_header}>
                                <Typography variant="small">
                                    When is this role estimated to start and on
                                    what type of contract should the slinger
                                    expect to be employed.
                                </Typography>
                            </div>
                            <div className={grid.row}>
                                <div className={grid.half}>
                                    <label>Start Date</label>
                                    <DatePicker
                                        disablePast
                                        format="DD/M/YYYY"
                                        minDate={dayjs().add(8, "days")}
                                        defaultValue={jobData.start_date}
                                        onChange={(value) => {
                                            handleStartDateChange(value);
                                        }}
                                    />
                                </div>
                                <div className={grid.half}>
                                    <label>Contract Type</label>
                                    <ButtonList
                                        options={[
                                            {
                                                label: "Part Time",
                                                value: "Part Time",
                                            },
                                            {
                                                label: "Full Time",
                                                value: "Full Time",
                                            },
                                        ]}
                                        currentValues={[jobData.contract_type]}
                                        onClick={handleContractType}
                                    />
                                </div>
                            </div>
                        </div>
                    </Blob>
                </div>
            </div>
        </>
    );
}

export default JEPeripherals;
