import Button from "../Button";
import { Breakpoint, styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { PropsWithChildren } from "react";
import style from "./Modal.module.scss";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));
interface ModalPropsInterface {
    open: boolean;
    handleClose: any;
    actionOne: any | null;
    actionOneText: string;
    negativeAction: any | null;
    isLoading: boolean;
    negativeActionText: any | null;
    title: string;
    disabled: boolean;
    maxWidth: false | Breakpoint | undefined;
    error: boolean;
    form: string | undefined;
}

Modal.defaultProps = {
    maxWidth: "md",
    disabled: false,
    title: "",
    form: undefined,
    actionOne: null,
    actionOneText: null,
};
export default function Modal(props: PropsWithChildren<ModalPropsInterface>) {
    return (
        <div className={style.modal_container}>
            <BootstrapDialog
                maxWidth={props.maxWidth}
                onClose={props.handleClose}
                aria-labelledby="customized-dialog-title"
                open={props.open}
                fullWidth={true}
            >
                {props.title ? (
                    <DialogTitle
                        sx={{ m: 0, p: 2 }}
                        id="customized-dialog-title"
                    >
                        {props.title}
                    </DialogTitle>
                ) : (
                    ""
                )}
                <IconButton
                    aria-label="close"
                    onClick={props.handleClose}
                    sx={{
                        zIndex: "8!important",
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>{props.children}</DialogContent>
                {props.actionOne || props.negativeAction ? (
                    <DialogActions>
                        {props.negativeActionText ? (
                            <Button
                                type="error"
                                disabled={props.disabled}
                                onClick={props.negativeAction}
                            >
                                {props.negativeActionText}
                            </Button>
                        ) : (
                            ""
                        )}
                        {props.form ? (
                            <Button
                                type="submit"
                                form={props.form}
                                onClick={props.actionOne}
                                disabled={props.disabled}
                            >
                                {props.actionOneText}
                            </Button>
                        ) : props.actionOneText ? (
                            <Button
                                disabled={props.disabled}
                                onClick={props.actionOne}
                            >
                                {props.actionOneText}
                            </Button>
                        ) : (
                            ""
                        )}
                    </DialogActions>
                ) : (
                    ""
                )}
            </BootstrapDialog>
        </div>
    );
}
