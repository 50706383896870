import apiService from "../api.service";

export default async function getOneSite(site_id): Promise<Object> {
    const body = { id: site_id };
    try {
        const ApiService = new apiService();
        const responseData = await ApiService.post<Object>("sites/get", body);
        return responseData;
    } catch (error) {
        console.error("Error in post request:", error);
        throw error;
    }

    return {};
}
