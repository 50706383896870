/* eslint-disable unused-imports/no-unused-vars */
import SlingerContainer from "../SlingerContainer";
import grid from "../../styles/partials/_grid.module.scss";
import Blob from "../../components/common/Blob";

import Button from "../../components/common/Button";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Loading from "../../components/common/Loading";
import style from "./JobDecision.module.scss";
import SiteCard from "../../components/Site/SiteCard";
import ShiftsAPI from "../../api/shifts/shifts.client";
import { Alert, TextareaAutosize } from "@mui/material";
import Typography from "../../components/Typography";
import dayjs from "dayjs";
import { priceFromPennies } from "../../helpers/general";
import Modal from "../../components/common/Modal";
import JobDescriptionAPI from "../../api/jobDescriptions.service";
// Load Container with all the relevant pieces for the page component to load
export default function JobDecisionContainer() {
    const { action, sid, uid, oid } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [feedback, setFeedback] = useState(true);
    const [isError, setIsError] = useState("");
    const [rejectModalOpen, setRejectModalOpen] = useState(false);
    const [shift, setShift] = useState({});
    const [offer, setOffer] = useState({});

    const [job, setJob] = useState({});

    const load = async () => {
        try {
            setIsError(false);
            if (oid && uid) {
                await new ShiftsAPI().getShift(sid).then((shift) => {
                    setShift(shift);

                    setJob(shift.job_description);
                    setIsLoading(false);
                    JobDescriptionAPI.getOffer(oid, uid)
                        .then((response) => {
                            setOffer(response);
                        })
                        .catch((response) => {
                            setIsError(response.message);
                        });
                });
            } else {
                setIsError(
                    "We can't display this job properly as we're missing critical information. Please contact support if you think this is an error.",
                );
            }
        } catch (err: any) {
            setIsLoading(false);
            setIsError(true);
        }
    };

    const copyToClipboard = (text) => {
        navigator.clipboard
            .writeText(text)
            .then(() => {
                alert("Copied to clipboard");
            })
            .catch((err) => {
                console.error("Failed to copy: ", err);
            });
    };

    const rejectJob = () => {
        JobDescriptionAPI.reject(oid, uid, feedback).then((response) => {
            JobDescriptionAPI.getOffer(oid, uid).then((response) => {
                setOffer(response);

                setRejectModalOpen(false);
            });
        });
    };
    const acceptJob = () => {
        JobDescriptionAPI.accept(oid, uid).then((response) => {
            JobDescriptionAPI.getOffer(oid, uid).then((response) => {
                setOffer(response);
            });
        });
    };

    useEffect(() => {
        load();
    }, []);

    if (isLoading) {
        return <Loading />;
    }

    return (
        <SlingerContainer type={"registration"} title="Accept Job Offer">
            <div className={`${grid.maxout} ${grid.small}`}>
                <div className={grid.row}>
                    <div className={grid.quarter}></div>
                    <div className={grid.half}>
                        <Typography variant="h3">
                            🎉 Congratulations on the offer!
                        </Typography>
                        <Typography variant="p">
                            This is it - have a final look at the job offer
                            below and press to accept or reject.
                        </Typography>
                        <Blob title="What?" size="full">
                            <Typography
                                variant="small"
                                className={["mt-8", "mb-8"]}
                            >
                                The job offered to you by the business:
                            </Typography>
                            <table className={style.offergrid}>
                                <tr>
                                    <td>
                                        <strong>Role:</strong>{" "}
                                    </td>{" "}
                                    <td>{shift.role.name}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Contract:</strong>{" "}
                                    </td>{" "}
                                    <td>{job.contract_type}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Salary:</strong>{" "}
                                    </td>{" "}
                                    <td>
                                        {priceFromPennies(job.wage)}{" "}
                                        {job.wage_type}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Est. Start Date: </strong>
                                    </td>{" "}
                                    <td>
                                        {dayjs(job.start_date).format(
                                            "dddd DD MMMM",
                                        )}
                                    </td>
                                </tr>
                            </table>
                        </Blob>
                        <Blob title="Where?" size="full">
                            <SiteCard site={shift.site} />
                        </Blob>
                        {isError ? (
                            <Alert severity={"error"}>
                                <h4>Something went wrong: </h4>
                                <small>{isError}</small>
                            </Alert>
                        ) : offer.accepted == null &&
                          offer.rejected == null &&
                          !isError ? (
                            <>
                                <Alert severity="info">
                                    Please read the info above and confirm below
                                    - this will start the process of getting
                                    contracts completed and finalising your
                                    start date.
                                </Alert>

                                <div className={style.button_cont}>
                                    <Button type={"green"} onClick={acceptJob}>
                                        I accept the job!
                                    </Button>
                                    <Button
                                        type={"error"}
                                        onClick={function () {
                                            setRejectModalOpen(true);
                                        }}
                                    >
                                        I don't want the job
                                    </Button>
                                </div>
                            </>
                        ) : (
                            <Alert severity="info">
                                <Typography variant="small">
                                    This job offer has been actioned, if you
                                    think this is incorrect you can click below
                                    - referencing Job ID:{" "}
                                    <strong
                                        style={{
                                            width: "100%",
                                            marginTop: "8px",
                                            display: "inline-block",
                                        }}
                                    >
                                        {job.uuid}{" "}
                                        <span
                                            style={{
                                                cursor: "pointer",
                                                textDecoration: "underline",
                                                color: "blue",
                                                fontWeight: "400",
                                            }}
                                            onClick={() =>
                                                copyToClipboard(job.uuid)
                                            }
                                        >
                                            Copy
                                        </span>
                                    </strong>
                                </Typography>
                                <Button
                                    onClick={function () {
                                        Intercom("show");
                                    }}
                                    style={{ marginTop: "8px" }}
                                >
                                    Contact Support
                                </Button>
                            </Alert>
                        )}
                    </div>
                    <div className={grid.quarter}></div>
                </div>
            </div>
            <Modal
                negativeAction={rejectJob}
                negativeActionText={"Reject Job"}
                open={rejectModalOpen}
                maxWidth="sm"
                handleClose={function () {
                    setRejectModalOpen(false);
                }}
            >
                <div className={style.rejection}>
                    <h3>Let us know why?</h3>
                    <Typography variant="small" className={["mb-8", "mt-16"]}>
                        We appreciate circumstances may have changed, let us
                        know why:
                    </Typography>

                    <textarea
                        placeholder="Let us know why..."
                        onChange={function (e) {
                            setFeedback(e.target.value);
                        }}
                    ></textarea>

                    <Alert severity="info" style={{ marginTop: "8px" }}>
                        This feedback is used to inform the business so they can
                        offer the role to another slinger, it's also your chance
                        to let them know where they missed the mark on this
                        occasion.
                    </Alert>
                </div>
            </Modal>
        </SlingerContainer>
    );
}
