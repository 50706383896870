import { atom } from "recoil";
import dayjs from "dayjs";
import LSS from "../../helpers/localStorageService";
/* eslint-disable @typescript-eslint/no-unused-vars */
interface ScreeningQuestion {
    question: string;
}

interface JobDescription {
    uuid: string;
    site_id: string;
    site_name: string;
    business_id: string;
    role_id: number;
    role_name: string;
    description: string;
    contract_type: string;
    start_date: dayjs.Dayjs;
    preferred_skill_level: number;
    minimum_skill_level: number;
    wage: number;
    wage_type: string;
    metadata: any;
    endorsements: [];
    screening_questions: ScreeningQuestion[];
}

export const jobCreationState = atom<JobDescription>({
    key: "jobCreationState",
    default: {
        uuid: "",
        site_id: "",
        business_id: LSS().get("business_uuid"),
        role_id: 0,
        role_name: "",
        site_name: "",
        description: "",
        contract_type: "",
        start_date: dayjs().add(7, "days"),
        preferred_skill_level: 5,
        minimum_skill_level: 5,
        wage: 0,
        wage_type: "Annually",
        metadata: "{}",
        endorsements: [],
        screening_questions: [],
    },
});
