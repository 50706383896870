let translations: { [key: string]: string } = {};

export function setTranslations(allTranslations: { [key: string]: string }) {
    translations = allTranslations;
}

export function getTranslations(): { [key: string]: string } {
    return translations;
}

export function translate(placeholder: string): string {
    const translation = translations[placeholder];
    if (undefined === translation) {
        return placeholder;
    }
    return translation;
}
