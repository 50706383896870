import ApiService from "./api.service";
import LSS from "../helpers/localStorageService";

class EndorsementAPI {
    static apiService = new ApiService();

    static async getGrouped(): Promise<Object> {
        const cacheKey = "endorsementsBox";
        const cacheTimeKey = "endorsementsBoxTimestamp";
        const cacheDuration = 12 * 60 * 60 * 1000; // 12 hours in milliseconds

        const currentTime = Date.now();
        const storedTime = localStorage.getItem(cacheTimeKey);
        const storedData = localStorage.getItem(cacheKey);
        const useCache = true;
        if (
            storedTime &&
            storedData &&
            currentTime - parseInt(storedTime, 10) < cacheDuration &&
            useCache
        ) {
            console.log("Returning cached data");
            return JSON.parse(storedData);
        } else {
            console.log("Returning non-cached data");
            try {
                const body = { business_uuid: LSS().get("business_uuid") };
                const responseData = await this.apiService.post<Object>(
                    "endorsement/grouped",
                    body,
                );
                localStorage.setItem(cacheKey, JSON.stringify(responseData));
                localStorage.setItem(cacheTimeKey, currentTime.toString());

                return responseData;
            } catch (error) {
                console.error("Error in post request:", error);
                throw error;
            }
        }
    }
}

export default EndorsementAPI;
