import React, { useCallback, useEffect, useState } from "react";
import Blob from "../../common/Blob";

import style from "./QuestionManager.module.scss";
import { TextField, Tooltip } from "@mui/material";
import Button from "../../common/Button";
import Typography from "../../Typography";
import { useRecoilState } from "recoil";
import { jobCreationState } from "../../../state/atoms/jobCreationAtom";

const debounce = (func: Function, delay: number) => {
    let timeoutId: ReturnType<typeof setTimeout>;
    return (...args: any[]) => {
        if (timeoutId) clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            func(...args);
        }, delay);
    };
};

function QuestionManager() {
    const [questions, setQuestions] = useState([
        { uuid: "", jd_uuid: "", question: "" },
    ]);
    const [jobData, setJobData] = useRecoilState(jobCreationState);

    // Synchronize local state with Recoil state on initial load
    useEffect(() => {
        if (jobData.screening_questions.length > 0) {
            setQuestions(jobData.screening_questions);
        }
    }, [jobData.screening_questions]);

    // Update Recoil state whenever questions change
    const debouncedUpdateRecoilState = useCallback(
        debounce((updatedQuestions) => {
            setJobData((prevJobData) => ({
                ...prevJobData,
                screening_questions: updatedQuestions,
            }));
        }, 500),
        [setJobData],
    );

    useEffect(() => {
        debouncedUpdateRecoilState(questions);
    }, [questions, debouncedUpdateRecoilState]);

    const addQuestion = () => {
        if (questions.length < 5) {
            setQuestions([
                ...questions,
                { uuid: "", jd_uuid: jobData.uuid, question: "" },
            ]);
        }
    };

    const deleteQuestion = (index: number) => {
        if (questions.length > 1) {
            setQuestions(questions.filter((_, i) => i !== index));
        }
    };

    const handleQuestionChange = (index: number, value: string) => {
        const newQuestions = questions.slice();
        newQuestions[index] = { ...newQuestions[index], question: value };
        setQuestions(newQuestions);
    };

    return (
        <Blob title="Screening Questions" size={"full"} padded_inner={false}>
            <div className={style.question_header}>
                <Typography variant="small">
                    These questions will be required to be answered by any
                    applicants that accept a trial shift. Their answers will be
                    visible to you to help with the pre-screening.
                </Typography>
            </div>
            <div className={style.question_list}>
                {questions.map((question, index) => (
                    <div className={style.question} key={index}>
                        <label>Question {index + 1}</label>
                        <div className={style.question_row}>
                            <TextField
                                type="text"
                                fullWidth
                                sx={{ "& fieldset": { border: "none" } }}
                                inputProps={{ disableUnderline: true }}
                                variant="outlined"
                                value={question.question}
                                onChange={(e) =>
                                    handleQuestionChange(index, e.target.value)
                                }
                            />
                            <div className={style.question_delete}>
                                <Tooltip
                                    title="Delete Question"
                                    placement="right"
                                    arrow
                                >
                                    <button
                                        onClick={() => deleteQuestion(index)}
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="#000000"
                                            version="1.1"
                                            width="800px"
                                            height="800px"
                                            viewBox="0 0 408.483 408.483"
                                        >
                                            <g>
                                                <g>
                                                    <path d="M87.748,388.784c0.461,11.01,9.521,19.699,20.539,19.699h191.911c11.018,0,20.078-8.689,20.539-19.699l13.705-289.316    H74.043L87.748,388.784z M247.655,171.329c0-4.61,3.738-8.349,8.35-8.349h13.355c4.609,0,8.35,3.738,8.35,8.349v165.293    c0,4.611-3.738,8.349-8.35,8.349h-13.355c-4.61,0-8.35-3.736-8.35-8.349V171.329z M189.216,171.329    c0-4.61,3.738-8.349,8.349-8.349h13.355c4.609,0,8.349,3.738,8.349,8.349v165.293c0,4.611-3.737,8.349-8.349,8.349h-13.355    c-4.61,0-8.349-3.736-8.349-8.349V171.329L189.216,171.329z M130.775,171.329c0-4.61,3.738-8.349,8.349-8.349h13.356    c4.61,0,8.349,3.738,8.349,8.349v165.293c0,4.611-3.738,8.349-8.349,8.349h-13.356c-4.61,0-8.349-3.736-8.349-8.349V171.329z" />
                                                    <path d="M343.567,21.043h-88.535V4.305c0-2.377-1.927-4.305-4.305-4.305h-92.971c-2.377,0-4.304,1.928-4.304,4.305v16.737H64.916    c-7.125,0-12.9,5.776-12.9,12.901V74.47h304.451V33.944C356.467,26.819,350.692,21.043,343.567,21.043z" />
                                                </g>
                                            </g>
                                        </svg>
                                    </button>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className={style.question_gutter}>
                <Typography variant="small">
                    {questions.length} of 5 questions
                </Typography>
                <Button disabled={questions.length >= 5} onClick={addQuestion}>
                    Add Question
                </Button>
            </div>
        </Blob>
    );
}

export default QuestionManager;
