import React, { createContext, useContext, useState, ReactNode } from "react";
import QRModal from "../components/common/Modals/QRModal";
import JobOfferModal from "../components/common/Modals/JobOfferModal";
import ArchiveJobModal from "../components/common/Modals/ArchiveJobModal";
import ProfileModal from "../components/common/Modals/ProfileModal";
import RequestNewSlinger from "../components/common/Modals/RequestNewSlinger";
import EndorsementsModal from "../components/common/Modals/EndorsementsModal";
import ShiftCreationModal from "../components/common/Modals/ShiftCreationModal";

export enum ModalType {
    QR = "QR",
    JobOffer = "JobOffer",
    Profile = "Profile",
    ArchiveJob = "ArchiveJob",
    RequestNew = "RequestNew",
    Endorsements = "Endorsements",
    ShiftCreate = "ShiftCreate",
}

interface ModalProps {
    type: ModalType;
    props: any;
}

interface ModalContextProps {
    openModal: (type: ModalType, props: any) => void;
    closeModal: () => void;
}

const ModalContext = createContext<ModalContextProps | undefined>(undefined);

export const useModal = () => {
    const context = useContext(ModalContext);
    if (!context) {
        throw new Error("useModal must be used within a ModalProvider");
    }
    return context;
};

interface ModalProviderProps {
    children: ReactNode;
}

const ModalProvider: React.FC<ModalProviderProps> = ({ children }) => {
    const [modal, setModal] = useState<ModalProps | null>(null);

    const openModal = (type: ModalType, props: any) => {
        setModal({ type, props });
    };

    const closeModal = () => {
        setModal(null);
    };

    return (
        <ModalContext.Provider value={{ openModal, closeModal }}>
            {children}
            {modal && modal.type === ModalType.QR && (
                <QRModal open={true} onClose={closeModal} {...modal.props} />
            )}
            {modal && modal.type === ModalType.ArchiveJob && (
                <ArchiveJobModal
                    open={true}
                    onClose={closeModal}
                    {...modal.props}
                />
            )}
            {modal && modal.type === ModalType.Endorsements && (
                <EndorsementsModal
                    open={true}
                    onClose={closeModal}
                    {...modal.props}
                />
            )}
            {modal && modal.type === ModalType.RequestNew && (
                <RequestNewSlinger
                    open={true}
                    onClose={closeModal}
                    {...modal.props}
                />
            )}
            {modal && modal.type === ModalType.Profile && (
                <ProfileModal
                    open={true}
                    onClose={closeModal}
                    {...modal.props}
                />
            )}
            {modal && modal.type === ModalType.JobOffer && (
                <JobOfferModal
                    open={true}
                    onClose={closeModal}
                    {...modal.props}
                />
            )}
            {modal && modal.type === ModalType.ShiftCreate && (
                <ShiftCreationModal
                    open={true}
                    onClose={closeModal}
                    {...modal.props}
                />
            )}
        </ModalContext.Provider>
    );
};

export default ModalProvider;
