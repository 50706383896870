import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
import Main from "../../router";
import HotjarScript from "../../helpers/hotjar";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ReactGA from "react-ga4";
import ModalProvider from "../../hooks/ModalContext";

export default function App() {
    if (process.env.REACT_APP_ENV === "production") {
        const TRACKING_ID = "G-WEV5JXR8SZ";
        ReactGA.initialize(TRACKING_ID);
    }
    return (
        <BrowserRouter>
            <RecoilRoot>
                <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale={"en-gb"}
                >
                    <HotjarScript />

                    <ModalProvider>
                        <Main />
                    </ModalProvider>
                </LocalizationProvider>
            </RecoilRoot>
        </BrowserRouter>
    );
}
