import style from "./ButtonList.module.scss";
interface ButtonListProps {
    options: { label: string; value: number | string }[];
    currentValues: [];
    onClick: (value: number) => void;
}

ButtonsList.defaultProps = {
    options: [
        { label: "Yes", value: 1 },
        { label: "No", value: 0 },
    ],
    currentValues: [],
};
function ButtonsList(props: ButtonListProps) {
    return (
        <div className={style.button_list}>
            {props.options.map(({ label, value }) => (
                <button
                    key={value}
                    className={
                        props.currentValues.includes(value) ? style.active : ""
                    }
                    onClick={() => props.onClick(value)}
                >
                    {label}
                </button>
            ))}
        </div>
    );
}
export default ButtonsList;
