import style from "./JDListItem.module.scss";
import Button from "../../../common/Button";
import { Alert, Rating } from "@mui/material";
import table from "../../../../styles/_table.module.scss";
import Avatar from "../../../common/Avatar";
import getImageURL from "../../../../helpers/imageUtils";
import Typography from "../../../Typography";
import Pill from "../../../common/Pill";
import { ReactComponent as Archive } from "../../../../assets/v2/folder.svg";
import { useModal, ModalType } from "../../../../hooks/ModalContext";
interface JDListItemPropsInterface {
    job: {};
    withWage: boolean;
    withDate: boolean;
    displayOnly: boolean;
}

JDListItem.defaultProps = {
    withWage: false,
    withDate: false,
    displayOnly: false,
};
function JDListItem(props: JDListItemPropsInterface) {
    const classes = [];
    const { openModal } = useModal();
    classes.push(style.shift_list_item);
    if (props.displayOnly) {
        classes.push(style.view_only);
    }

    return (
        <div className={style.listItem}>
            <div className={style.jobBlock}>
                {props.job.site.file1 !== undefined ? (
                    <Avatar
                        size="tiny"
                        image={getImageURL(props.job.site.file1)}
                    ></Avatar>
                ) : (
                    ""
                )}
                <div className={style.details}>
                    <strong>{props.job.role.name}</strong>
                    <small>{props.job.site.name}</small>
                </div>
            </div>
            <div className={style.contractBlock}>
                <strong>{props.job.contract_type}</strong>
                <small>
                    £{(props.job.wage / 100).toFixed(2)} {props.job.wage_type}
                </small>
            </div>
            <div className={style.statusBlock}>
                {props.job.archived_at ? (
                    <Pill label="Closed" type={"greyed"} />
                ) : props.job.stats.upcoming > 0 ? (
                    <Pill label="Active" type={"primary"} />
                ) : (
                    <Pill label="No upcoming shifts" type={"tertiary"} />
                )}
            </div>
            <div className={style.actionBlock}>
                <Button href={"/trial-shift/view/" + props.job.uuid}>
                    View
                </Button>
                {props.job.archived_at !== null ||
                props.job.stats.upcoming > 0 ? (
                    ""
                ) : (
                    <button
                        onClick={() =>
                            openModal(ModalType.ArchiveJob, {
                                job_uuid: props.job.uuid,
                            })
                        }
                    >
                        <Archive />
                    </button>
                )}
            </div>
        </div>
    );
}
export default JDListItem;
