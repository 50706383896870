import style from "./BusinessSwitcher.module.scss";
import LSS from "../../../helpers/localStorageService";
import getImageURL from "../../../helpers/imageUtils";
import cookies from "../../../helpers/cookies";
import { useState } from "react";

export default function BusinessSwitcher() {
    const [isOpen, setIsOpen] = useState(false);

    const toggleModal = () => {
        setIsOpen(!isOpen);
    };
    const setBusiness = (business_id) => {
        cookies.setCookie("active_business", business_id);
        LSS().set("business_uuid", business_id);
        LSS()
            .get("businesses")
            .map((business) => {
                if (business_id == business.uuid) {
                    LSS().set("business", business);
                    LSS().set("business_permissions", business.permissions);
                }
            });
        window.location.href = "/dashboard";
    };
    return (
        <>
            <div
                className={`${style.greyout} ${isOpen ? style.active : ""}`}
            ></div>
            <div
                className={`${style.businessSwitcher} ${
                    isOpen ? style.active : ""
                }`}
            >
                {LSS().get("businesses").length > 1 ? (
                    <div className={style.list}>
                        <small>Switch business:</small>
                        {LSS()
                            .get("businesses")
                            .map((business) => {
                                return (
                                    <div
                                        key={business.uuid}
                                        className={style.item}
                                        onClick={function () {
                                            setBusiness(business.uuid);
                                        }}
                                    >
                                        <div
                                            className={style.img}
                                            style={{
                                                backgroundImage: `url('${getImageURL(
                                                    business.file,
                                                )}')`,
                                            }}
                                        ></div>
                                        <span>{business.name}</span>
                                    </div>
                                );
                            })}
                    </div>
                ) : (
                    ""
                )}
                <div className={`${style.button} `} onClick={toggleModal}>
                    {LSS().get("business") ? (
                        <div
                            className={style.img}
                            style={{
                                backgroundImage: `url('${getImageURL(
                                    LSS().get("business").file,
                                )}')`,
                            }}
                        ></div>
                    ) : (
                        ""
                    )}
                    <span>{LSS().get("business").name}</span>
                    {LSS().get("businesses").length > 1 ? (
                        <span className={style.clicker}>
                            <svg
                                width="8"
                                height="13"
                                viewBox="0 0 8 13"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M1 4L4 1L7 4" stroke="#282828" />
                                <path
                                    d="M1 8.5L4 11.5L7 8.5"
                                    stroke="#282828"
                                />
                            </svg>
                        </span>
                    ) : (
                        ""
                    )}
                </div>
            </div>
        </>
    );
}
