/* eslint-disable unused-imports/no-unused-vars */
import { useEffect, useMemo, useState } from "react";

import Loading from "../common/Loading";
import style from "./TimesheetManager.module.scss";
import table from "../../styles/_table.module.scss";
import grid from "../../styles/partials/_grid.module.scss";
import dayjs from "dayjs";
import Typography from "../Typography";
import Button from "../common/Button";
import Avatar from "../common/Avatar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCheckCircle,
    faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import getImageURL from "../../helpers/imageUtils";

interface ShiftListPropsInterface {
    shifts: [];
    passed: boolean;
}

function TimesheetManager(props: ShiftListPropsInterface) {
    const [isLoading, setIsLoading] = useState(true);
    const groupedShifts = useMemo(() => {
        const groups = new Map();
        props.shifts.forEach((shift) => {
            const date = shift.shift_date;
            if (!groups.has(date)) {
                groups.set(date, []);
            }
            groups.get(date).push(shift);
        });
        return Array.from(groups.entries());
    }, [props.shifts]);

    const load = () => {
        setIsLoading(false);
    };
    useEffect(() => {
        load();
    }, []);

    if (isLoading) {
        return <Loading />;
    }
    if (groupedShifts.length == 0) {
        return (
            <div className={style.shift_list}>
                <div style={{ textAlign: "center", paddingBottom: "16px" }}>
                    <Typography variant="small" className={["mb-16", "mt-16"]}>
                        No outstanding timesheets
                    </Typography>
                    <Button href="/timesheets/completed">
                        See completed timesheets
                    </Button>
                </div>
            </div>
        );
    }
    return (
        <div className={style.shift_list}>
            <table className={table.timesheet_table}>
                <thead>
                    <tr>
                        <th className={table.status}></th>
                        <th className={table.site}>Site</th>
                        <th className={table.slinger}>Slinger</th>
                        <th className={table.shift_date}>Shift Date</th>

                        <th className={table.hours}>Original Hours</th>
                        <th className={table.hours}>Submitted Hours</th>
                        <th className={table.hours}>Status</th>
                        <th className={table.column_actions}>...</th>
                    </tr>
                </thead>
                {groupedShifts.map(([date, shifts]) => (
                    <>
                        <tr className={table.breaker}>
                            <td colSpan={8}>
                                {dayjs(date).format("DD/MM/YYYY")}
                            </td>
                        </tr>
                        {shifts.map((shift) => {
                            return (
                                <tr key={shift.uuid}>
                                    <td className={table.status}>
                                        {shift.requires_attention ? (
                                            <>
                                                <a
                                                    href={
                                                        "/shift/review/" +
                                                        shift.uuid
                                                    }
                                                    className={grid.coverall}
                                                ></a>
                                                <FontAwesomeIcon
                                                    color="red"
                                                    icon={faExclamationCircle}
                                                />
                                            </>
                                        ) : (
                                            <>
                                                <a
                                                    href={
                                                        "/shift/view/" +
                                                        shift.uuid
                                                    }
                                                    className={grid.coverall}
                                                ></a>
                                                <FontAwesomeIcon
                                                    color="green"
                                                    icon={faCheckCircle}
                                                />
                                            </>
                                        )}
                                    </td>
                                    <td className={table.slinger}>
                                        <div className={grid.row}>
                                            <Avatar
                                                size={"tiny"}
                                                image={getImageURL(
                                                    shift.site?.profile_picture,
                                                )}
                                            ></Avatar>
                                            <div>
                                                {shift.site.name}
                                                <Typography variant="small">
                                                    {shift.site.address_line1},{" "}
                                                    {shift.site.post_code}
                                                </Typography>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className={grid.row}>
                                            <Avatar
                                                size={"tiny"}
                                                image={getImageURL(
                                                    shift.slinger
                                                        .profile_picture,
                                                )}
                                            ></Avatar>
                                            <div>
                                                {shift.slinger.first_name}{" "}
                                                {shift.slinger.last_name}
                                                <Typography variant="small">
                                                    {shift.role.name}
                                                </Typography>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            {dayjs(shift.shift_date).format(
                                                "DD/MM/YYYY",
                                            )}{" "}
                                            <Typography variant="small">
                                                {dayjs(shift.start_time).format(
                                                    "HH:mm",
                                                )}{" "}
                                                -{" "}
                                                {dayjs(shift.end_time).format(
                                                    "HH:mm",
                                                )}
                                            </Typography>
                                        </div>
                                    </td>
                                    <td>{shift.original_hours} hrs</td>
                                    <td>{shift.freelancer_hours} hrs</td>
                                    <td>
                                        {shift.finalised_hours ? (
                                            <Typography variant="small">
                                                {shift.message}
                                            </Typography>
                                        ) : (
                                            <>
                                                <Typography variant="small">
                                                    {shift.message}
                                                </Typography>
                                            </>
                                        )}
                                    </td>
                                    <td className={table.column_actions}>
                                        {shift.requires_attention ? (
                                            <Button type="error">
                                                Submit Timesheet →
                                            </Button>
                                        ) : (
                                            <Button>View Shift →</Button>
                                        )}
                                    </td>
                                </tr>
                            );
                        })}
                    </>
                ))}
            </table>
        </div>
    );
}

TimesheetManager.defaultProps = {
    shifts: [],
};

export default TimesheetManager;
