import { CSSProperties } from "react";
import style from "./Avatar.module.scss";
import getImageURL from "../../../helpers/imageUtils";

interface AvatarProps {
    image: string;
    size: string;
    style: CSSProperties;
}

Avatar.defaultProps = {
    size: "standard",
    style: null,
};

const sizeClassMapping: { [key: string]: string } = {
    tiny: style.tiny,
    small: style.small,
    half: style.half,
    fixed_height: style.fixed_height,
};

export default function Avatar(props: AvatarProps) {
    const classes = [style.avatar];
    const sizeClass = sizeClassMapping[props.size];
    if (sizeClass) {
        classes.push(sizeClass);
    }
    return (
        <div
            className={classes.join(" ")}
            style={{ backgroundImage: `url('${props.image}')` }}
        >
            <svg width="400" height="400">
                <rect width="400" height="400" />
            </svg>
        </div>
    );
}
