import { useEffect } from "react";
import Typography from "../../components/Typography";
import Blob from "../../components/common/Blob";

interface ErrorPropsInterface {
    onErrorReload: () => Promise<void> | null;
    className?: string;
    message: string;
}

function ErrorPage(props: ErrorPropsInterface) {
    useEffect(() => {
        document.title = "Slinger Business | Error: " + props.message;
    }, []);

    return (
        <Blob title="Something went wrong" size="full">
            <Typography variant="small" className={["mt-16", "mb-8"]}>
                {props.message
                    ? props.message
                    : "Something went wrong - contact support if this problem persists."}
            </Typography>
            <Typography variant="small" className={["gutter"]}>
                Refresh and try again or alternatively click here to go back to
                the dashboard <a href="/dashboard">Back to Dashboard</a>
            </Typography>
        </Blob>
    );
}

ErrorPage.defaultProps = {
    className: "",
    onErrorReload: null,
};

export default ErrorPage;
