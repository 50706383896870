import React, { useState, useEffect } from "react";
import { Modal as MUIModal, Box } from "@mui/material";
import JobDescriptionAPI from "../../../../api/jobDescriptions.service";
import style from "./EndorsementsModal.module.scss";
import Button from "../../Button";
import { useRecoilState } from "recoil";
import { jobCreationState } from "../../../../state/atoms/jobCreationAtom";
import EndorsementAPI from "../../../../api/endorsement.service";

interface ModalProps {
    open: boolean;
    onClose: () => void;
    job_uuid: string;
}

const EndorsementsModal: React.FC<ModalProps> = ({
    open,
    onClose,
    job_uuid,
}) => {
    const [jobData, setJobData] = useRecoilState(jobCreationState);
    const [isLoading, setIsLoading] = useState(false);
    const [endorsementGroups, setEndorsementGroups] = useState<any[]>([]);
    const [selectedEndorsements, setSelectedEndorsements] = useState<number[]>(
        jobData.endorsements || [],
    );
    const [openSections, setOpenSections] = useState<{
        [key: string]: boolean;
    }>({});

    // Fetch data from the API
    useEffect(() => {
        setIsLoading(true);
        EndorsementAPI.getGrouped()
            .then((response) => {
                setEndorsementGroups(response);
                // Initialize open sections state
                const initialOpenSections = response.reduce(
                    (acc: any, group: any) => {
                        acc[group.type] = true; // Set default open/close state
                        return acc;
                    },
                    {},
                );
                setOpenSections(initialOpenSections);
            })
            .catch((error) => {
                console.error("Error fetching endorsement groups:", error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [job_uuid]);

    useEffect(() => {
        if (open) {
            // Initialize selectedEndorsements from jobData when the modal opens
            setSelectedEndorsements(jobData.endorsements || []);
        }
    }, [open, jobData.endorsements]);

    const toggleEndorsement = (id: number) => {
        setSelectedEndorsements((prev) => {
            const updated = prev.includes(id)
                ? prev.filter((endorsementId) => endorsementId !== id)
                : [...prev, id];

            // Update the jobData state with the updated endorsements array
            setJobData((prevJobData) => ({
                ...prevJobData,
                endorsements: updated,
            }));

            return updated;
        });
    };

    const toggleSection = (section: string) => {
        setOpenSections((prev) => ({
            ...prev,
            [section]: !prev[section],
        }));
    };

    return (
        <MUIModal open={open} onClose={onClose}>
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    borderRadius: "5px",
                    overflow: "hidden",
                    p: 1,
                }}
            >
                <div className={style.archive} style={{ maxWidth: "800px" }}>
                    <h3 style={{ marginBottom: "16px" }}>
                        Select requirements
                    </h3>

                    {isLoading ? (
                        <p>Loading...</p>
                    ) : (
                        endorsementGroups.map((group) => (
                            <div key={group.type} className={style.rack}>
                                <div
                                    className={style.header}
                                    onClick={() => toggleSection(group.type)}
                                >
                                    <div>
                                        <h3>{group.title}</h3>
                                        {openSections[group.type] && (
                                            <small>{group.description}</small>
                                        )}
                                    </div>
                                    <span className={style.toggler}>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 512 512"
                                        >
                                            <path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z" />
                                        </svg>
                                    </span>
                                </div>
                                {openSections[group.type] && (
                                    <div className={style.content}>
                                        <ul>
                                            {group.endorsements.map(
                                                (endorsement: any) => (
                                                    <li key={endorsement.id}>
                                                        <div
                                                            className={`${
                                                                style.toggle
                                                            } ${
                                                                selectedEndorsements.includes(
                                                                    endorsement.id,
                                                                )
                                                                    ? style.active
                                                                    : ""
                                                            }`}
                                                            onClick={() =>
                                                                toggleEndorsement(
                                                                    endorsement.id,
                                                                )
                                                            }
                                                        >
                                                            <span>✓</span>
                                                            {endorsement.name}
                                                        </div>
                                                    </li>
                                                ),
                                            )}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        ))
                    )}
                    <Button onClick={onClose}>Save Requirements</Button>
                </div>
            </Box>
        </MUIModal>
    );
};

export default EndorsementsModal;
