import axios, { AxiosResponse } from "axios";
import cookies from "../helpers/cookies";
import attemptLogout from "./logout";
import { clearLocalStorage } from "../helpers/general";
class ApiService {
    private baseUrl: string;

    constructor() {
        this.baseUrl = process.env.REACT_APP_API_HOST
            ? process.env.REACT_APP_API_HOST
            : "https://staging.slingerstaff.com/api";
    }

    private async handleResponse<T>(response: AxiosResponse): Promise<T> {
        return response.data;
    }
    private async handleError<T>(error: AxiosResponse): Promise<T> {
        const response = error.response;
        if (response.status !== 200) {
            if (response.status === 500) {
                // Return a predefined fake response for status 500
                return Promise.reject(
                    new Error(
                        "Server Side error. Please contact Slinger support",
                    ),
                );
            } else if (response.status === 422 || response.status === 401) {
                try {
                    const errorDetails = await response.data;
                    const errorMessage =
                        errorDetails.message || "Something went wrong";
                    if (errorMessage == "Unauthenticated.") {
                        clearLocalStorage();
                        window.location.href = "/login";
                    }
                    return Promise.reject(new Error(`${errorMessage}`));
                } catch (error) {
                    // Handle JSON parsing error
                    return Promise.reject(
                        new Error("Request failed for an unknown reason."),
                    );
                }
            } else {
                if (response.data.message) {
                    return Promise.reject(
                        new Error(`${response.data.message}`),
                    );
                } else {
                    return Promise.reject(
                        new Error(
                            `Request failed with status ${response.status}`,
                        ),
                    );
                }
            }
        }
        return error;
    }

    async get<T>(endpoint: string, data: any): Promise<T> {
        const url = `${this.baseUrl}/${endpoint}`;
        const response = await axios({
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ${cookies.getCookie("bearer")}`,
            },
            data: data,
            url: url,
        }).catch((error) => {
            return this.handleError<T>(error);
        });

        return this.handleResponse<T>(response);
    }

    async post<T>(endpoint: string, data: any): Promise<T> {
        const url = `${this.baseUrl}/${endpoint}`;
        const response = await axios({
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ${cookies.getCookie("bearer")}`,
            },
            data: data,
            url: url,
        }).catch((error) => {
            return this.handleError<T>(error);
        });

        return this.handleResponse<T>(response);
    }

    async upload<T>(endpoint: string, data: any): Promise<T> {
        const url = `${this.baseUrl}/${endpoint}`;
        const form_data = new FormData();

        for (const key in data) {
            form_data.append(key, data[key]);
        }
        const response = await axios({
            method: "POST",
            headers: {
                "Content-Type": "multipart/form-data",
                Accept: "application/json",
                Authorization: `Bearer ${cookies.getCookie("bearer")}`,
            },
            data: data,
            url: url,
        }).catch((error) => {
            return this.handleError<T>(error);
        });

        return this.handleResponse<T>(response);
    }
}

export default ApiService;
