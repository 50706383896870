import style from "./Loading.module.scss";
import { ReactComponent as SlingerIcon } from "../../../assets/v2/icon.svg";
interface LoadingPropsInterface {
    backgroundFade?: boolean;
    title: string;
    type: string;
}

export default function Loading(props: LoadingPropsInterface) {
    const { backgroundFade } = props;

    const classes = [style.loading];
    if (backgroundFade) classes.push(style.withFade);
    if (props.type == "internal") classes.push(style.internal);

    return (
        <div className={classes.join(" ")} data-testid="loader">
            <span className={style.loader}>
                <SlingerIcon />
            </span>
            {props.title ? <h3>{props.title}...</h3> : null}
        </div>
    );
}

Loading.defaultProps = {
    backgroundFade: true,
    title: "",
    type: "standard",
};
