import React, { useState } from "react";
import { Alert, TextField } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import { roundToNearest5Minutes } from "../../../../../helpers/shiftHelpers";

const EndDateTimePage: React.FC<{
    formData: any;
    updateFormData: (field: string, value: any) => void;
    setError: (value: any) => void;
}> = ({ formData, updateFormData, setError }) => {
    const handleEndDateChange = (newValue: any) => {
        const selectedDate = roundToNearest5Minutes(
            dayjs(newValue).set("seconds", 0),
        );

        if (selectedDate.isBefore(formData.start_time)) {
            setError("End date cannot be before start date");
        } else if (
            formData.start_time !== null &&
            newValue.diff(formData.start_time, "hour", true) < 4
        ) {
            setError("Shifts need to be a minimum of 4 hours");
        } else {
            setError("");
            updateFormData("end_time", selectedDate);
        }
    };

    return (
        <>
            <DateTimePicker
                label="End Date and Time"
                value={formData.end_time}
                onChange={handleEndDateChange}
                slotProps={{
                    popper: {
                        placement: "right", // Popper will appear on top of the input field
                        sx: {
                            ".MuiPaper-root": {
                                border: "1px solid #3490dc",
                                borderRadius: "5px",
                                ml: -20,
                            },
                        },
                        disablePortal: true, // Ensures the popper stays within the modal
                    },
                }}
                sx={{ width: "100%", mb: 3 }}
                format="DD/M/YYYY - HH:mm"
                ampm={false}
                minDateTime={
                    formData.start_time !== null
                        ? dayjs(formData.start_time).add(4, "hours")
                        : dayjs().add(6, "hours")
                } // Ensure end time can't be before start time
            />
        </>
    );
};

export default EndDateTimePage;
