import { Alert } from "@mui/material";
import Avatar from "../../Avatar";
import style from "./ProfileModal.module.scss";
import QRCode from "react-qr-code";

import React, { useEffect, useState } from "react";
import { Modal as MUIModal, Box, Typography, Button } from "@mui/material";
import JobDescriptionAPI from "../../../../api/jobDescriptions.service";
import Profile from "../../../Slinger/Profile";
import UserAPI from "../../../../api/user/user.service";
import Loading from "../../Loading";
interface ModalProps {
    open: boolean;
    onClose: () => void;
    uuid: string;
    job_uuid: null | string;
    shift_uuid: null | string;
}

const ProfileModal: React.FC<ModalProps> = ({
    open,
    onClose,
    uuid,
    job_uuid,
    shift_uuid,
}) => {
    // const [isLoading, setIsLoading] = useState(false);
    const [slinger, setSlinger] = useState(null);

    useEffect(() => {
        if (uuid) {
            console.log(uuid);
            console.log(job_uuid);
            UserAPI.getPublic(uuid, shift_uuid).then((response) => {
                setSlinger(response);
                console.log(response);
            });
        }
    }, [uuid]);

    if (slinger == null) {
        return (
            <MUIModal open={open} onClose={onClose}>
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        minWidth: "200px;",
                        minHeight: "200px",
                        transform: "translate(-50%, -50%)",
                        maxHeight: "90vh",
                        bgcolor: "background.paper",
                        boxShadow: 24,
                        borderRadius: "5px",
                        overflow: "scroll",
                        p: 1,
                    }}
                >
                    <Loading type="internal" />
                </Box>
            </MUIModal>
        );
    } else {
        return (
            <MUIModal open={open} onClose={onClose}>
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        maxHeight: "90vh",
                        bgcolor: "background.paper",
                        boxShadow: 24,
                        borderRadius: "5px",
                        overflow: "scroll",
                        p: 1,
                    }}
                >
                    <Profile slinger={slinger} />
                </Box>
            </MUIModal>
        );
    }
};

export default ProfileModal;
