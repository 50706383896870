import ApiService from "./api.service";
import LSS from "../helpers/localStorageService";
class SubscriptionAPI {
    static apiService = new ApiService();

    static async getBreakdown(): Promise<Object> {
        const body = { business_uuid: LSS().get("business_uuid") };
        const responseData = await this.apiService.post<Object>(
            "payment/open-subscriptions",
            body,
        );
        return responseData;
    }

    static async getSingleBreakdown(subscription_uuid): Promise<Object> {
        const body = {
            business_uuid: LSS().get("business_uuid"),
            subscription_uuid: subscription_uuid,
        };
        const responseData = await this.apiService.post<Object>(
            "payment/subscription-breakdown",
            body,
        );
        return responseData;
    }

    static async hasAny(): Promise<Object> {
        const body = {
            business_uuid: LSS().get("business_uuid"),
        };
        const responseData = await this.apiService.post<Object>(
            "payment/has-active-subscriptions",
            body,
        );
        return responseData;
    }
}
export default SubscriptionAPI;
