type Country = {
    id: number;
    name: string;
};

type Translations = {
    [key: string]: string;
};

type LoqateDetails = {
    api_key: string;
    find_endpoint: string;
    retrieve_endpoint: string;
    enabled_countries: Array<string>;
};

export interface PreloadedDataInterface {
    api_namespace: string;
    basename: string;
    countries: Country[];
    translations: Translations;
    loqate: LoqateDetails;
    enabled_country_ids: Array<number>;
}

export default function preload(): PreloadedDataInterface {
    const preloadDataElement = document.getElementById("slinger-preload");

    if (!preloadDataElement) {
        throw new Error("No preload data provided.");
    }

    return JSON.parse(preloadDataElement.innerText.trim());
}
