/* eslint-disable unused-imports/no-unused-vars */
import { useState } from "react";
import grid from "../../styles/partials/_grid.module.scss";
import Blob from "../../components/common/Blob";
import { Alert, FormControl, TextField } from "@mui/material";
import Typography from "../../components/Typography";

import AuthAPI from "../../api/auth.client";
import Button from "../../components/common/Button";
const labelProps = {
    shrink: true,
};
// Load Container with all the relevant pieces for the page component to load
export default function UpdatePasswordForm() {
    const [isChecking, setIsChecking] = useState(false);
    const [isError, setIsError] = useState("");
    const [isSuccess, setIsSuccess] = useState("");
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmedNewPassword, setConfirmedNewPassword] = useState("");
    const [passwordMatches, setPasswordMatches] = useState(true);
    const authAPI = new AuthAPI();

    const validatePassword = () => {
        if (newPassword === confirmedNewPassword) {
            return true;
        }
        return false;
    };
    const updatePassword = () => {
        setIsChecking(true);
        setIsError("");
        setIsSuccess("");
        if (validatePassword()) {
            setPasswordMatches(true);

            const body = {
                current_password: currentPassword,
                new_password: newPassword,
                new_password_confirmation: confirmedNewPassword,
            };
            authAPI
                .updatePassword(body)
                .then((response) => {
                    setIsSuccess(response.message);
                })
                .catch((err) => {
                    setIsError(err.message);
                })
                .finally(() => {
                    setIsChecking(false);
                });
        } else {
            setPasswordMatches(false);
            setIsChecking(false);
        }
    };
    return (
        <div className={[grid.row, grid.centre].join(" ")}>
            <Blob title="Update your password" size="half">
                <Typography variant="small" className={["mb-16", "mt-8"]}>
                    Please enter your current and new password below:
                </Typography>
                {isError ? (
                    <Alert severity="error" style={{ marginBottom: "16px" }}>
                        {isError}
                    </Alert>
                ) : (
                    ""
                )}
                {isSuccess ? (
                    <Alert severity="success" style={{ marginBottom: "16px" }}>
                        {isSuccess}
                    </Alert>
                ) : (
                    ""
                )}

                <FormControl fullWidth sx={{ mb: 4 }}>
                    <TextField
                        disabled={isChecking}
                        id="current_password"
                        label="Current Password"
                        InputLabelProps={labelProps}
                        type="password"
                        onChange={function (e) {
                            setPasswordMatches(true);
                            setCurrentPassword(e.target.value);
                        }}
                    />
                </FormControl>
                <FormControl fullWidth sx={{ mb: 1 }}>
                    <TextField
                        disabled={isChecking}
                        id="new_password"
                        error={!passwordMatches}
                        type="password"
                        style={{ marginBottom: "8px" }}
                        InputLabelProps={labelProps}
                        label="New Password"
                        onChange={function (e) {
                            setPasswordMatches(true);
                            setNewPassword(e.target.value);
                        }}
                    />
                </FormControl>
                <FormControl fullWidth sx={{ mb: 2 }}>
                    <TextField
                        disabled={isChecking}
                        error={!passwordMatches}
                        InputLabelProps={labelProps}
                        label="Confirm New Password"
                        id="new_password_confirmation"
                        type="password"
                        onChange={function (e) {
                            setConfirmedNewPassword(e.target.value);
                        }}
                    />
                </FormControl>
                <div className={[grid.row, grid.centre].join(" ")}>
                    <Button onClick={updatePassword}>Update Password</Button>
                </div>
                <a href="/profile" style={{ textAlign: "center" }}>
                    <Typography variant="small">Back to account</Typography>
                </a>
            </Blob>
        </div>
    );
}
