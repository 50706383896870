/* eslint-disable unused-imports/no-unused-vars */
import { useEffect, useState } from "react";
import TimesheetManager from "../../components/TimesheetManager";
import Typography from "../../components/Typography";
import Blob from "../../components/common/Blob";
import grid from "../../styles/partials/_grid.module.scss";
import SlingerContainer from "../SlingerContainer";
import TimesheetAPI from "../../api/timesheet.service";
import Loading from "../../components/common/Loading";
import Button from "../../components/common/Button";

interface TimesheetViewerProps {
    passed: boolean;
}
export default function TimesheetContainer(props: TimesheetViewerProps) {
    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [maxPage, setMaxPage] = useState(1);
    const [isError, setIsError] = useState(false);
    const [shifts, setShifts] = useState();
    const getCore = async () => {
        // Get single shift
    };

    const load = async (page) => {
        try {
            setIsError(false);
            setIsLoading(true);
            if (!props.passed) {
                TimesheetAPI.getOutstanding(page).then((response) => {
                    setShifts(response.data);
                    setCurrentPage(response.current_page);
                    setMaxPage(response.max_page);
                    setIsLoading(false);
                });
            } else {
                TimesheetAPI.getAll(page).then((response) => {
                    setShifts(response.data);
                    setCurrentPage(response.current_page);
                    setMaxPage(response.max_page);
                    setIsLoading(false);
                });
            }
        } catch (err: any) {
            setIsLoading(false);
            setIsError(true);
        }
    };
    const next = () => {
        const new_page = page + 1;
        setPage(new_page);
        load(new_page);
    };
    const previous = () => {
        let new_page = page - 1;
        if (new_page < 1) {
            new_page = 1;
        }

        setPage(new_page);
        load(new_page);
    };
    useEffect(() => {
        document.title = "Slinger Business | Timesheet Management";
        load(page);
    }, []);

    return (
        <SlingerContainer title="Timesheets">
            <div className={grid.toolbar} style={{ paddingLeft: 0 }}>
                {props.passed ? (
                    <Button href="/timesheets">
                        View Pending Timesheets →
                    </Button>
                ) : (
                    <Button href="/timesheets/completed">
                        View Completed Timesheets →
                    </Button>
                )}
            </div>
            <div className={grid.row}>
                <div
                    className={[
                        grid.row,
                        grid.centreVertically,
                        grid.align_right,
                        grid.controller,
                    ].join(" ")}
                    style={{ marginTop: "16px" }}
                >
                    <Button
                        type="micro"
                        onClick={previous}
                        disabled={page == 1 || isLoading}
                    >
                        {"<"}
                    </Button>
                    <div>
                        {currentPage} of {maxPage}
                    </div>

                    <Button
                        type="micro"
                        onClick={next}
                        disabled={maxPage == page || isLoading}
                    >
                        {">"}
                    </Button>
                </div>
            </div>
            <div className={grid.row}>
                <Blob
                    size="full"
                    title="Outstanding Timesheets"
                    style={{ minHeight: "300px" }}
                    padded_inner={false}
                >
                    {isLoading ? (
                        <Loading title="Fetching Timesheets" type="internal" />
                    ) : (
                        <TimesheetManager
                            shifts={shifts}
                            passed={props.passed}
                        />
                    )}
                </Blob>
            </div>
            <div className={grid.row} style={{ paddingBottom: "60px" }}>
                <div
                    className={[
                        grid.row,
                        grid.centreVertically,
                        grid.align_right,
                        grid.controller,
                    ].join(" ")}
                >
                    <Button
                        type="micro"
                        onClick={previous}
                        disabled={page == 1 || isLoading}
                    >
                        {"<"}
                    </Button>
                    <div>
                        {currentPage} of {maxPage}
                    </div>

                    <Button
                        type="micro"
                        onClick={next}
                        disabled={maxPage == page || isLoading}
                    >
                        {">"}
                    </Button>
                </div>
            </div>
        </SlingerContainer>
    );
}
