/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import style from "./MultiSelect.module.scss";

const MultiSelect = ({
    options,
    selectedOptions,
    onChange,
    label,
    placeholder,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selected, setSelected] = useState(selectedOptions);
    const wrapperRef = useRef(null);
    const toggleDropdown = () => setIsOpen(!isOpen);

    const handleOptionClick = (option) => {
        const newSelected = selected.includes(option.id)
            ? selected.filter((id) => id !== option.id)
            : [...selected, option.id];

        setSelected(newSelected);
        onChange(newSelected);
    };

    const handleClickOutside = (event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className={style.multiSelect} ref={wrapperRef}>
            <label>{label}</label>
            <div className={style.multiSelectInput} onClick={toggleDropdown}>
                {selected.length === 0 ? (
                    <span className={style.placeholder}>{placeholder}</span>
                ) : (
                    <span className={style.chip}>
                        {selected.length === 1
                            ? options.find(
                                  (option) => option.id === selected[0],
                              ).name
                            : `${selected.length} selected`}
                    </span>
                )}
                <span className={style.arrow}>+</span>
            </div>
            {isOpen && (
                <div className={style.dropdown}>
                    {options.map((option) => (
                        <div
                            key={option.id}
                            className={`${style.dropdownItem} ${
                                selected.includes(option.id)
                                    ? style.selected
                                    : ""
                            }`}
                            onClick={() => handleOptionClick(option)}
                        >
                            <input
                                type="checkbox"
                                checked={selected.includes(option.id)}
                                onChange={() => handleOptionClick(option)}
                            />
                            {option.name}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default MultiSelect;
