import { useState } from "react";
import Typography from "../../components/Typography";
import grid from "../../styles/partials/_grid.module.scss";
import JEDescription from "./Description";
import JEOpener from "./Opener/opener";
import JEPeripherals from "./Peripherals";
import JEQuestions from "./Questions";
import Button from "../../components/common/Button";
import JEEndorsements from "./Endorsements";
import StepTracker from "../StepTracker";
import JobDescriptionAPI from "../../api/jobDescriptions.service";
import { jobCreationState } from "../../state/atoms/jobCreationAtom";
import JERoundUp from "./Roundup";
import { useRecoilState } from "recoil";
import JESalary from "./Salary";

function JobEditorForm() {
    const [currentStep, setCurrentStep] = useState(0);
    const handleNext = () => setCurrentStep((prev) => prev + 1);
    const handlePrev = () => setCurrentStep((prev) => prev - 1);
    // eslint-disable-next-line unused-imports/no-unused-vars
    const [jobData, setJobData] = useRecoilState(jobCreationState);

    const handleSubmit = async () => {
        JobDescriptionAPI.create(jobData).then((response) => {
            window.location.href = "/trial-shift/view/" + response.uuid;
        });
    };
    const validateStep = (step: number): boolean => {
        switch (step) {
            case 0:
                return true;
            case 1:
                return (
                    jobData.site_id !== "" &&
                    jobData.role_id !== 0 &&
                    jobData.contract_type !== "" &&
                    jobData.start_date
                );
            case 2:
                return jobData.wage > 0;
            case 3:
                return jobData.description && jobData.description.length > 150;
            case 4:
                // TODO:: More complex handling of question contents (at the moment it could be empty)
                return (
                    jobData.screening_questions &&
                    jobData.screening_questions.length > 0
                );
            case 5:
                // TODO:: Maybe we should have a reasonable action on this page?
                return true;
            case 6:
                return true; // All previous steps are valid, ready for confirmation
            default:
                return false;
        }
    };
    const steps = [
        <JEOpener key="0" />,
        <JEPeripherals key="1" />,
        <JESalary key="2" />,
        <JEDescription key="3" />,
        <JEQuestions key="4" />,
        <JEEndorsements key="5" />,
        <JERoundUp key="6" />,
    ];

    return (
        <div style={{ maxWidth: "960px", width: "100%", margin: "0 auto" }}>
            <div style={{ width: "150px", margin: "0 auto 32px" }}>
                <img width="150px" src="/assets/images/slingerflatlogo.svg" />
            </div>

            <div>{steps[currentStep]}</div>
            <div className={grid.row}>
                <Button href="/trial-shift" type="error">
                    Cancel
                </Button>
                <div style={{ marginLeft: "auto" }}>
                    {currentStep > 0 && (
                        <Button
                            onClick={handlePrev}
                            style={{ marginRight: "16px" }}
                        >
                            Previous
                        </Button>
                    )}
                    {currentStep < steps.length - 1 && (
                        <Button
                            onClick={handleNext}
                            disabled={!validateStep(currentStep)}
                        >
                            Next
                        </Button>
                    )}
                    {currentStep === steps.length - 1 && (
                        <Button type="green" onClick={handleSubmit}>
                            {jobData.uuid ? "Save" : "Create"}
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
}

export default JobEditorForm;
