const setCookie = (cname, cvalue, exdays = 30) => {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    const expires = "expires=" + d.toUTCString();
    document.cookie =
        cname +
        "=" +
        cvalue +
        ";" +
        expires +
        ";path=/;domain=." +
        window.location.hostname;
};

const getCookie = (cname) => {
    const name = cname + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
};

const logout = async () => {
    const logout = await api.post.logout();
    if (logout.status == 200) {
        return logout;
    }
    return false;
};

const getErrors = (errors) => {
    const errorList = [];
    for (const [key, value] of Object.entries(errors)) {
        if(key){
  errorList.push(value[0]);
        }
    }
    return errorList;
};

setCookie.defaultProps = {
    exdays: 10,
};
export default {
    setCookie,
    getCookie,
    logout,
    getErrors,
};
