/* eslint-disable unused-imports/no-unused-vars */
import React, { useState, useEffect } from "react";
import Blob from "../../common/Blob";
import grid from "../../../styles/partials/_grid.module.scss";
import style from "./RequirementsManager.module.scss";
import Button from "../../common/Button";
import Typography from "../../Typography";
import { jobCreationState } from "../../../state/atoms/jobCreationAtom";
import { useRecoilState } from "recoil";
import EndorsementAPI from "../../../api/endorsement.service";
import { ModalType, useModal } from "../../../hooks/ModalContext";

function RequirementsManager() {
    const [jobData, setJobData] = useRecoilState(jobCreationState);
    const [isLoading, setIsLoading] = useState(true);
    const [endorsementGroups, setEndorsementGroups] = useState<any[]>([]);
    const [currentValues, setCurrentValues] = useState<number[]>(
        jobData.endorsements || [],
    );
    const [openSections, setOpenSections] = useState<{
        [key: string]: boolean;
    }>({});
    const { openModal } = useModal();

    useEffect(() => {
        setCurrentValues(jobData.endorsements || []);
    }, [jobData.endorsements]);

    useEffect(() => {
        setIsLoading(true);
        EndorsementAPI.getGrouped()
            .then((response) => {
                console.log(response); // Log the response to verify the structure
                if (Array.isArray(response)) {
                    setEndorsementGroups(response);
                } else {
                    console.error("Unexpected response structure:", response);
                }
                // Initialize open sections state
                const initialOpenSections = response.reduce(
                    (acc: any, group: any) => {
                        acc[group.type] = true; // Set default open/close state
                        return acc;
                    },
                    {},
                );
                setOpenSections(initialOpenSections);
            })
            .catch((error) => {
                console.error("Error fetching endorsement groups:", error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    const toggleSection = (section: string) => {
        setOpenSections((prev) => ({
            ...prev,
            [section]: !prev[section],
        }));
    };

    return (
        <Blob title="Requirements" size={"full"} padded_inner={false}>
            <div className={grid.form_inner}>
                <div className={grid.form_header}>
                    <Typography variant="small">
                        Let the applicant know what requirements you need for
                        this role. Whilst we don't filter applicants out based
                        on this information, it's displayed to candidates so
                        they know the expectations.
                    </Typography>
                </div>
                <div className={grid.row}>
                    {isLoading ? (
                        <p>Loading...</p>
                    ) : Array.isArray(endorsementGroups) &&
                      endorsementGroups.length > 0 ? (
                        endorsementGroups.map((group) => {
                            // Filter selected endorsements
                            const selectedEndorsements =
                                group.endorsements.filter((endorsement: any) =>
                                    currentValues.includes(endorsement.id),
                                );

                            return (
                                <div key={group.type} className={grid.third}>
                                    <div
                                        className={style.header}
                                        onClick={() =>
                                            toggleSection(group.type)
                                        }
                                    >
                                        <div>
                                            <h3>{group.title}</h3>
                                        </div>
                                    </div>
                                    {openSections[group.type] && (
                                        <div className={style.content}>
                                            <ul className={style.outcome_list}>
                                                {selectedEndorsements.length >
                                                0 ? (
                                                    selectedEndorsements.map(
                                                        (endorsement: any) => (
                                                            <li
                                                                key={
                                                                    endorsement.id
                                                                }
                                                            >
                                                                <div
                                                                    className={
                                                                        style.head
                                                                    }
                                                                >
                                                                    <span
                                                                        className={
                                                                            style.emoji
                                                                        }
                                                                    >
                                                                        {
                                                                            endorsement.emoji
                                                                        }
                                                                    </span>
                                                                    <h5>
                                                                        {
                                                                            endorsement.name
                                                                        }
                                                                    </h5>
                                                                </div>
                                                                {endorsement.description ? (
                                                                    <small>
                                                                        {
                                                                            endorsement.description
                                                                        }
                                                                    </small>
                                                                ) : null}
                                                            </li>
                                                        ),
                                                    )
                                                ) : (
                                                    <li>
                                                        <small>
                                                            No Requirements
                                                        </small>
                                                    </li>
                                                )}
                                            </ul>
                                        </div>
                                    )}
                                </div>
                            );
                        })
                    ) : (
                        <p>No endorsement groups found.</p>
                    )}
                </div>
                <div className={style.component}>
                    <Button
                        onClick={() => openModal(ModalType.Endorsements, {})}
                    >
                        Add requirements
                    </Button>
                </div>
            </div>
        </Blob>
    );
}

export default RequirementsManager;
