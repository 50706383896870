/* eslint-disable unused-imports/no-unused-vars */
import ShiftsAPI from "../../api/shifts/shifts.client";
import ShiftList from "../../components/Shift/ShiftList";
import SlingerContainer from "../SlingerContainer";
import LSS from "../../helpers/localStorageService";
import { useEffect, useState } from "react";
import Loading from "../../components/common/Loading";
import grid from "../../styles/partials/_grid.module.scss";
import Typography from "../../components/Typography";
import Button from "../../components/common/Button";
import { canCreateShifts } from "../../helpers/permissions";
import Blob from "../../components/common/Blob";
// Load Container with all the relevant pieces for the page component to load
export default function AgendaContainer() {
    const [shifts, setShifts] = useState([] as {}[]);

    const [isLoading, setIsLoading] = useState(true);
    const shiftAPI = new ShiftsAPI();
    const [page, setPage] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [maxPage, setMaxPage] = useState(10);
    const load = async (page_set) => {
        const body = {
            business_id: LSS().get("business").id,
            page: page_set,
        };
        await shiftAPI
            .getUpcoming(body)
            .then((response) => {
                setShifts(response.data);
                setCurrentPage(response.current_page);
                setIsLoading(false);
                if (!response.next_page_url) {
                    setMaxPage(response.current_page);
                }
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    };

    useEffect(() => {
        load(1);
        document.title = "Slinger Business | Your Shifts";
    }, []);
    const next = () => {
        const new_page = page + 1;
        setPage(new_page);
        load(new_page);
    };
    const previous = () => {
        let new_page = page - 1;
        if (new_page < 1) {
            new_page = 1;
        }
        setPage(new_page);
        load(new_page);
    };
    if (isLoading) {
        return <Loading></Loading>;
    }
    return (
        <SlingerContainer title="Shift Agenda">
            <div className={grid.row}>
                <div className={grid.full}>
                    <div className={grid.row}>
                        {canCreateShifts() ? (
                            <Button href="/shifts/create">
                                Create new shift
                            </Button>
                        ) : (
                            ""
                        )}
                    </div>
                    <div
                        className={[grid.row, grid.centreVertically].join(" ")}
                        style={{
                            marginBottom: "0px",
                            paddingBottom: "16px",
                        }}
                    >
                        <div className={grid.third}>
                            <Typography variant="h3">
                                Upcoming Shifts
                            </Typography>
                        </div>
                        <div
                            className={[
                                grid.row,
                                grid.centreVertically,
                                grid.align_right,
                                grid.controller,
                            ].join(" ")}
                        >
                            <Button
                                type="micro"
                                onClick={previous}
                                disabled={page == 1}
                                style={{ marginRight: 0 }}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 320 512"
                                >
                                    <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z" />
                                </svg>
                            </Button>
                            <div>{currentPage}</div>

                            <Button
                                type="micro"
                                onClick={next}
                                disabled={maxPage == page}
                                style={{ marginRight: 0 }}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 320 512"
                                    >
                                        <path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" />
                                    </svg>
                                </svg>
                            </Button>
                        </div>
                    </div>

                    <ShiftList shifts={shifts}></ShiftList>
                </div>
            </div>
        </SlingerContainer>
    );
}
