import { useEffect, useState } from "react";
import SlingerContainer from "../SlingerContainer";
import Loading from "../../components/common/Loading";
import ErrorPage from "../../pages/Error";

import { useParams } from "react-router-dom";
import Invite from "../../forms/Invite/Invite";
import BusinessAPI from "../../api/business/business.client";

// Load Container with all the relevant pieces for the page component to load
export default function InviteContainer() {
    const { business_uuid } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState("");
    const [setup, setSetup] = useState();

    const businessAPI = new BusinessAPI();
    const load = async () => {
        try {
            setIsError(false);
            await businessAPI
                .InvitePayload(business_uuid)
                .then((response) => {
                    setSetup(response);
                    setIsLoading(false);
                })
                .catch((err) => {
                    setIsError(err.message);
                });
        } catch (err: any) {
            setIsLoading(false);
            setIsError(err.message);
        }
    };

    useEffect(() => {
        load();
    }, []);

    if (isError || !business_uuid) {
        return (
            <SlingerContainer title="" type="registration">
                <ErrorPage message={isError} onErrorReload={load} />
            </SlingerContainer>
        );
    }

    if (isLoading || !setup) {
        return (
            <SlingerContainer title="" type="registration">
                <Loading />
            </SlingerContainer>
        );
    }

    return (
        <SlingerContainer title="Invite to team" type="registration">
            <Invite setup={setup} business_id={business_uuid} />
        </SlingerContainer>
    );
}
