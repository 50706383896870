import React, { Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { lazy } from "react";
import Loading from "./components/common/Loading";
import InviteContainer from "./containers/InviteContainer/InviteContainer";
import UserDetailsUpdateContainer from "./containers/UserDetailsUpdateContainer/UserDetailsUpdateContainer";
import GeneralMessageContainer from "./containers/GeneralMessageContainer";
import QRGeneratorCode from "./containers/QRGeneratorContainer";
import TimesheetContainer from "./containers/TimesheetContainer";
import SubscriptionContainer from "./containers/SubscriptionContainer";
import SingleSubscriptionContainer from "./containers/SingleSubscriptionContainer";
import JobDescriptionCreatorContainer from "./containers/JobDescriptionCreatorContainer";
import JobDescriptionListContainer from "./containers/JobDescriptionListContainer";
import AgendaContainer from "./containers/AgendaContainer";
import JobDecisionContainer from "./containers/JobDecisionContainer";
import ShiftInvoiceContainer from "./containers/ShiftInvoiceContainer";

const DashboardContainer = lazy(() => import("./containers/Dashboard"));
const PasswordResetContainer = lazy(
    () => import("./containers/PasswordResetContainer"),
);
const JobDescriptionContainer = lazy(
    () => import("./containers/JobDescriptionContainer"),
);

const LoginContainer = lazy(() => import("./containers/Login"));
const LogoutContainer = lazy(() => import("./containers/Logout"));

const ShiftManageContainer = lazy(() => import("./containers/ShiftManager"));
const ShiftEditContainer = lazy(
    () => import("./containers/ShiftEditContainer"),
);
const ShiftsContainer = lazy(() => import("./containers/Shifts"));
const ProfileContainer = lazy(() => import("./containers/Profile"));
const UserManagementContainer = lazy(
    () => import("./containers/UserManagementContainer"),
);
const InvoiceContainer = lazy(() => import("./containers/Invoice"));
const DefaultCardContainer = lazy(
    () => import("./containers/StripeManagement/DefaultCardIntent"),
);
const HourConfirmationContainer = lazy(
    () => import("./containers/HourConfirmationContainer"),
);
const BusinessContainer = lazy(() => import("./containers/Business"));
const NotificationsContainer = lazy(
    () => import("./containers/NotificationsContainer"),
);
const ShiftCreatedContainer = lazy(() => import("./containers/ShiftCreated"));
const ReviewsContainer = lazy(() => import("./containers/ReviewsContainer"));
const ShiftViewContainer = lazy(() => import("./containers/ShiftView"));
const ShiftReviewContainer = lazy(
    () => import("./containers/ShiftReviewContainer"),
);
const SiteViewContainer = lazy(() => import("./containers/SiteViewContainer"));
const SitesContainer = lazy(() => import("./containers/SitesContainer"));
const BusinessRegistrationContainer = lazy(
    () => import("./containers/BusinessRegistration"),
);
const InviteRegistrationContainer = lazy(
    () => import("./containers/InviteRegistrationContainer"),
);
const SiteFirstTimeContainer = lazy(
    () => import("./containers/SiteFirstTimeContainer"),
);
const SiteManageContainer = lazy(
    () => import("./containers/SiteManageContainer"),
);
const ShiftCancelContainer = lazy(
    () => import("./containers/ShiftCancelContainer"),
);
const Main = () => (
    <Routes>
        <Route index element={<Navigate to="/login" replace />} />

        <Route
            path="/login"
            element={
                <Suspense fallback={<div></div>}>
                    <LoginContainer />
                </Suspense>
            }
        />

        <Route
            path="/dashboard"
            element={
                <Suspense fallback={<Loading />}>
                    <DashboardContainer />
                </Suspense>
            }
        />

        <Route
            path="/rtw-complete"
            element={
                <Suspense fallback={<Loading />}>
                    <GeneralMessageContainer type="rtw" />
                </Suspense>
            }
        />

        <Route
            path="/success"
            element={
                <Suspense fallback={<Loading />}>
                    <GeneralMessageContainer type="" />
                </Suspense>
            }
        />

        <Route
            path="/stripe-success"
            element={
                <Suspense fallback={<Loading />}>
                    <GeneralMessageContainer type="stripe" />
                </Suspense>
            }
        />

        <Route
            path="/sites"
            element={
                <Suspense fallback={<Loading />}>
                    <SitesContainer />
                </Suspense>
            }
        />

        <Route
            path="/reviews"
            element={
                <Suspense fallback={<Loading />}>
                    <ReviewsContainer />
                </Suspense>
            }
        />

        <Route
            path="/profile"
            element={
                <Suspense fallback={<Loading />}>
                    <ProfileContainer />
                </Suspense>
            }
        />
        <Route
            path="/invoices"
            element={
                <Suspense fallback={<Loading />}>
                    <InvoiceContainer />
                </Suspense>
            }
        />

        {/* Shifts Logic */}
        <Route
            path="/shifts"
            element={
                <Suspense fallback={<div></div>}>
                    <ShiftsContainer />
                </Suspense>
            }
        />
        <Route
            path="/shifts/create"
            element={
                <Suspense fallback={<Loading />}>
                    <ShiftManageContainer />
                </Suspense>
            }
        />
        <Route
            path="/shifts/manage/:shift_id"
            element={
                <Suspense fallback={<Loading />}>
                    <ShiftEditContainer />
                </Suspense>
            }
        />
        <Route path="/qr/:site_id" element={<QRGeneratorCode />} />
        <Route
            path="/shifts/created/:shift_id"
            element={
                <Suspense fallback={<Loading />}>
                    <ShiftCreatedContainer />
                </Suspense>
            }
        />

        <Route
            path="/shift/review/:shift_id"
            element={
                <Suspense fallback={<Loading />}>
                    <ShiftReviewContainer />
                </Suspense>
            }
        />
        <Route
            path="/shifts/agenda"
            element={
                <Suspense fallback={<Loading />}>
                    <AgendaContainer />
                </Suspense>
            }
        />
        <Route
            path="/shift/confirm-hours/:shift_id"
            element={
                <Suspense fallback={<Loading />}>
                    <HourConfirmationContainer />
                </Suspense>
            }
        />

        <Route
            path="/trial-shift/"
            element={
                <Suspense fallback={<Loading />}>
                    <JobDescriptionListContainer />
                </Suspense>
            }
        />

        <Route
            path="/trial-shift/view/:job_uuid"
            element={
                <Suspense fallback={<Loading />}>
                    <JobDescriptionContainer />
                </Suspense>
            }
        />
        <Route
            path="/trial-shift/edit/:job_uuid"
            element={
                <Suspense fallback={<Loading />}>
                    <JobDescriptionCreatorContainer />
                </Suspense>
            }
        />

        <Route
            path="/trial-shift/create/"
            element={
                <Suspense fallback={<Loading />}>
                    <JobDescriptionCreatorContainer />
                </Suspense>
            }
        />
        <Route
            path="/shift/view/:shift_id"
            element={
                <Suspense fallback={<Loading />}>
                    <ShiftViewContainer />
                </Suspense>
            }
        />

        <Route
            path="/shift/history"
            element={
                <Suspense fallback={<Loading />}>
                    <ShiftInvoiceContainer />
                </Suspense>
            }
        />

        <Route
            path="/notifications"
            element={
                <Suspense fallback={<Loading />}>
                    <NotificationsContainer />
                </Suspense>
            }
        />
        <Route
            path="/invite/accept/:invite_id"
            element={
                <Suspense fallback={<Loading />}>
                    <InviteRegistrationContainer />
                </Suspense>
            }
        />

        <Route
            path="/shift/cancel/:shift_id"
            element={
                <Suspense fallback={<Loading />}>
                    <ShiftCancelContainer />
                </Suspense>
            }
        />

        <Route
            path="/business"
            element={
                <Suspense fallback={<Loading />}>
                    <BusinessContainer />
                </Suspense>
            }
        />
        <Route
            path="/business/select"
            element={
                <Suspense fallback={<Loading />}>
                    <BusinessContainer view="select" />
                </Suspense>
            }
        />

        {/* Business Creation Logic */}
        <Route
            path="/business/register"
            element={
                <Suspense fallback={<Loading />}>
                    <BusinessRegistrationContainer step={1} />
                </Suspense>
            }
        />

        <Route
            path="/business/verify-email"
            element={
                <Suspense fallback={<Loading />}>
                    <BusinessRegistrationContainer step={2} />
                </Suspense>
            }
        />

        <Route
            path="/business/setup"
            element={
                <Suspense fallback={<Loading />}>
                    <BusinessRegistrationContainer step={3} />
                </Suspense>
            }
        />

        <Route
            path="/sites/setup"
            element={
                <Suspense fallback={<Loading />}>
                    <SiteFirstTimeContainer />
                </Suspense>
            }
        />

        <Route
            path="/business/default-payment"
            element={
                <Suspense fallback={<Loading />}>
                    <DefaultCardContainer />
                </Suspense>
            }
        />
        <Route
            path="/reference/denied/:uuid/:user_uuid"
            element={
                <Suspense fallback={<Loading />}>
                    <GeneralMessageContainer type="reference.denied" />
                </Suspense>
            }
        />
        <Route
            path="/reference/approve/:uuid/:user_uuid"
            element={
                <Suspense fallback={<Loading />}>
                    <GeneralMessageContainer type="reference.approved" />
                </Suspense>
            }
        />

        <Route
            path="/job-offer/:action/:sid/:uid/:oid"
            element={
                <Suspense fallback={<Loading />}>
                    <JobDecisionContainer />
                </Suspense>
            }
        />

        <Route
            path="/business/invite/:business_uuid"
            element={
                <Suspense fallback={<Loading />}>
                    <InviteContainer />
                </Suspense>
            }
        />
        <Route
            path="/timesheets"
            element={
                <Suspense fallback={<Loading />}>
                    <TimesheetContainer passed={false} />
                </Suspense>
            }
        />
        <Route
            path="/timesheets/completed"
            element={
                <Suspense fallback={<Loading />}>
                    <TimesheetContainer passed={true} />
                </Suspense>
            }
        />
        <Route
            path="/business/user-management"
            element={
                <Suspense fallback={<Loading />}>
                    <UserManagementContainer />
                </Suspense>
            }
        />

        <Route
            path="/subscriptions"
            element={
                <Suspense fallback={<Loading />}>
                    <SubscriptionContainer />
                </Suspense>
            }
        />
        <Route
            path="/subscriptions/view/:subscription_uuid"
            element={
                <Suspense fallback={<Loading />}>
                    <SingleSubscriptionContainer />
                </Suspense>
            }
        />
        <Route
            path="/business/user-management/:user_uuid"
            element={
                <Suspense fallback={<Loading />}>
                    <UserManagementContainer />
                </Suspense>
            }
        />

        <Route
            path="/sites/view/:site_id"
            element={
                <Suspense fallback={<Loading />}>
                    <SiteViewContainer />
                </Suspense>
            }
        />

        <Route
            path="/sites/manage/:site_id"
            element={
                <Suspense fallback={<Loading />}>
                    <SiteManageContainer />
                </Suspense>
            }
        />

        {/* Added Extras */}
        <Route
            path="/password/reset/:key"
            element={
                <Suspense fallback={<Loading />}>
                    <PasswordResetContainer />
                </Suspense>
            }
        />
        <Route
            path="/user/update-password"
            element={
                <Suspense fallback={<Loading />}>
                    <UserDetailsUpdateContainer step="1" />
                </Suspense>
            }
        ></Route>
        <Route
            path="/user/update-profile-photo"
            element={
                <Suspense fallback={<Loading />}>
                    <UserDetailsUpdateContainer step="2" />
                </Suspense>
            }
        ></Route>
        <Route
            path="/logout"
            element={
                <Suspense fallback={<Loading />}>
                    <LogoutContainer />
                </Suspense>
            }
        />
        <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
);

export default Main;
