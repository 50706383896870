import { PropsWithChildren, useState } from "react";
import Sidebar from "../../components/common/Sidebar";
import Header from "../../components/common/Header";
import style from "./SlingerContainer.module.scss";
import LSS from "../../helpers/localStorageService";
import { allowedAccess } from "../../helpers/general";
import { MenuProvider } from "../../hooks/menuContext";
interface SlingerContainerPropsInterface {
    title: string;
    type: string;
}

SlingerContainer.defaultProps = {
    title: "Welcome Back",
    type: "standard",
};
// Load Container with all the relevant pieces for the page component to load
export default function SlingerContainer(
    props: PropsWithChildren<SlingerContainerPropsInterface>,
) {
    const [menuOpen, setMenuOpen] = useState(false);
    const env = process.env.REACT_APP_ENV;
    if (env == "production") {
        window.Intercom("boot", {
            app_id: "vmm6y8am",
            name: LSS().get("first_name") + " " + LSS().get("last_name"),
            email: LSS().get("email"),
            user_id: LSS().get("user_id"),
        });
    } else {
        window.Intercom("boot", {
            app_id: "vmm6y8am",
        });
    }
    const openMobileMenu = () => {
        setMenuOpen(!menuOpen);
    };
    if (props.type == "registration") {
        return (
            <div className={style.slingerContainer}>
                <div
                    id="scrollable"
                    className={[style.page_inner, style.registration].join(" ")}
                >
                    <div className={style.floppy_banner}></div>
                    <div className={style.page_content}>{props.children}</div>
                </div>
            </div>
        );
    }

    if (props.type == "focussed") {
        return (
            <div className={style.slingerContainer}>
                <div
                    id="scrollable"
                    className={[style.page_inner, style.focussed].join(" ")}
                >
                    <Header title={props.title} />
                    <div className={style.page_content}>{props.children}</div>
                </div>
            </div>
        );
    }

    // Check where user is allowed to be.
    allowedAccess();
    return (
        <div className={style.slingerContainer}>
            <MenuProvider>
                {env !== "production" ? (
                    <div
                        style={{
                            width: "300px",
                            background: "#ff5600",
                            color: "white",
                            left: "50%",
                            fontSize: "14px",
                            bottom: "0",
                            marginLeft: "-150px",
                            position: "absolute",
                            textAlign: "center",
                            padding: "2px",
                            zIndex: "100",
                        }}
                    >
                        You're on {env}! TEST ONLY
                    </div>
                ) : (
                    ""
                )}
                <Sidebar />
                <div id="scrollable" className={[style.page_inner].join(" ")}>
                    <Header menuControl={openMobileMenu} title={props.title} />
                    <div className={style.page_content}>{props.children}</div>
                </div>
            </MenuProvider>
        </div>
    );
}
