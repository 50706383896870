import Blob from "../common/Blob";
import Button from "../common/Button";
import style from "./SubscriptionBreakdown.module.scss";
import grid from "../../styles/partials/_grid.module.scss";
import table from "../../styles/_table.module.scss";
import { priceFromPennies } from "../../helpers/general";
import Avatar from "../common/Avatar";
import getImageURL from "../../helpers/imageUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCalendar,
    faClock,
    faSubscript,
} from "@fortawesome/free-solid-svg-icons";
import Typography from "../Typography";
interface SubscriptionBreakdownInterface {
    subscriptions: any;
    past: boolean;
}
export default function SubscriptionBreakdown(
    props: SubscriptionBreakdownInterface,
) {
    return (
        <div>
            <div
                className={grid.invoice_container}
                style={{ marginTop: "16px" }}
            >
                <table className={table.description_table}>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Sites</th>
                            <th>Current Period</th>

                            <th>Payment Amount</th>
                            <th className={table.column_actions}>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className={table.spanner}>
                            <td colSpan={6}>
                                {Object.keys(props.subscriptions).length}{" "}
                                {props.past ? "past" : "active"} subscriptions
                                shown
                            </td>
                        </tr>
                        {Object.keys(props.subscriptions).map(
                            function (subscription_id) {
                                const subscription =
                                    props.subscriptions[subscription_id];
                                const firstSite = subscription.sites[0];
                                let colour = "#019e3a";
                                // Setup the bits of each state
                                (() => {
                                    switch (subscription.status) {
                                        case "active":
                                            colour = "#019e3a";
                                            return;
                                        case "canceled":
                                            colour = "#cecece";
                                            return;
                                        default:
                                            colour = "#fb6b02";
                                            return;
                                    }
                                })();
                                return (
                                    <tr key={subscription_id}>
                                        <td
                                            className={table.icon}
                                            style={{
                                                background: colour,
                                                padding: "0",
                                                width: "10px",
                                            }}
                                        ></td>
                                        <td className={table.location}>
                                            <div
                                                className={`${grid.row} ${grid.centreVertically}`}
                                            >
                                                <div className={table.avatar}>
                                                    <Avatar
                                                        size="tiny"
                                                        image={getImageURL(
                                                            firstSite.file1,
                                                        )}
                                                    ></Avatar>
                                                </div>
                                                <div className={style.location}>
                                                    <strong>
                                                        {firstSite.name}
                                                    </strong>
                                                    {subscription.sites.length >
                                                    1 ? (
                                                        <small>
                                                            {subscription.sites
                                                                .length -
                                                                1}{" "}
                                                            more site{" "}
                                                            {subscription.sites
                                                                .length -
                                                                1 >
                                                            1
                                                                ? "s"
                                                                : ""}
                                                        </small>
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            {props.past ? (
                                                <>
                                                    Cancelled
                                                    <small>
                                                        on{" "}
                                                        {
                                                            subscription.period_end
                                                        }
                                                    </small>
                                                </>
                                            ) : (
                                                <>
                                                    <FontAwesomeIcon
                                                        icon={faCalendar}
                                                        style={{
                                                            marginRight: "8px",
                                                        }}
                                                    />
                                                    {subscription.period_start}{" "}
                                                    - {subscription.period_end}
                                                    <small>
                                                        In{" "}
                                                        {subscription.days_left}{" "}
                                                        days
                                                    </small>
                                                </>
                                            )}
                                        </td>

                                        <td>
                                            {props.past ? (
                                                "None outstanding"
                                            ) : (
                                                <>
                                                    <strong>
                                                        {priceFromPennies(
                                                            subscription.upcoming_invoice_amount,
                                                        )}
                                                    </strong>
                                                    <small>
                                                        Will be charged*
                                                    </small>
                                                </>
                                            )}
                                        </td>
                                        <td className={table.column_actions}>
                                            {(() => {
                                                switch (subscription.status) {
                                                    case "active":
                                                        return (
                                                            <Button
                                                                style={{
                                                                    float: "right",
                                                                }}
                                                                href={
                                                                    "/subscriptions/view/" +
                                                                    subscription_id
                                                                }
                                                            >
                                                                View Invoices
                                                            </Button>
                                                        );
                                                    case "canceled":
                                                        return (
                                                            <Button
                                                                style={{
                                                                    float: "right",
                                                                    background:
                                                                        "#73bcf9",
                                                                }}
                                                                href={
                                                                    "/subscriptions/view/" +
                                                                    subscription_id
                                                                }
                                                            >
                                                                View Invoices
                                                            </Button>
                                                        );
                                                    default:
                                                        return (
                                                            <Button
                                                                style={{
                                                                    float: "right",
                                                                }}
                                                                href={
                                                                    "/subscriptions/view/" +
                                                                    subscription_id
                                                                }
                                                            >
                                                                Resolve
                                                                Subscription
                                                            </Button>
                                                        );
                                                }
                                            })()}
                                        </td>
                                    </tr>
                                );
                            },
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
}
