import apiService from "../api.service";

export default async function getAllRoles(): Promise<Object> {
    const cacheKey = "roles";
    const cacheTimeKey = "rolesTimestamp";
    const cacheDuration = 12 * 60 * 60 * 1000; // 10 minutes in milliseconds

    const currentTime = Date.now();
    const storedTime = localStorage.getItem(cacheTimeKey);
    const storedData = localStorage.getItem(cacheKey);

    if (
        storedTime &&
        storedData &&
        currentTime - parseInt(storedTime, 10) < cacheDuration
    ) {
        return JSON.parse(storedData);
    } else {
        try {
            const ApiService = new apiService();
            const responseData = await ApiService.get<Object>(
                "user/get-roles",
                null,
            );
            localStorage.setItem(cacheKey, JSON.stringify(responseData));
            localStorage.setItem(cacheTimeKey, currentTime.toString());

            return responseData;
        } catch (error) {
            console.error("Error in post request:", error);
            throw error;
        }

        return {};
    }
}
