import ApiService from "../api.service";

class UserAPI {
    static apiService = new ApiService();

    static async getNotifications(page): Promise<Object> {
        const body = { page: page };
        const responseData = await this.apiService.get<Object>(
            "notifications/list?page=" + page,
            body,
        );
        return responseData;
    }
    static async markNotificationAsRead(uuid): Promise<Object> {
        const body = { uuid: uuid };
        const responseData = await this.apiService.post<Object>(
            "notifications/mark-as-read",
            body,
        );
        return responseData;
    }

    static async getPublic(uuid, shift_uuid = null): Promise<Object> {
        const responseData = await this.apiService.get<Object>(
            "user/profile/" + uuid + "/" + shift_uuid,
            {},
        );
        return responseData;
    }

    static async getSitePermissions(site_uuid): Promise<Object> {
        const responseData = await this.apiService.post<Object>(
            "user/get-site-permissions",
            { site_uuid: site_uuid },
        );
        return responseData;
    }

    static async getBusinessPermissions(site_uuid): Promise<Object> {
        const responseData = await this.apiService.post<Object>(
            "user/get-site-permissions",
            { site_uuid: site_uuid },
        );
        return responseData;
    }

    static async markAllNotificationsAsRead(): Promise<Object> {
        const responseData = await this.apiService.get<Object>(
            "notifications/mark-all-as-read",
            null,
        );
        return responseData;
    }

    static async updatePicture(image): Promise<Object> {
        try {
            const responseData = await this.apiService.upload<Object>(
                "user/update-photo",
                image,
            );
            return responseData;
        } catch (error) {
            console.error("Error in post request:", error);
            throw error;
        }
    }
}
export default UserAPI;
