import ApiService from "./api.service";
import LSS from "../helpers/localStorageService";
class TimesheetAPI {
    static apiService = new ApiService();

    static async getOutstanding(page = 1): Promise<Object> {
        const body = { business_uuid: LSS().get("business_uuid") };
        const responseData = await this.apiService.post<Object>(
            "timesheets/list?page=" + page,
            body,
        );
        return responseData;
    }
    static async getAll(page = 1): Promise<Object> {
        const body = { business_uuid: LSS().get("business_uuid") };
        const responseData = await this.apiService.post<Object>(
            "timesheets/all?page=" + page,
            body,
        );
        return responseData;
    }
}
export default TimesheetAPI;
