import style from "./SubmenuItem.module.scss";
interface SubmenuItemPropsInterface {
    children: any;
    onClick: any;
    href: string;
    target: string;
}
function SubmenuItem(props: SubmenuItemPropsInterface) {
    return (
        <div className={style.submenuItem}>
            <a onClick={props.onClick} target={props.target} href={props.href}>
                {props.children}
            </a>
        </div>
    );
}

SubmenuItem.defaultProps = {
    target: "_self",
    onClick: null,
};

export default SubmenuItem;
