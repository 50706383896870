import style from "./Sidebar.module.scss";
import { isActivePage } from "../../../helpers/general";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ReactComponent as TrialShiftIcon } from "../../../assets/v2/trial-shift.svg";
import { ReactComponent as DashboardIcon } from "../../../assets/v2/dashboard.svg";
import { ReactComponent as ShiftIcon } from "../../../assets/v2/calendar.svg";
import { ReactComponent as TimesheetIcon } from "../../../assets/v2/timesheet.svg";
import { ReactComponent as SiteIcon } from "../../../assets/v2/site.svg";
import { ReactComponent as PaymentsIcon } from "../../../assets/v2/coin.svg";
import {
    faGauge,
    faClock,
    faBuilding,
    faFileInvoice,
    faShop,
    faCalendar,
    faFileContract,
} from "@fortawesome/free-solid-svg-icons";

import LSS from "../../../helpers/localStorageService";
import getImageURL from "../../../helpers/imageUtils";
import { useMenu } from "../../../hooks/menuContext";
import { canCreateShifts } from "../../../helpers/permissions";
import BusinessSwitcher from "../BusinessSwitcher";
function Sidebar() {
    const { isMenuOpen } = useMenu();

    const classes = [style.sidebar];
    if (isMenuOpen) {
        classes.push(style.open);
    }
    return (
        <aside className={classes.join(" ")}>
            <div className={style.logo}>
                <img src="/assets/images/slingerflatlogo.svg" />
            </div>
            <BusinessSwitcher />
            <div className={style.menu}>
                <ul>
                    <li
                        className={
                            isActivePage("dashboard") ? style.active : ""
                        }
                    >
                        <a href="/dashboard">
                            <DashboardIcon />
                            <span>Dashboard</span>
                            {LSS().get("unread_notifications") > 0 && (
                                <>
                                    <div>
                                        {LSS().get("unread_notifications")}
                                    </div>
                                </>
                            )}
                        </a>
                    </li>
                    <li className={isActivePage("shifts") ? style.active : ""}>
                        <a href="/shifts/agenda">
                            <ShiftIcon
                                style={{
                                    marginTop: "-2px",
                                    display: "inline-block",
                                }}
                            />
                            <span style={{ marginTop: "2px" }}>Shifts</span>
                        </a>
                        <ul className={style.submenu}>
                            {canCreateShifts() ? (
                                <li>
                                    <a href="/shifts/agenda">
                                        On-demand Shifts
                                    </a>
                                </li>
                            ) : (
                                ""
                            )}
                            <li>
                                <a href="/trial-shift">Trial Shifts</a>
                            </li>
                            <li>
                                <a href="/shifts">Calendar </a>
                            </li>
                        </ul>
                    </li>
                    <li
                        className={
                            isActivePage("trial-shift") ? style.active : ""
                        }
                    >
                        <a href="/trial-shift">
                            <TrialShiftIcon />
                            <span>Trial Shifts</span>
                        </a>
                        <ul className={style.submenu}>
                            {canCreateShifts() ? (
                                <li>
                                    <a href="/trial-shift">
                                        All Job Descriptions
                                    </a>
                                </li>
                            ) : (
                                ""
                            )}
                        </ul>
                    </li>
                    <li
                        className={
                            isActivePage("timesheets") ? style.active : ""
                        }
                    >
                        <a href="/timesheets">
                            <TimesheetIcon />
                            <span>Timesheets</span>
                        </a>
                        <ul className={style.submenu}>
                            <li>
                                <a href="/timesheets">Outstanding</a>
                            </li>

                            <li>
                                <a href="/timesheets/completed">
                                    Past Timesheets
                                </a>
                            </li>
                        </ul>
                    </li>
                    {/* {LSS().get("business_permissions").edit ? (
                        <li
                            className={
                                isActivePage("business") ? style.active : ""
                            }
                        >
                            <a href="/business">
                                <FontAwesomeIcon icon={faBuilding} />
                                <span>Business</span>
                            </a>
                            <ul className={style.submenu}>
                                <li>
                                    <a href="/business">Manage Business</a>
                                </li>
                            </ul>
                        </li>
                    ) : (
                        ""
                    )} */}
                    <li className={isActivePage("sites") ? style.active : ""}>
                        <a href="/sites">
                            <SiteIcon />
                            <span>Sites</span>
                        </a>
                    </li>
                    {LSS().get("business_permissions").view_invoices ? (
                        <li
                            className={
                                isActivePage("subscriptions") ||
                                isActivePage("invoices")
                                    ? style.active
                                    : ""
                            }
                        >
                            <a href="/subscriptions">
                                <PaymentsIcon />
                                <span>Payments</span>
                            </a>
                            <ul className={style.submenu}>
                                <li>
                                    <a href="/subscriptions">Subscriptions</a>
                                </li>
                                <li>
                                    <a href="/invoices">Pre-paid Shifts</a>
                                </li>
                            </ul>
                        </li>
                    ) : (
                        ""
                    )}
                </ul>
            </div>
        </aside>
    );
}

export default Sidebar;
