import apiService from "../api.service";

export default async function getNotifications(limit = 5): Promise<Object> {
    try {
        const ApiService = new apiService();
        const responseData = await ApiService.get<Object>(
            "notifications?limit=" + limit,
            null,
        );
        return responseData;
    } catch (error) {
        console.error("Error in post request:", error);
        throw error;
    }

    return {};
}
