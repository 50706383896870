import { MenuItem } from "@mui/material";
import Select from "@mui/material/Select";
import getImageURL from "../../../helpers/imageUtils";
import { useEffect, useState } from "react";

import getAllRoles from "../../../api/roles/getAllRoles";

interface RoleSelectorPropsInterface {
    default: string;
    onChange: any;
    disabled: boolean;
    create_only: boolean;
}
RoleSelector.defaultProps = {
    disabled: false,
    create_only: false,
    onChange: function () {
        console.log("");
    },
};
function RoleSelector(props: RoleSelectorPropsInterface) {
    const [roles, setRoles] = useState([]);
    const [loading, setLoading] = useState(true);
    const load = async () => {
        setLoading(true);
        await getAllRoles().then((response) => {
            setRoles(response.jobRoles);
            setLoading(false);
        });
    };

    useEffect(() => {
        load();
    }, []);

    if (loading) {
        return (
            <Select
                id="shift-location"
                defaultValue={props.default}
                style={{
                    width: "100%",
                }}
                disabled={props.disabled}
                onChange={props.onChange}
            >
                <MenuItem disabled={true} value="0">
                    <div>Loading Sites...</div>
                </MenuItem>
            </Select>
        );
    }
    return (
        <Select
            labelId="role_id"
            id="role_id"
            defaultValue={props.default}
            style={{
                width: "100%",
            }}
            disabled={props.disabled}
            onChange={props.onChange}
        >
            <MenuItem disabled={true} value="0">
                <em>Select a role</em>
            </MenuItem>
            {roles.length &&
                roles.map((role, i) => {
                    return (
                        <MenuItem value={role.id} key={i}>
                            <img
                                style={{
                                    width: "32px",
                                    marginRight: "16px",
                                }}
                                src={"/assets/images/icons/" + role.icon}
                            />
                            <div>
                                {role.name}
                                <small
                                    style={{
                                        width: "100%",
                                        display: "table",
                                    }}
                                >
                                    {" "}
                                    {role.role_category}
                                </small>
                            </div>
                        </MenuItem>
                    );
                })}
        </Select>
    );
}

export default RoleSelector;
