import apiService from "../api.service";
import LSS from "../../helpers/localStorageService";

export default async function getBusinessSites(): Promise<Object> {
    try {
        const ApiService = new apiService();
        const responseData = await ApiService.get<Object>(
            "sites?id=" + LSS().get("business").id,
            null,
        );
        return responseData;
    } catch (error) {
        console.error("Error in post request:", error);
        throw error;
    }
}

export async function getBusinessSitesWithCreate(): Promise<Object> {
    try {
        const ApiService = new apiService();
        const responseData = await ApiService.get<Object>(
            "sites/sites-with?id=" + LSS().get("business").id,
            null,
        );
        return responseData;
    } catch (error) {
        console.error("Error in post request:", error);
        throw error;
    }
}
