import { MenuItem } from "@mui/material";
import Select from "@mui/material/Select";
import getImageURL from "../../../helpers/imageUtils";
import { useEffect, useState } from "react";
import getBusinessSites, {
    getBusinessSitesWithCreate,
} from "../../../api/sites/getBusinessSites";

interface SiteSelectorPropsInterface {
    default: string;
    onChange: any;
    disabled: boolean;
    create_only: boolean;
}
SiteSelector.defaultProps = {
    disabled: false,
    create_only: false,
    onChange: function (event) {
        window.location.href = "/sites/view/" + event.target.value;
    },
};
function SiteSelector(props: SiteSelectorPropsInterface) {
    const [sites, setSites] = useState([]);
    const [loading, setLoading] = useState(true);
    const load = async () => {
        setLoading(true);

        if (props.create_only) {
            await getBusinessSitesWithCreate().then((response) => {
                setSites(response.sites);
                setLoading(false);
            });
        } else {
            await getBusinessSites().then((response) => {
                setSites(response.sites);
                setLoading(false);
            });
        }
    };

    useEffect(() => {
        load();
    }, []);

    if (loading) {
        return (
            <Select
                id="shift-location"
                defaultValue={props.default}
                style={{
                    width: "100%",
                }}
                disabled={props.disabled}
                onChange={props.onChange}
            >
                <MenuItem disabled={true} value="0" selected={true}>
                    <div>Loading Sites...</div>
                </MenuItem>
            </Select>
        );
    }
    return (
        <Select
            id="shift-location"
            value={props.default ?? 0}
            defaultValue="0"
            style={{
                width: "100%",
            }}
            disabled={props.disabled}
            onChange={props.onChange}
        >
            <MenuItem disabled={true} value="0">
                <em>Select a site</em>
            </MenuItem>
            {sites.length &&
                sites.map((site) => {
                    return (
                        <MenuItem
                            value={site.uuid}
                            key={site.uuid}
                            selected={site.uuid == props.default}
                        >
                            <img
                                style={{
                                    width: "48px",
                                    marginRight: "16px",
                                    borderRadius: "8px",
                                }}
                                src={getImageURL(site.file1)}
                            />
                            <div>
                                {site.name}
                                <small
                                    style={{
                                        width: "100%",
                                        display: "table",
                                    }}
                                >
                                    {site.address_line1} {site.city}
                                </small>
                            </div>
                        </MenuItem>
                    );
                })}
        </Select>
    );
}

export default SiteSelector;
