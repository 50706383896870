/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from "react";
import table from "../../styles/_table.module.scss";
import Typography from "../../components/Typography";
import Blob from "../../components/common/Blob";
import grid from "../../styles/partials/_grid.module.scss";
import SlingerContainer from "../SlingerContainer";
import SubscriptionAPI from "../../api/subscription.service";
import { useParams } from "react-router";
import Loading from "../../components/common/Loading";
import style from "./SingleSubscriptionContainer.module.scss";
import Button from "../../components/common/Button";
import { priceFromPennies } from "../../helpers/general";
import dayjs from "dayjs";
import SiteCard from "../../components/Site/SiteCard";
import { faEllipsis, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Pill from "../../components/common/Pill";

export default function SingleSubscriptionContainer() {
    const { subscription_uuid } = useParams() as { subscription_uuid: string };
    const [isLoading, setIsLoading] = useState(true);
    const [stats, setStats] = useState([]);
    const [invoices, setInvoices] = useState([]);
    const [payload, setPayload] = useState([]);
    const [upcoming, setUpcoming] = useState([]);
    document.title = "Slinger Business | Subscription Breakdown";
    useEffect(() => {
        SubscriptionAPI.getSingleBreakdown(subscription_uuid).then(
            (response) => {
                setStats(response.stats);
                setUpcoming(response.invoice_lines);
                setInvoices(response.past_invoices);
                setPayload(response);
                setIsLoading(false);
            },
        );
    }, []);

    if (isLoading) {
        return (
            <SlingerContainer title="Subscriptions">
                <Loading
                    type="internal"
                    title="Fetching your subscription info..."
                />
            </SlingerContainer>
        );
    }

    return (
        <SlingerContainer title="Subscriptions">
            <div className={grid.row} style={{ marginBottom: "32px" }}>
                <div className={grid.twoThird}>
                    <Button href="/subscriptions">Back to subscriptions</Button>
                </div>
                <div className={grid.third}></div>
            </div>

            <div className={grid.row} style={{ marginBottom: "32px" }}>
                <div className={grid.twoThird}>
                    <Blob size="full" title="Upcoming Invoice">
                        <div className={grid.row}>
                            <div className={grid.third}>
                                <div className={grid.powercard}>
                                    <h1>
                                        {priceFromPennies(
                                            payload.upcoming_invoice_amount,
                                        )}
                                    </h1>
                                    <small>Pending Invoice Amount*</small>
                                </div>
                            </div>
                            <div className={grid.third}>
                                <div className={grid.powercard}>
                                    <h1>{payload.period_end}</h1>
                                    <small>Expected Payment Date</small>
                                </div>
                            </div>
                            {/* <div className={grid.third}>
                                <div className={grid.powercard}>
                                    <h1>Card Ending 4444</h1>
                                    <small>Payment Method</small>
                                </div>
                            </div> */}
                        </div>
                        <div className={grid.invoice_container}>
                            <table className={table.invoice_table}>
                                <thead>
                                    <th>Description</th>
                                    <th>Amount</th>
                                    <th className={table.column_actions}>
                                        <FontAwesomeIcon icon={faEllipsis} />
                                    </th>
                                </thead>
                                <tbody>
                                    {upcoming.map((line, i) => {
                                        return (
                                            <tr key={i}>
                                                {" "}
                                                <td>{line.description}</td>
                                                <td>
                                                    {priceFromPennies(
                                                        line.amount,
                                                    )}
                                                </td>
                                                <td
                                                    className={
                                                        table.column_actions
                                                    }
                                                >
                                                    {line.shift_uuid ? (
                                                        <Button
                                                            type="link"
                                                            target="blank"
                                                            href={
                                                                "/shift/view/" +
                                                                line.shift_uuid
                                                            }
                                                        >
                                                            <FontAwesomeIcon
                                                                title="View Shift"
                                                                icon={faEye}
                                                            />
                                                        </Button>
                                                    ) : (
                                                        ""
                                                    )}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                                <tfoot>
                                    <tr className={table.fixed_hover}>
                                        <td colSpan={1}></td>
                                        <td
                                            style={{ padding: "8px 0px" }}
                                            colSpan={2}
                                        >
                                            <table
                                                className={table.invoice_sub}
                                            >
                                                <tr>
                                                    <td className={table.item}>
                                                        Subtotal
                                                    </td>
                                                    <td>
                                                        {priceFromPennies(
                                                            payload.subtotal,
                                                        )}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className={table.item}>
                                                        VAT
                                                    </td>
                                                    <td>
                                                        {priceFromPennies(
                                                            payload.vat,
                                                        )}
                                                    </td>
                                                </tr>
                                                <tr
                                                    className={
                                                        table.grand_total
                                                    }
                                                >
                                                    <td className={table.item}>
                                                        Total
                                                    </td>
                                                    <td>
                                                        {priceFromPennies(
                                                            payload.upcoming_invoice_amount,
                                                        )}
                                                        *
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                        <Typography
                            variant="small"
                            className={["mt-16", "mb-32"]}
                        >
                            <span
                                style={{
                                    width: "100%",
                                    display: "inline-block",
                                }}
                            >
                                * payment amounts are finalised up to midnight
                                of the period. Any shifts completed and
                                confirmed will still be added to this bill
                            </span>
                        </Typography>
                    </Blob>

                    <Typography variant="h2" className={["mb-16", "mt-32"]}>
                        Past Invoices
                    </Typography>
                    <Blob size="full" padded_inner={false}>
                        <table className={table.description_table}>
                            <thead>
                                <th></th>
                                <th>Period</th>
                                <th>Amount</th>
                                <th>Status</th>
                                <th className={table.column_actions}>
                                    View Invoice
                                </th>
                            </thead>

                            <tbody>
                                {payload.past_invoices.map((invoice, i) => {
                                    let colour = "#019e3a";
                                    // Setup the bits of each state

                                    (() => {
                                        switch (invoice.status) {
                                            case "active":
                                                colour = "#019e3a";
                                                return;
                                            case "paid":
                                                colour = "#019e3a";
                                                return;
                                            case "canceled":
                                                colour = "#cecece";
                                                return;
                                            default:
                                                colour = "#fb6b02";
                                                return;
                                        }
                                    })();
                                    return (
                                        <tr key={i}>
                                            <td
                                                className={table.icon}
                                                style={{
                                                    background: colour,
                                                    padding: "0",
                                                    width: "10px",
                                                }}
                                            ></td>
                                            <td>
                                                {dayjs(
                                                    invoice.invoice_period
                                                        .start,
                                                ).format("DD/MM/YY")}{" "}
                                                -{" "}
                                                {dayjs(
                                                    invoice.invoice_period.end,
                                                ).format("DD/MM/YY")}
                                            </td>
                                            <td>
                                                {priceFromPennies(
                                                    invoice.amount_to_pay,
                                                )}
                                            </td>
                                            <td>
                                                <Pill
                                                    type="primary"
                                                    label={invoice.status}
                                                />
                                            </td>
                                            <td
                                                className={table.column_actions}
                                            >
                                                {invoice.status == "draft" ||
                                                invoice.status == "pending" ? (
                                                    ""
                                                ) : (
                                                    <Button
                                                        href={
                                                            invoice.link_to_pdf
                                                        }
                                                        target="blank"
                                                    >
                                                        {invoice.status ==
                                                        "paid"
                                                            ? "View Invoice"
                                                            : "Resolve Invoice"}
                                                    </Button>
                                                )}
                                                {invoice.status == "pending" ? (
                                                    <Button
                                                        href={
                                                            invoice.link_to_pdf
                                                        }
                                                        target="blank"
                                                    >
                                                        {"View Pending Payment"}
                                                    </Button>
                                                ) : (
                                                    ""
                                                )}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </Blob>
                </div>
                <div className={grid.third}>
                    <Blob size="full" title="Sites">
                        <Typography variant="p" className={["mb-8"]}>
                            This subscription is attached to{" "}
                            {payload.sites.length} sites:
                        </Typography>
                        {payload.sites.map((site) => {
                            return (
                                <SiteCard
                                    key={site.id}
                                    site={site}
                                    view={true}
                                />
                            );
                        })}
                    </Blob>
                    <Blob size="full" title="Changing a subscription">
                        <Typography variant="p">
                            If you need to change the subscription invoice day,
                            the payment method or have a query about the
                            subscription please contact our team.
                        </Typography>
                        <Typography variant="small" className={["mb-16"]}>
                            We'll need to confirm with that any changes won't
                            affect upcoming shifts.
                        </Typography>
                        <Button
                            onClick={function () {
                                Intercom("show");
                            }}
                        >
                            Contact Slinger Support
                        </Button>
                    </Blob>
                </div>
            </div>
        </SlingerContainer>
    );
}
