import { Alert, MenuItem, Select, TextField } from "@mui/material";
import Avatar from "../../Avatar";
import style from "./RequestNewSlinger.module.scss";
import QRCode from "react-qr-code";

import React, { useState } from "react";
import { Modal as MUIModal, Box } from "@mui/material";
import Button from "../../Button";
import JobDescriptionAPI from "../../../../api/jobDescriptions.service";
import ShiftsAPI from "../../../../api/shifts/shifts.client";
import Typography from "../../../Typography";

interface ModalProps {
    open: boolean;
    onClose: () => void;
    shift: any;
    isSoon: boolean;
    isBlocked: boolean;
}

const RequestNewSlinger: React.FC<ModalProps> = ({
    open,
    onClose,
    shift,
    isSoon,
    isBlocked,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [cancellationReason, setCancellationReason] = useState("");
    const [otherReason, setOtherReason] = useState("");

    const requestNew = () => {
        setIsLoading(true);
        let reason = cancellationReason;
        if (cancellationReason == "Other") {
            reason = otherReason;
        }
        new ShiftsAPI()
            .requestNewSlinger(shift.id, reason)
            .then(() => {
                const currentUrl = new URL(window.location.href);
                currentUrl.searchParams.set("reroll", "1");
                window.location.href = currentUrl.toString();
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleChange = (event) => {
        setCancellationReason(event.target.value);
        if (event.target.value !== "Other") {
            setOtherReason("");
        }
    };
    return (
        <MUIModal open={open} onClose={onClose}>
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",

                    bgcolor: "background.paper",
                    boxShadow: 24,
                    borderRadius: "5px",
                    overflow: "hidden",
                    p: 1,
                }}
            >
                <div
                    className={style.request_new}
                    style={{ maxWidth: "500px" }}
                >
                    <Typography variant="p" className={["mt-0"]}>
                        Sorry to hear you feel you need a new slinger. In most
                        cases the first few slingers to take shifts are the best
                        for the role.
                    </Typography>

                    {isSoon || isBlocked ? (
                        isSoon ? (
                            <Alert severity={"warning"}>
                                <Typography variant="small">
                                    This shift is within the next 24 hours, as
                                    per our T&C's, you aren't able to change now
                                    without incurring a fee. Please contact
                                    support if you feel you need to change out
                                    for a new slinger.
                                </Typography>
                            </Alert>
                        ) : (
                            <Alert severity={"warning"}>
                                <Typography variant="small">
                                    It looks as though you've used all of your
                                    new slinger requests for this shift. If you
                                    feel strongly that you need another slinger,
                                    please contact support.
                                </Typography>
                            </Alert>
                        )
                    ) : (
                        <>
                            <Typography variant="p" className={["mt-0"]}>
                                All of our slingers have been vetted before
                                having access to the platform. Please select the
                                reason below to request.
                            </Typography>
                            <Typography variant="small">
                                Cancellation Reason:{" "}
                            </Typography>
                            <Select
                                id="slinger_number"
                                style={{
                                    width: "100%",
                                    marginBottom: "16px",
                                }}
                                label="Request reason:"
                                value={cancellationReason}
                                onChange={handleChange}
                            >
                                <MenuItem value="No Profile Picture">
                                    Slinger is missing profile picture
                                </MenuItem>
                                <MenuItem value="Poor Profile Picture">
                                    Slinger has a poor profile picture
                                </MenuItem>
                                <MenuItem value="No Experience">
                                    Slinger has no relevant experience
                                </MenuItem>
                                <MenuItem value="Low References">
                                    Slinger has low references
                                </MenuItem>
                                <MenuItem value="Bad References">
                                    Slinger has bad references
                                </MenuItem>
                                <MenuItem value="Other">Other</MenuItem>
                            </Select>
                            {cancellationReason === "Other" && (
                                <TextField
                                    label="Please specify"
                                    value={otherReason}
                                    onChange={(event) =>
                                        setOtherReason(event.target.value)
                                    }
                                    fullWidth
                                    style={{
                                        marginBottom: "16px",
                                    }}
                                />
                            )}
                            <Alert severity="info">
                                <Typography variant="small">
                                    We limit the number of requests to 3. You
                                    currently have{" "}
                                    <strong>{3 - shift.rejections}</strong>{" "}
                                    remaining.
                                </Typography>
                            </Alert>
                        </>
                    )}
                    <div className={style.action}>
                        <button onClick={requestNew} disabled={isLoading}>
                            Request New
                        </button>
                    </div>
                </div>
            </Box>
        </MUIModal>
    );
};

export default RequestNewSlinger;
