import React from "react";
import dayjs from "dayjs";
import style from "../ShiftCreationModal.module.scss";
import Pill from "../../../Pill";
import {
    calculateTotalCost,
    calculateTotalHours,
    calculateBaseWage,
    calculateFeeCost,
} from "../../../../../helpers/shiftHelpers";
import { priceFromPennies } from "../../../../../helpers/general";
const BreakdownPage: React.FC<{ formData: any; fee: number }> = ({
    formData,
    fee,
}) => {
    calculateTotalCost;
    if (formData.start_time == null || formData.end_time == null) {
        return "";
    }
    return (
        <div className={style.billingTotal}>
            <div className={style.breakdown}>
                <Pill
                    type="small"
                    label={
                        "Hours: " +
                        calculateTotalHours(
                            formData.start_time,
                            formData.end_time,
                        ).toFixed(2)
                    }
                />

                <Pill
                    type="small"
                    label={
                        "Wage: " +
                        priceFromPennies(
                            calculateBaseWage(
                                100 * formData.price_per_hour,
                                calculateTotalHours(
                                    formData.start_time,
                                    formData.end_time,
                                ),
                                formData.number_people,
                            ),
                        )
                    }
                />
                <Pill
                    type="small"
                    label={
                        "Fee: " +
                        priceFromPennies(
                            calculateFeeCost(
                                fee,
                                calculateTotalHours(
                                    formData.start_time,
                                    formData.end_time,
                                ),
                                formData.number_people,
                            ),
                        )
                    }
                />
                <div className={style.total}>
                    Total:{" "}
                    {priceFromPennies(
                        calculateTotalCost(
                            100 * formData.price_per_hour,
                            fee,
                            formData.start_time,
                            formData.end_time,
                            formData.number_people,
                        ),
                    )}
                    <small>Incl. VAT</small>
                </div>
            </div>
        </div>
    );
};

export default BreakdownPage;
