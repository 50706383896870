import style from "./ShiftListItem.module.scss";
import grid from "../../../../styles/partials/_grid.module.scss";
import dayjs from "dayjs";
import Button from "../../../common/Button";
import { Alert, Rating, Tooltip } from "@mui/material";
import Avatar from "../../../common/Avatar";
import getImageURL from "../../../../helpers/imageUtils";
import Typography from "../../../Typography";
import Pill from "../../../common/Pill";
import { useModal, ModalType } from "../../../../hooks/ModalContext";
import { ReactComponent as TimesheetIcon } from "../../../../assets/v2/timesheet.svg";
import { ReactComponent as QRIcon } from "../../../../assets/v2/qr.svg";
interface ShiftListItemPropsInterface {
    shift: Shift;
    withWage: boolean;
    withDate: boolean;
    displayOnly: boolean;
}

ShiftListItem.defaultProps = {
    withWage: false,
    withDate: false,
    displayOnly: false,
};
function ShiftListItem(props: ShiftListItemPropsInterface) {
    const { openModal } = useModal();
    const classes = [];
    classes.push(style.shift_list_item);
    if (props.displayOnly) {
        classes.push(style.view_only);
    }

    return (
        <div className={classes.join(" ")}>
            <div
                className={[grid.row, grid.centreVertically, grid.noGap].join(
                    " ",
                )}
            >
                {/* {!props.displayOnly ? <a className={grid.coverall}></a> : ""} */}

                <div className={style.date_time}>
                    {props.withDate ? (
                        <>
                            <strong>
                                {dayjs(props.shift.start_time).format(
                                    "DD/MM/YYYY",
                                )}
                            </strong>
                            <small>
                                {dayjs(props.shift.start_time).format("HH:mm")}{" "}
                                - {dayjs(props.shift.end_time).format("HH:mm")}
                            </small>
                        </>
                    ) : (
                        <div>
                            <div className={style.time}>
                                <TimesheetIcon />
                                <strong>
                                    {dayjs(props.shift.start_time).format(
                                        "HH:mm",
                                    )}{" "}
                                    -{" "}
                                    {dayjs(props.shift.end_time).format(
                                        "HH:mm",
                                    )}
                                </strong>
                            </div>
                            {props.shift.trial_shift ? (
                                <div style={{ marginTop: "4px" }}>
                                    <Pill
                                        type={"tertiary"}
                                        label="Trial Shift"
                                    />
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                    )}
                </div>
                {props.shift.site.file1 !== undefined ? (
                    <div className={style.location_image}>
                        <Avatar
                            size="tiny"
                            image={getImageURL(props.shift.site.file1)}
                        ></Avatar>
                    </div>
                ) : (
                    ""
                )}
                <div className={style.location}>
                    <div>
                        <strong>{props.shift.role.name}</strong>
                        <small>{props.shift.site.name}</small>
                    </div>
                </div>

                {props.withWage ? (
                    <div className={style.wage}>
                        <strong>
                            £{(props.shift.total_price / 100).toFixed(2)}
                        </strong>
                        <small>
                            £{(props.shift.wage / 100).toFixed(2)} per hour
                        </small>
                    </div>
                ) : (
                    ""
                )}
                {props.shift.taken_shift_info === null &&
                props.shift.slinger === null ? (
                    <div className={style.taker}>
                        <Alert severity="warning">Unclaimed</Alert>
                    </div>
                ) : (
                    <div className={style.taker}>
                        <div
                            className={[
                                grid.row,
                                grid.noGap,
                                grid.centreVertically,
                            ].join(" ")}
                        >
                            <Avatar
                                image={getImageURL(
                                    props.shift.slinger?.profile_picture,
                                )}
                                size="tiny"
                            ></Avatar>
                            <div
                                style={{
                                    marginLeft: "8px",
                                    marginTop: "4px",
                                    maxWidth: "100px",
                                }}
                            >
                                <Typography
                                    variant="small"
                                    className={["no_overflow"]}
                                >
                                    {props.shift.slinger?.first_name}
                                </Typography>
                                <Rating
                                    value={5}
                                    size="small"
                                    readOnly={true}
                                />
                            </div>
                        </div>
                    </div>
                )}
                {!props.displayOnly ? (
                    <div className={style.action}>
                        {props.shift.taken_shift_info === null &&
                        props.shift.slinger === null ? (
                            ""
                        ) : (
                            <Tooltip
                                title="Show the clock-in QR code for this shift"
                                placement={"bottom"}
                                arrow
                            >
                                <div>
                                    <Button
                                        type="qr"
                                        onClick={() =>
                                            openModal(ModalType.QR, {
                                                imageUrl: getImageURL(
                                                    props.shift.slinger
                                                        ?.profile_picture,
                                                ),
                                                text: props.shift.site.uuid,
                                            })
                                        }
                                    >
                                        <QRIcon />
                                    </Button>
                                </div>
                            </Tooltip>
                        )}
                        <Button href={"/shift/view/" + props.shift.id}>
                            View
                        </Button>
                    </div>
                ) : (
                    ""
                )}
            </div>
        </div>
    );
}
export default ShiftListItem;
