import style from "./NotificationDropdown.module.scss";
import Submenu from "../../Submenu";
import { useEffect, useState } from "react";
import getNotifications from "../../../../api/user/getNotifications";
import getImageURL from "../../../../helpers/imageUtils";
interface NotificationDropdownPropsInterface {
    isOpen: boolean;
}
function NotificationDropdown(props: NotificationDropdownPropsInterface) {
    const [notifications, setNotifications] = useState([] as {}[]);
    const [isLoading, setIsLoading] = useState(false);
    const load = async () => {
        if (props.isOpen) {
            setIsLoading(true);
            try {
                const response = await getNotifications(5);
                setNotifications(response.notifications);

                setIsLoading(false);
            } catch (error: any) {
                console.error("Error", error);
            }
        }
    };

    useEffect(() => {
        load();
    }, [props.isOpen]);

    return (
        <Submenu isOpen={props.isOpen} type="freeform">
            <div className={style.notification_list}>
                {notifications.length ? (
                    notifications.map((notification, i) => {
                        const icon = notification.data.icon
                            ? getImageURL(notification.data.icon)
                            : "/android-chrome-192x192.png";
                        return (
                            <div key={i} className={style.notification}>
                                <img src={icon} />
                                <div>
                                    <small className={style.notifier}>
                                        {notification.data.title}
                                    </small>
                                    <span className={style.notitext}>
                                        {notification.data.body.length > 120
                                            ? notification.data.body.substring(
                                                  0,
                                                  120,
                                              ) + "..."
                                            : notification.data.body}
                                    </span>
                                    {notification.data.link && (
                                        <a href={notification.data.link}>
                                            View
                                        </a>
                                    )}
                                </div>
                            </div>
                        );
                    })
                ) : isLoading ? (
                    <small className={style.notice}>
                        Loading notifications...
                    </small>
                ) : (
                    <small className={style.notice}>No new notifications</small>
                )}
            </div>
            <div className={style.mini_menu}>
                <a href="/notifications">View All</a>
            </div>
        </Submenu>
    );
}
export default NotificationDropdown;
