import style from "./ProfileMenu.module.scss";
import Submenu from "../../Submenu";
import SubmenuItem from "../../SubmenuItem";
interface ProfileMenuPropsInterface {
    isOpen: boolean;
}
function ProfileMenu(props: ProfileMenuPropsInterface) {
    return (
        <Submenu isOpen={props.isOpen}>
            <SubmenuItem href={"/profile"}>Profile</SubmenuItem>
            <SubmenuItem
                onClick={function () {
                    Intercom("show");
                }}
                href="#"
            >
                Support
            </SubmenuItem>
            <SubmenuItem href={"/logout"}>Logout</SubmenuItem>
        </Submenu>
    );
}
export default ProfileMenu;
