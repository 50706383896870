/* eslint-disable unused-imports/no-unused-vars */
import SlingerContainer from "../SlingerContainer";
import grid from "../../styles/partials/_grid.module.scss";
import Blob from "../../components/common/Blob";
import { useEffect, useState } from "react";
import Loading from "../../components/common/Loading";
import { useRecoilState } from "recoil";
import { jobCreationState } from "../../state/atoms/jobCreationAtom";
import JobEditorForm from "../../forms/JobEditor/JobEditor.form";
import { useParams } from "react-router-dom";
import JobDescriptionAPI from "../../api/jobDescriptions.service";
import dayjs from "dayjs";
import LSS from "../../helpers/localStorageService";
interface ScreeningQuestion {
    uuid: string;
    jd_uuid: string;
    question: string;
}

interface JobDescription {
    uuid: string;
    site_id: string;
    site_name: string;
    business_id: string;
    role_id: number;
    role_name: string;
    description: string;
    contract_type: string;
    start_date: dayjs.Dayjs;
    preferred_skill_level: number;
    minimum_skill_level: number;
    wage: number;
    wage_type: string;
    metadata: any;
    endorsements: [];
    screening_questions: ScreeningQuestion[];
}
// Load Container with all the relevant pieces for the page component to load
export default function JobDescriptionCreatorContainer() {
    const [jobData, setJobData] = useRecoilState(jobCreationState);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const { job_uuid } = useParams() as { job_uuid: string };

    const loadJobIntoState = async () => {
        try {
            const response = await JobDescriptionAPI.get(job_uuid);
            const data = response;

            const mappedData: JobDescription = {
                uuid: data.uuid,
                site_id: data.site.uuid,
                site_name: data.site.name,
                business_id: LSS().get("business").uuid,
                role_id: data.role.id,
                role_name: data.role.name,
                description: data.description,
                contract_type: data.contract_type,
                start_date: dayjs(data.start_date),
                preferred_skill_level: parseFloat(data.preferred_skill_level),
                minimum_skill_level: parseFloat(data.minimum_skill_level),
                wage: data.wage / 100,
                wage_type: data.wage_type,
                metadata: data.metadata,
                endorsements: data.endorsements.map(
                    (endorsement: any) => endorsement.id,
                ),
                screening_questions: data.screening_questions,
            };

            setJobData(mappedData);
        } catch (err) {
            setIsError(true);
            console.error("Failed to load job data", err);
        } finally {
            setIsLoading(false);
        }
    };

    const load = async () => {
        try {
            setIsError(false);
            if (job_uuid && jobData.uuid === "") {
                loadJobIntoState();
            } else {
                setIsLoading(false);
            }
        } catch (err: any) {
            setIsLoading(false);
            setIsError(true);
        }
    };

    useEffect(() => {
        load();
    }, []);
    if (isLoading) {
        return <Loading />;
    }
    return (
        <SlingerContainer title="Job Description" type="registration">
            <div className={grid.maxout}>
                <div className={grid.row}>
                    <JobEditorForm />
                </div>
            </div>
        </SlingerContainer>
    );
}
