import Typography from "../../../components/Typography";
import Blob from "../../../components/common/Blob";
import grid from "../../../styles/partials/_grid.module.scss";
function JEOpener() {
    return (
        <Blob
            title="Job Description Creation"
            size={"full"}
            padded_inner={false}
        >
            <div className={grid.form_inner}>
                <Typography variant="small" className={["mb-16"]}>
                    This works slightly differently to your standard hiring
                    platform, there's a few steps to keep you in control of your
                    hiring and to give you the flexibility to hire how you want.
                </Typography>
                <Typography variant="h3" className={["mt-16"]}>
                    How it works:
                </Typography>
                <ul>
                    <li>
                        <strong>Create a job description:</strong>
                        <Typography variant="small" className={["mb-16"]}>
                            Creating a job description is the first step. Once
                            you've created it you can add trial shifts to find
                            the candidate that suits your needs.
                        </Typography>
                    </li>
                    <li>
                        <strong>Create Trial Shifts:</strong>
                        <Typography variant="small" className={["mb-16"]}>
                            Similar to how we create on-demand shifts, you can
                            create shorter shifts to trial slingers for the
                            role. You will be able to reject/offer the role to
                            slingers after each shift with no limit.
                        </Typography>
                    </li>
                    <li>
                        <strong>At your own speed:</strong>
                        <Typography variant="small" className={["mb-16"]}>
                            We don't rush you, so if you want to complete 2
                            trial shifts, or 20 trial shifts before making your
                            decision - that's entirely up to you.
                        </Typography>
                    </li>
                    <li>
                        <strong>Saved for later:</strong>
                        <Typography variant="small" className={["mb-16"]}>
                            Once this job description is created you'll be able
                            to reuse it in any future hiring round for that role
                            type. No repeating, just open and go.
                        </Typography>
                    </li>
                </ul>
            </div>
        </Blob>
    );
}

export default JEOpener;
