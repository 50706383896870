/* eslint-disable unused-imports/no-unused-vars */
import SlingerContainer from "../SlingerContainer";
import grid from "../../styles/partials/_grid.module.scss";
import style from "./JobDescriptionList.module.scss";
import Blob from "../../components/common/Blob";
import Button from "../../components/common/Button";
import { useEffect, useState } from "react";
import JobDescriptionAPI from "../../api/jobDescriptions.service";
import Loading from "../../components/common/Loading";

import JDList from "../../components/JobDescription/List";
import SubscriptionAPI from "../../api/subscription.service";
import { Alert } from "@mui/material";
import Typography from "../../components/Typography";
// Load Container with all the relevant pieces for the page component to load
export default function JobDescriptionListContainer() {
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [active, setActive] = useState(false);
    const [activeFilter, setActiveFilter] = useState("all");
    const [jobs, setJobs] = useState([]);

    function getMatchingNamesInSection(data: any[], ids: any[]): string[] {
        const idValues = ids.map((item) => item.id);

        return data
            .filter((item) => idValues.includes(item.value))
            .map((item) => item.label);
    }

    const getJobs = async (filter) => {
        // Get single shift
        setIsLoading(true);
        JobDescriptionAPI.list(filter)
            .then((response) => {
                setJobs(response.data);

                setIsLoading(false);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const load = async () => {
        await SubscriptionAPI.hasAny()
            .then((response) => {
                setActive(response.has_active);
                if (response.has_active) {
                    try {
                        setIsError(false);
                        getJobs(activeFilter);
                    } catch (err: any) {
                        setIsLoading(false);
                        setIsError(true);
                    }
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        load();
    }, []);

    return (
        <SlingerContainer title="Trial Shift">
            <div className={grid.maxout}>
                <Blob
                    padded_inner={false}
                    size="full"
                    title="Job Descriptions"
                    subtitle="Access your job descriptions below, using the filters to explore past job postings."
                >
                    <div className={style.list_inner}>
                        <div className={grid.row}>
                            <div className={grid.filter}>
                                <span
                                    className={`${
                                        activeFilter == "all" ? grid.active : ""
                                    }`}
                                    onClick={function () {
                                        setActiveFilter("all");
                                        getJobs("all");
                                    }}
                                >
                                    All
                                </span>

                                <span
                                    className={`${
                                        activeFilter == "active"
                                            ? grid.active
                                            : ""
                                    }`}
                                    onClick={function () {
                                        setActiveFilter("active");
                                        getJobs("active");
                                    }}
                                >
                                    Active
                                </span>
                                <span
                                    className={`${
                                        activeFilter == "inactive"
                                            ? grid.active
                                            : ""
                                    }`}
                                    onClick={function () {
                                        setActiveFilter("inactive");
                                        getJobs("inactive");
                                    }}
                                >
                                    Inactive
                                </span>
                                <span
                                    className={`${
                                        activeFilter == "filled"
                                            ? grid.active
                                            : ""
                                    }`}
                                    onClick={function () {
                                        setActiveFilter("filled");
                                        getJobs("filled");
                                    }}
                                >
                                    Filled
                                </span>

                                <span
                                    className={`${
                                        activeFilter == "closed"
                                            ? grid.active
                                            : ""
                                    }`}
                                    onClick={function () {
                                        setActiveFilter("closed");
                                        getJobs("closed");
                                    }}
                                >
                                    Closed
                                </span>
                            </div>
                            {active ? (
                                <Button
                                    style={{ marginLeft: "auto" }}
                                    href="/trial-shift/create"
                                    type="green"
                                >
                                    Create New Job Description
                                </Button>
                            ) : (
                                ""
                            )}
                        </div>
                        {isLoading ? (
                            <Loading />
                        ) : active ? (
                            <JDList jobs={jobs} filter={activeFilter} />
                        ) : (
                            <Alert severity="info">
                                <Typography variant="h3" className={["mt-0"]}>
                                    You don't have any active hiring plans
                                </Typography>
                                <Typography variant="p" className={["mt-0"]}>
                                    You need an active hiring plan at one of
                                    your sites in order to use Trial shifts and
                                    create job descriptions. If you're
                                    interested in full time hiring-
                                </Typography>
                                <Button
                                    onClick={function () {
                                        Intercom("show");
                                    }}
                                >
                                    {" "}
                                    Contact Us
                                </Button>
                            </Alert>
                        )}
                    </div>
                </Blob>
            </div>
        </SlingerContainer>
    );
}
