import dayjs from "dayjs";
import ShiftCompact from "../entities/ShiftCompact.entity";

// shiftHelpers.ts
export const removeItemAtIndex = (arr, index) => [
    ...arr.slice(0, index),
    ...arr.slice(index + 1),
];

export const addOrReplaceItemAtIndex = (array, index, newItem) => {
    const newArray = [...array];
    if (index >= 0 && index < newArray.length) {
        newArray[index] = newItem;
    } else {
        newArray.push(newItem);
    }
    return newArray;
};

export const defaultShift = (now) => {
    const tomorrowAtNine = now.add(1, "day").set("hour", 9).set("minute", 0);
    const tomorrowAtFive = now.add(1, "day").set("hour", 17).set("minute", 0);

    return new ShiftCompact({
        number_people: 1,
        shift_date: tomorrowAtNine,
        start_time: tomorrowAtNine,
        end_time: tomorrowAtFive,
        price_per_hour: 13.15,
        num_hours: 8,
    });
};

export const calculateTotalHours = (startDateTime: any, endDateTime: any) => {
    const start = dayjs(startDateTime);
    const end = dayjs(endDateTime);
    return end.diff(start, "hour", true);
};

export const calculateBaseWage = (
    price_per_hour: number,
    totalHours: number,
    numberOfPeople: number,
) => {
    const wage = Math.floor(price_per_hour * totalHours);
    const outcome = wage * numberOfPeople;

    return outcome;
};

export const calculateFeeCost = (
    fee: number,
    totalHours: number,
    numberOfPeople: number,
) => {
    const calc = Math.floor(fee * 1.2 * totalHours);
    const outcome = calc * numberOfPeople;
    return outcome;
};

export const calculateTotalCost = (
    price_per_hour: number,
    fee: number,
    startDateTime: dayjs.Dayjs,
    endDateTime: dayjs.Dayjs,
    numberOfPeople: number,
) => {
    const start = dayjs(startDateTime);
    const end = dayjs(endDateTime);
    const totalHours = calculateTotalHours(start, end); // Calculate hours once
    const baseCost = calculateBaseWage(
        price_per_hour,
        totalHours,
        numberOfPeople,
    );
    const feeCost = calculateFeeCost(fee, totalHours, numberOfPeople);
    return Math.floor(baseCost + feeCost);
};

export const roundToNearest5Minutes = (dateTime: dayjs.Dayjs) => {
    const minutes = dateTime.minute();
    const roundedMinutes = Math.ceil(minutes / 5) * 5; // Round up to nearest multiple of 5
    return dateTime.minute(roundedMinutes).second(0); // Set seconds to 00
};
