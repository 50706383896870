import React, { useState } from "react";
import { TextField, Select, MenuItem, InputAdornment } from "@mui/material";
import grid from "../../../../../styles/partials/_grid.module.scss";
const PricePeoplePage: React.FC<{
    formData: any;
    updateFormData: (field: string, value: any) => void;
}> = ({ formData, updateFormData }) => {
    const [error, setError] = useState("");

    const handlePriceChange = (e: any) => {
        const value = parseFloat(e.target.value);
        if (value < 13.15) {
            setError("Wage must be at least £13.15p/h");
        } else {
            setError("");
            updateFormData("price_per_hour", value);
        }
    };

    return (
        <div className={grid.row}>
            <TextField
                label="Price Per Hour"
                type="number"
                value={formData.price_per_hour}
                onChange={handlePriceChange}
                sx={{ mb: 2 }}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">£</InputAdornment>
                    ),
                }}
                error={!!error}
                helperText={error}
                fullWidth
            />
            <Select
                id="number_of_people"
                value={formData.number_people}
                sx={{ mb: 2 }}
                onChange={(e) =>
                    updateFormData("number_people", parseInt(e.target.value))
                }
                fullWidth
                margin="normal"
            >
                <MenuItem value={1}>1 Person</MenuItem>
                <MenuItem value={2}>2 People</MenuItem>
                <MenuItem value={3}>3 People</MenuItem>
                <MenuItem value={4}>4 People</MenuItem>
                <MenuItem value={5}>5 People</MenuItem>
            </Select>
        </div>
    );
};

export default PricePeoplePage;
