/* eslint-disable unused-imports/no-unused-vars */
import React, { useState, useEffect } from "react";
import { Modal as MUIModal, Box, TextField, Alert } from "@mui/material";
import dayjs from "dayjs";
import style from "./ShiftCreationModal.module.scss";
import grid from "../../../../styles/partials/_grid.module.scss";
import BreakdownPage from "./Pages/BreakdownPage";
import EndDateTimePage from "./Pages/EndDatetimePage";
import StartDateTimePage from "./Pages/StartDatetimePage";
import PricePeoplePage from "./Pages/PricePeoplePage";
import Button from "../../Button";

interface ModalProps {
    open: boolean;
    onClose: () => void;
    onSave: (formData) => any;
    onDelete?: (index: number) => any; // Add onDelete prop for deleting shift
    shift?: any; // Existing shift data for editing
    fee: number;
    index?: number; // Index if editing
}

const ShiftCreationModal: React.FC<ModalProps> = ({
    open,
    onClose,
    onSave,
    onDelete,
    shift,
    fee,
    index,
}) => {
    const [error, setError] = useState("");

    const [formData, setFormData] = useState({
        price_per_hour: 13.15,
        number_people: 1,
        start_time: null,
        end_time: null,
    });

    // If editing, initialize form data with the shift data
    useEffect(() => {
        if (shift) {
            setFormData({
                price_per_hour: shift.price_per_hour || 13.15,
                number_people: shift.number_people || 1,
                start_time: dayjs(shift.start_time),
                end_time: dayjs(shift.end_time),
            });
        }
    }, [shift]);

    const handleNext = () => setError(""); // Move to next step if needed
    const handleBack = () => setError("");

    const updateFormData = (field: string, value: any) => {
        setFormData({ ...formData, [field]: value });
    };

    return (
        <MUIModal open={open} onClose={onClose}>
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    p: 2.5,
                    width: "100%",
                    borderRadius: "5px",
                    maxWidth: "550px",
                }}
            >
                <h4 style={{ marginBottom: "24px" }}>
                    {index !== undefined
                        ? "Edit Shift: " + index
                        : "Create Shift"}
                </h4>

                <PricePeoplePage
                    formData={formData}
                    updateFormData={updateFormData}
                />
                <div className={grid.row} style={{ marginBottom: 0 }}>
                    <StartDateTimePage
                        formData={formData}
                        updateFormData={updateFormData}
                        setError={setError}
                    />
                    <EndDateTimePage
                        formData={formData}
                        updateFormData={updateFormData}
                        setError={setError}
                    />
                </div>

                {error ? (
                    <Alert style={{ marginBottom: "16px" }} severity="warning">
                        {error}
                    </Alert>
                ) : formData.start_time !== null &&
                  formData.end_time !== null ? (
                    <BreakdownPage formData={formData} fee={fee} />
                ) : (
                    ""
                )}

                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        mt: 2,
                    }}
                >
                    <Button onClick={onClose}>Cancel</Button>
                    <div>
                        {index !== undefined && (
                            <Button
                                type="error"
                                onClick={() => {
                                    if (onDelete) {
                                        onDelete(index); // Call onDelete with the shift index
                                        onClose(); // Close the modal
                                    }
                                }}
                            >
                                Delete
                            </Button>
                        )}
                        <Button
                            type="green"
                            style={{ marginRight: 0 }}
                            onClick={() => {
                                onSave(formData); // Save the shift data
                                onClose(); // Close the modal
                            }}
                            disabled={
                                error ||
                                formData.start_time == null ||
                                formData.end_time == null
                            }
                        >
                            {index !== undefined
                                ? "Save Changes"
                                : "Save Shift"}
                        </Button>
                    </div>
                </Box>
            </Box>
        </MUIModal>
    );
};

export default ShiftCreationModal;
