import Blob from "../../components/common/Blob";
import SlingerContainer from "../SlingerContainer";
import grid from "../../styles/partials/_grid.module.scss";
import Typography from "../../components/Typography";
// Load Container with all the relevant pieces for the page component to load
interface GeneralProps {
    type: any;
}

export default function GeneralMessageContainer(props: GeneralProps) {
    if (props.type == "rtw") {
        return (
            <SlingerContainer type="registration">
                <div className={grid.mobile_size}>
                    <Blob title="Thanks for completing your Right to Work">
                        <Typography variant="small" className={["mt-8"]}>
                            Now that's all done, we need to wait for
                            confirmation from our 3rd Party. You'll be notified
                            in the app of any updates.
                        </Typography>

                        <Typography variant="small" className={["gutter"]}>
                            You can close this window and go back to the app now
                        </Typography>
                    </Blob>
                </div>
            </SlingerContainer>
        );
    } else if (props.type == "stripe") {
        return (
            <SlingerContainer type="registration">
                <div className={grid.mobile_size}>
                    <Blob title="Thanks for completing your bank details">
                        <Typography variant="small" className={["mt-8"]}>
                            Now that's all done, you can get paid! We'll notify
                            you in-app if we need any more info - for now you're
                            all good to go.
                        </Typography>

                        <Typography variant="small" className={["gutter"]}>
                            You can close this window and go back to the app now
                        </Typography>
                    </Blob>
                </div>
            </SlingerContainer>
        );
    } else if (props.type == "reference.approved") {
        return (
            <SlingerContainer type="registration">
                <div className={grid.mobile_size}>
                    <Blob title="Thanks for approving this reference">
                        <Typography variant="small" className={["mt-8"]}>
                            This will boost the slinger's rating in that role
                            and improve the quality of work they're able to get.
                        </Typography>

                        <Typography variant="small" className={["gutter"]}>
                            You can close this window.
                        </Typography>
                    </Blob>
                </div>
            </SlingerContainer>
        );
    } else if (props.type == "reference.denied") {
        return (
            <SlingerContainer type="registration">
                <div className={grid.mobile_size}>
                    <Blob title="Thanks for letting us know this reference wasn't quite right.">
                        <Typography variant="small" className={["mt-8"]}>
                            We're working on improving the quality of our
                            slinger's and this is the first step.
                        </Typography>

                        <Typography variant="small" className={["gutter"]}>
                            You can close this window.
                        </Typography>
                    </Blob>
                </div>
            </SlingerContainer>
        );
    } else {
        return (
            <SlingerContainer type="registration">
                <div className={grid.mobile_size}>
                    <Blob title="Thanks">
                        <Typography variant="small" className={["mt-8"]}>
                            You can go back to the app now, you'll be sent
                            notifications of any updates.
                        </Typography>
                        <Typography variant="small" className={["gutter"]}>
                            You can close this window and go back to the app now
                        </Typography>
                    </Blob>
                </div>
            </SlingerContainer>
        );
    }
}
