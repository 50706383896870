import { Alert } from "@mui/material";
import Avatar from "../../Avatar";
import style from "./JobOfferModal.module.scss";
import QRCode from "react-qr-code";

import React, { useState } from "react";
import { Modal as MUIModal, Box, Typography, Button } from "@mui/material";
import JobDescriptionAPI from "../../../../api/jobDescriptions.service";
interface ModalProps {
    open: boolean;
    onClose: () => void;
    imageUrl: string;
    site: {};
    slinger: {};
    job_uuid: string;
    shift_uuid: string;
}

const JobOfferModal: React.FC<ModalProps> = ({
    open,
    onClose,
    imageUrl,
    site,
    slinger,
    job_uuid,
    shift_uuid,
}) => {
    const offerJob = () => {
        setIsLoading(true);

        JobDescriptionAPI.offerJob({ shift_uuid: shift_uuid })
            .then(() => {
                setIsLoading(false);
                window.location.reload();
            })
            .catch((response) => {
                console.log(response);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };
    const rejectCandidate = () => {
        setIsLoading(true);

        JobDescriptionAPI.rejectCandidate({ shift_uuid: shift_uuid })
            .then(() => {
                setIsLoading(false);
                window.location.reload();
            })
            .catch((response) => {
                console.log(response);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };
    const [isLoading, setIsLoading] = useState(false);
    console.log(site);
    console.log(job_uuid);
    return (
        <MUIModal open={open} onClose={onClose}>
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",

                    bgcolor: "background.paper",
                    boxShadow: 24,
                    borderRadius: "5px",
                    overflow: "hidden",
                    p: 1,
                }}
            >
                <div className={style.jobOfferModal}>
                    <div className={style.title}>
                        <div>
                            <h3>How did {slinger?.first_name} do?</h3>
                            <small>
                                You can offer them the role, or let them down
                                easy
                            </small>
                        </div>
                        <span className={style.close} onClick={onClose}>
                            <svg
                                width="11"
                                height="11"
                                viewBox="0 0 11 11"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M1 1L5.5 5.5M5.5 5.5L10 1M5.5 5.5L1 10M5.5 5.5L10 10"
                                    stroke="black"
                                />
                            </svg>
                        </span>
                    </div>
                    <div className={style.pickaside}>
                        <div className={style.offer}>
                            <h3>Offer {slinger?.first_name} a job?</h3>

                            <small>
                                We'll alert the slinger by email to let them
                                know they've been offered the job. It's then a
                                waiting game for their response.
                            </small>
                            <button onClick={offerJob} disabled={isLoading}>
                                Send Job Offer
                            </button>
                        </div>
                        <div className={style.reject}>
                            <h3>Reject {slinger?.first_name}</h3>

                            <small>
                                The slinger will be alerted that you're moving
                                forward with another candidate and the shift
                                will be archived.
                            </small>
                            <button
                                onClick={rejectCandidate}
                                disabled={isLoading}
                            >
                                Send Rejection
                            </button>
                        </div>
                    </div>
                    <Alert severity={"warning"} style={{ marginTop: "16px" }}>
                        All job offers are still subject to a slinger
                        accepting/rejecting. We will keep you updated with any
                        responses via email.
                    </Alert>
                    <div className={style.data}>
                        <div className={style.profile}>
                            <Avatar size="large" image={imageUrl} />
                        </div>
                        <div className={style.qr}>
                            <QRCode
                                size={"100%"}
                                value={"test"}
                                fgColor="#000"
                            />
                        </div>
                    </div>
                </div>
            </Box>
        </MUIModal>
    );
};

export default JobOfferModal;
