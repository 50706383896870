import { useMenu } from "../../../hooks/menuContext";
import Button from "../Button";
import style from "./Header.module.scss";
import NotificationBell from "./NotificationBell";
import ProfileMenuButton from "./ProfileMenuButton";
import SearchMenuButton from "./SearchMenuButton";

interface HeaderOptionsPropsInterface {
    title: string;
}
Header.defaultProps = {
    title: "",
};
function Header(props: HeaderOptionsPropsInterface) {
    const { toggleMenu, isMenuOpen } = useMenu();
    return (
        <header className={style.header}>
            <span
                className={[
                    style.mobile_menu,
                    isMenuOpen ? style.open : style.closed,
                ].join(" ")}
                onClick={function () {
                    toggleMenu();
                }}
            >
                <i></i>
            </span>
            <h3 style={{ marginRight: "auto" }}>{props.title}</h3>
            <div className={style.menus}>
                <div className={style.peripheral}>
                    {/* <SearchMenuButton /> */}
                    {/* <Button type="hollow" style={{ marginRight: "16px" }}>
                        Create Shift
                    </Button> */}
                    <NotificationBell />
                </div>
                <ProfileMenuButton />
            </div>
        </header>
    );
}

export default Header;
