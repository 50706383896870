/* eslint-disable unused-imports/no-unused-vars */
import { useState } from "react";
import grid from "../../styles/partials/_grid.module.scss";
import Blob from "../../components/common/Blob";
import { Alert, FormControl, TextField } from "@mui/material";
import Typography from "../../components/Typography";
import LSS from "../../helpers/localStorageService";
import AuthAPI from "../../api/auth.client";
import Button from "../../components/common/Button";
import AvatarUpload from "../../components/common/AvatarUpload/AvatarUpload";
import UserAPI from "../../api/user/user.service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
const labelProps = {
    shrink: true,
};
// Load Container with all the relevant pieces for the page component to load
export default function UpdatePictureForm() {
    const [isError, setIsError] = useState("");
    const [isSuccess, setIsSuccess] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const [selectedImage, setSelectedImage] = useState(
        LSS().get("profile_picture"),
    );
    const handleChange = (data, key) => {
        setIsError("");
        setIsSuccess("");
        if (key == "file") {
            setSelectedImage(data);
        }
    };

    const uploadPicture = () => {
        setIsLoading(true);
        setIsError("");
        setIsSuccess("");
        UserAPI.updatePicture({
            file: selectedImage,
        })
            .then((response) => {
                setIsSuccess("Profile picture uploaded succesfully");
                LSS().set("profile_picture", response.file);
                setIsLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setIsError(err.message);
                setIsLoading(false);
            });
    };
    return (
        <div className={[grid.row, grid.centre].join(" ")}>
            <Blob title="Update your profile picture" size="half">
                <Typography variant="small" className={["mb-16", "mt-8"]}>
                    Please select a profile photo for your account.
                </Typography>
                {isError ? (
                    <Alert severity="error" style={{ marginBottom: "16px" }}>
                        {isError}
                    </Alert>
                ) : (
                    ""
                )}
                {isSuccess ? (
                    <Alert severity="success" style={{ marginBottom: "16px" }}>
                        {isSuccess}
                    </Alert>
                ) : (
                    ""
                )}

                <FormControl fullWidth sx={{ mb: 4 }}>
                    <div style={{ width: "200px", margin: "0 auto" }}>
                        <AvatarUpload
                            name="file"
                            text="Select New Image"
                            id="file"
                            onChange={handleChange}
                            default={selectedImage}
                        />
                    </div>
                </FormControl>

                <div className={[grid.row, grid.centre].join(" ")}>
                    <Button type="green">Save Profile Photo</Button>
                </div>
                <a href="/profile" style={{ textAlign: "center" }}>
                    <Typography variant="small">Back to account</Typography>
                </a>
            </Blob>
        </div>
    );
}
