import ApiService from "../api.service";

class BusinessAPI {
    // Make sure that ApiService is correctly instantiated.
    private apiService: ApiService;

    constructor() {
        this.apiService = new ApiService();
    }
    async registerBusiness(business_data): Promise<Object> {
        if (business_data.address_line2 == null) {
            business_data.address_line2 = "";
        }
        try {
            const responseData = await this.apiService.upload<Object>(
                "user/business/create-profile",
                business_data,
            );
            return responseData;
        } catch (error) {
            console.error("Error in post request:", error);
            throw error;
        }
    }

    async InvitePayload(business_id): Promise<Object> {
        const body = { business_id: business_id };
        try {
            const responseData = await this.apiService.post<Object>(
                "user/business/invite-info",
                body,
            );
            return responseData;
        } catch (error) {
            console.error("Error in post request:", error);
            throw error;
        }
    }
    async sendInvite(body): Promise<Object> {
        try {
            const responseData = await this.apiService.post<Object>(
                "user/business/invite-member",
                body,
            );
            return responseData;
        } catch (error) {
            console.error("Error in post request:", error);
            throw error;
        }
    }

    async updateLogo(image): Promise<Object> {
        try {
            const responseData = await this.apiService.upload<Object>(
                "user/business/update-photo",
                image,
            );
            return responseData;
        } catch (error) {
            console.error("Error in post request:", error);
            throw error;
        }
    }

    async getBusinessInfo(): Promise<Object> {
        try {
            const responseData = await this.apiService.get<Object>(
                "user/business/get-information",
                null,
            );
            return responseData;
        } catch (error) {
            console.error("Error in post request:", error);
            throw error;
        }
    }
}

export default BusinessAPI;
