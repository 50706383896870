import style from "./ProfileMenuButton.module.scss";
import ProfileMenu from "../ProfileMenu";
import { useState } from "react";

import LSS from "../../../../helpers/localStorageService";
import getImageURL from "../../../../helpers/imageUtils";

function ProfileMenuButton() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const openMenu = () => {
        if (isMenuOpen) {
            setIsMenuOpen(false);
        } else {
            setIsMenuOpen(true);
        }
    };
    const image =
        LSS().get("profile_picture") !== null
            ? getImageURL(LSS().get("profile_picture"))
            : "https://as2.ftcdn.net/v2/jpg/00/64/67/63/1000_F_64676383_LdbmhiNM6Ypzb3FM4PPuFP9rHe7ri8Ju.jpg";
    return (
        <div className={style.profileMenu} onClick={openMenu}>
            <span style={{ backgroundImage: "url('" + image + "')" }}></span>
            <i>{LSS().get("first_name") + " " + LSS().get("last_name")}</i>
            <ProfileMenu isOpen={isMenuOpen} />
        </div>
    );
}

export default ProfileMenuButton;
