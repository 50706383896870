import React, { CSSProperties, PropsWithChildren } from "react";
import style from "./Button.module.scss";

interface ButtonProps {
    size?: "micro" | "small" | "medium";
    type?:
        | "primary"
        | "hollow"
        | "secondary"
        | "micro"
        | "error"
        | "green"
        | "archive";
    disabled?: boolean;
    className?: string;
    onClick?: () => void;
    target?: "_blank" | "_self" | "_parent" | "_top";
    href?: string;
    style?: CSSProperties;
    download?: boolean;
    form?: string;
}

const Button: React.FC<PropsWithChildren<ButtonProps>> = ({
    children,
    size = "medium",
    type = "primary",
    disabled = false,
    className = "",
    onClick,
    target = "_parent",
    href,
    style: customStyle = {},
    download = false,
    form = "",
}) => {
    const classes = [style.button, style[type], style[size], className]
        .filter(Boolean)
        .join(" ");

    if (href) {
        return (
            <a
                href={href}
                className={classes}
                style={customStyle}
                target={target}
                download={download}
            >
                {children}
            </a>
        );
    }

    return (
        <button
            className={classes}
            style={customStyle}
            disabled={disabled}
            onClick={onClick}
            form={form}
        >
            {children}
        </button>
    );
};

export default Button;
