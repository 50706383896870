let instance: LocalStorageServiceInterface | null = null;

export interface LocalStorageServiceInterface {
    get(key: string): any;
    set(key: string, value: any): any;
    remove(key: string): any;
}

class LocalStorageService implements LocalStorageServiceInterface {
    private readonly storage: Storage | undefined;

    constructor(storage: Storage | undefined) {
        this.storage = storage;
    }

    public get(key: string): any {
        let response = null;
        if (!this.storage) {
            return response;
        }
        try {
            const item = this.storage.getItem(key);
            if (item) {
                response = JSON.parse(item);
            }
        } catch (error) {
            /* empty */
        }
        return response;
    }

    public set(key: string, value: any): any {
        try {
            if (typeof this.storage !== "undefined") {
                this.storage.setItem(key, JSON.stringify(value));
            }
        } catch (error) {
            /* empty */
        }
    }

    public remove(key: string): any {
        try {
            if (this.storage) {
                this.storage.removeItem(key);
            }
        } catch (error) {
            /* empty */
        }
    }
}

export default function getInstance(): LocalStorageServiceInterface {
    if (instance === null) {
        instance = new LocalStorageService(window.localStorage);
    }

    return instance;
}
