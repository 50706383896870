/* eslint-disable unused-imports/no-unused-vars */
import { useEffect, useState } from "react";
import SubscriptionBreakdown from "../../components/SubscriptionBreakdown";
import Typography from "../../components/Typography";
import Blob from "../../components/common/Blob";
import grid from "../../styles/partials/_grid.module.scss";
import SlingerContainer from "../SlingerContainer";
import SubscriptionAPI from "../../api/subscription.service";
import { useParams } from "react-router";
import Loading from "../../components/common/Loading";

export default function SubscriptionContainer() {
    const { subscription_uuid } = useParams() as { subscription_uuid: string };
    const [isLoading, setIsLoading] = useState(true);
    const [stats, setStats] = useState([]);
    const [inactive, setInactive] = useState([]);
    const [upcoming, setUpcoming] = useState([]);
    document.title = "Slinger Business | Subscriptions";
    useEffect(() => {
        SubscriptionAPI.getBreakdown().then((response) => {
            setStats(response.stats);
            setUpcoming(response.upcoming);
            setInactive(response.inactive);
            setIsLoading(false);
        });
    }, []);

    if (isLoading) {
        return (
            <SlingerContainer title="Subscriptions">
                <Loading
                    type="internal"
                    title="Fetching your subscription info..."
                />
            </SlingerContainer>
        );
    } else {
        return (
            <SlingerContainer title="Subscriptions">
                <div className={grid.row}>
                    <div className={grid.twoThird}>
                        <Typography variant="h1">All Subscriptions</Typography>
                        <Typography variant="p">
                            This is a breakdown of your current and previous
                            subscriptions. Click into a subscription to see the
                            previous invoices.
                        </Typography>
                        <Typography variant="small" className={["mb-8"]}>
                            <span
                                style={{
                                    width: "100%",
                                    display: "inline-block",
                                }}
                            >
                                * payment amounts are finalised up to midnight
                                of the period. Any shifts completed and
                                confirmed will still be added to this bill
                            </span>
                        </Typography>
                    </div>
                    <div className={grid.third}></div>
                </div>

                <div className={grid.row}>
                    <div className={grid.full}>
                        {Object.keys(upcoming).length ? (
                            <SubscriptionBreakdown
                                subscriptions={upcoming}
                                past={false}
                            />
                        ) : (
                            <Blob
                                size="full"
                                title=" Active Subscriptions"
                                style={{ opacity: 0.5 }}
                            >
                                No upcoming subscriptions
                            </Blob>
                        )}

                        <Typography variant="h2" className={["mb-16", "mt-32"]}>
                            Past Subscriptions
                        </Typography>

                        {Object.keys(inactive).length ? (
                            <SubscriptionBreakdown
                                subscriptions={inactive}
                                past={true}
                            />
                        ) : (
                            <Blob size="full" style={{ opacity: 0.5 }}>
                                No past subscriptions
                            </Blob>
                        )}
                    </div>
                </div>
            </SlingerContainer>
        );
    }
}
