import { TextField, debounce } from "@mui/material";
import Blob from "../../../components/common/Blob";
import grid from "../../../styles/partials/_grid.module.scss";
import Typography from "../../../components/Typography";
import { useCallback, useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { jobCreationState } from "../../../state/atoms/jobCreationAtom";

function JEDescription() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
    const [description, setDescription] = useState("");
    const [jobData, setJobData] = useRecoilState(jobCreationState);
    const [charCount, setCharCount] = useState(0);
    const handleChange = (value: string) => {
        setDescription(value);
    };

    useEffect(() => {
        if (jobData.description) {
            setDescription(jobData.description);
        }
    }, [jobData.description]);

    const debouncedUpdateRecoilState = useCallback(
        debounce((updateDescription) => {
            setCharCount(updateDescription.length);
            setJobData((prevJobData) => ({
                ...prevJobData,
                description: updateDescription,
            }));
        }, 500),
        [setJobData],
    );

    useEffect(() => {
        debouncedUpdateRecoilState(description);
    }, [description, debouncedUpdateRecoilState]);

    return (
        <Blob title="Job Description" size="full" padded_inner={false}>
            <div className={grid.form_inner}>
                <div className={grid.form_header}>
                    <Typography variant="small">
                        Tell the candidate what it is they'll be doing and why.
                    </Typography>
                </div>
                <label>Job Description:</label>
                <TextField
                    multiline={true}
                    fullWidth={true}
                    minRows={4}
                    defaultValue={description}
                    onChange={(e) => handleChange(e.target.value)}
                    inputProps={{ maxLength: 1000 }}
                    placeholder="Solid description about this job and what it will involve"
                />
                <small
                    style={{
                        width: "100%",
                        textAlign: "right",
                        display: "inline-block",
                        padding: "8px 0 16px",
                        color: "grey",
                    }}
                >
                    Minimum 150 characters ({charCount}/1000)
                </small>
            </div>
        </Blob>
    );
}

export default JEDescription;
