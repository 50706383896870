import { Alert } from "@mui/material";
import Avatar from "../../Avatar";
import style from "./QRModal.module.scss";
import QRCode from "react-qr-code";

import React from "react";
import { Modal as MUIModal, Box, Typography, Button } from "@mui/material";

interface ModalProps {
    open: boolean;
    onClose: () => void;
    imageUrl: string;
    text: string;
}

const QRModal: React.FC<ModalProps> = ({ open, onClose, imageUrl, text }) => {
    return (
        <MUIModal open={open} onClose={onClose}>
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",

                    bgcolor: "background.paper",
                    boxShadow: 24,
                    borderRadius: "5px",
                    overflow: "hidden",
                    p: 1,
                }}
            >
                <div className={style.qrCode}>
                    <div className={style.title}>
                        <div>
                            <h3>Scan to Check in</h3>
                            <small>
                                Show this to a slinger when they arrive on site{" "}
                            </small>
                        </div>
                        <span className={style.close} onClick={onClose}>
                            <svg
                                width="11"
                                height="11"
                                viewBox="0 0 11 11"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M1 1L5.5 5.5M5.5 5.5L10 1M5.5 5.5L1 10M5.5 5.5L10 10"
                                    stroke="black"
                                />
                            </svg>
                        </span>
                    </div>
                    <div className={style.data}>
                        <div className={style.profile}>
                            <Avatar size="large" image={imageUrl} />
                        </div>
                        <div className={style.qr}>
                            <QRCode size={"100%"} value={text} fgColor="#000" />
                        </div>
                    </div>

                    <div className={style.warning}>
                        <Alert
                            severity={"warning"}
                            style={{ marginTop: "16px" }}
                        >
                            <small>
                                Please make sure the person in the photo is the
                                same as the person clocking-in.
                            </small>
                        </Alert>
                    </div>
                </div>
            </Box>
        </MUIModal>
    );
};

export default QRModal;
