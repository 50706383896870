import { useEffect } from "react";
import Hotjar from "@hotjar/browser";
import LSS from "../helpers/localStorageService";
const HotjarScript = () => {
    useEffect(() => {
        if (process.env.REACT_APP_ENV === "production") {
            const siteId = 3883879;
            const hotjarVersion = 6;
            const userId = LSS().get("user_id") || null;

            Hotjar.init(siteId, hotjarVersion);

            Hotjar.identify(userId, { business: LSS().get("business_name") });
        }
    }, []);

    return null;
};

export default HotjarScript;
