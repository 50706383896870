import style from "./Submenu.module.scss";
import { useEffect } from "react";
interface SubmenuPropsInterface {
    isOpen: boolean;
    type: string;
    children: any;
}
function Submenu(props: SubmenuPropsInterface) {
    const classes = [style.submenu];
    if (props.isOpen) {
        classes.push(style.open);
    }

    if (props.type == "freeform") {
        classes.push(style.freeform);
    }
    return <div className={classes.join(" ")}>{props.children}</div>;
}

export default Submenu;
