import React, { PropsWithChildren } from "react";
import style from "./Typography.module.scss";
interface TypographyPropsInterface {
    className: string[];
    variant: string;
    children: any;
}

export default function Typography(
    props: PropsWithChildren<TypographyPropsInterface>,
) {
    // Define CSS classes based on the variant prop
    let generatedClassNames = "";
    if (props.className && props.className.length > 0) {
        generatedClassNames = props.className
            .map((className) => style[className])
            .join(" ");
    }

    switch (props.variant) {
        case "p":
            return <p className={generatedClassNames}>{props.children}</p>;

        case "h1":
            return <h1 className={generatedClassNames}>{props.children}</h1>;

        case "h2":
            return <h2 className={generatedClassNames}>{props.children}</h2>;

        case "h3":
            return <h3 className={generatedClassNames}>{props.children}</h3>;
        case "h4":
            return <h4 className={generatedClassNames}>{props.children}</h4>;

        case "small":
            return (
                <small className={generatedClassNames}>{props.children}</small>
            );
        case "subtitle":
            return (
                <h3 className={[style.subtitle, generatedClassNames].join(" ")}>
                    {props.children}
                </h3>
            );
        default:
            return null;
    }
}
Typography.defaultProps = { className: [] };
