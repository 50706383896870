import React, { useState } from "react";
import { Alert, TextField } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import { roundToNearest5Minutes } from "../../../../../helpers/shiftHelpers";

const StartDateTimePage: React.FC<{
    formData: any;
    updateFormData: (field: string, value: any) => void;
    setError: (value: any) => void;
}> = ({ formData, updateFormData, setError }) => {
    const handleStartDateChange = (newValue: any) => {
        if (dayjs(newValue).isValid()) {
            const selectedDate = roundToNearest5Minutes(
                dayjs(newValue).set("seconds", 0),
            );

            if (selectedDate.isBefore(dayjs())) {
                setError("Start date cannot be before today");
            } else if (
                formData.end_time !== null &&
                formData.end_time.diff(selectedDate, "hour", true) < 4
            ) {
                setError("Shifts need to be a minimum of 4 hours");
                updateFormData("start_time", selectedDate);
            } else {
                setError("");
                updateFormData("start_time", selectedDate);
            }
        }
    };

    return (
        <>
            <DateTimePicker
                label="Start Date and Time"
                value={formData.start_time}
                onChange={handleStartDateChange}
                slotProps={{
                    popper: {
                        placement: "right",
                        sx: {
                            ".MuiPaper-root": {
                                border: "1px solid #3490dc",
                                borderRadius: "5px",
                                ml: -20,
                            },
                        },
                        disablePortal: true,
                    },
                }}
                sx={{ width: "100%", mb: 2 }}
                format="DD/MM/YYYY - HH:mm"
                ampm={false}
                minutesStep={5} // Ensures only 5-minute intervals can be selected
                minDateTime={dayjs().add(2, "hours")} // Ensure start time is at least 2 hours in the future
            />
        </>
    );
};

export default StartDateTimePage;
