import { Alert } from "@mui/material";
import Avatar from "../../Avatar";
import style from "./ArchiveJobModal.module.scss";
import QRCode from "react-qr-code";

import React, { useState } from "react";
import { Modal as MUIModal, Box, Typography } from "@mui/material";
import Button from "../../Button";
import JobDescriptionAPI from "../../../../api/jobDescriptions.service";

interface ModalProps {
    open: boolean;
    onClose: () => void;
    job_uuid: string;
}

const ArchiveJobModal: React.FC<ModalProps> = ({ open, onClose, job_uuid }) => {
    const [isLoading, setIsLoading] = useState(false);
    const closeJob = () => {
        setIsLoading(true);
        JobDescriptionAPI.closeJob(job_uuid)
            .then(() => {
                window.location.reload();
            })
            .finally(() => {
                setIsLoading(false);
            });
    };
    return (
        <MUIModal open={open} onClose={onClose}>
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",

                    bgcolor: "background.paper",
                    boxShadow: 24,
                    borderRadius: "5px",
                    overflow: "hidden",
                    p: 1,
                }}
            >
                <div className={style.archive} style={{ maxWidth: "500px" }}>
                    <h3>Close this job?</h3>
                    <p>
                        This will remove it from your active lists and move it
                        to the 'closed' tab. You can create a new job
                        description at any time.
                    </p>

                    <div className={style.cancel}>
                        <Button
                            type={"error"}
                            onClick={onClose}
                            disabled={isLoading}
                        >
                            Cancel
                        </Button>
                        <Button
                            style={{ float: "Right" }}
                            type={"primary"}
                            disabled={isLoading}
                            onClick={closeJob}
                        >
                            Close This Job
                        </Button>
                    </div>
                </div>
            </Box>
        </MUIModal>
    );
};

export default ArchiveJobModal;
