/* eslint-disable unused-imports/no-unused-vars */
import { useEffect, useState } from "react";
import SlingerContainer from "../SlingerContainer";
import Loading from "../../components/common/Loading";
import ErrorPage from "../../pages/Error";
import grid from "../../styles/partials/_grid.module.scss";
import style from "./ShiftInvoiceContainer.module.scss";
import table from "../../styles/_table.module.scss";
import {
    Checkbox,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent,
} from "@mui/material";
import Blob from "../../components/common/Blob";
import Button from "../../components/common/Button";
import { GridFilterListIcon } from "@mui/x-data-grid";
import getAllRoles from "../../api/roles/getAllRoles";
import MultiSelect from "../../components/common/MultiSelect";
import getBusinessSites from "../../api/sites/getBusinessSites";
import ShiftsAPI from "../../api/shifts/shifts.client";
import dayjs from "dayjs";
import Pill from "../../components/common/Pill";
import Avatar from "../../components/common/Avatar";
import getImageURL from "../../helpers/imageUtils";
import { priceFromPennies } from "../../helpers/general";

// Load Container with all the relevant pieces for the page component to load
export default function ShiftInvoiceContainer() {
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [roles, setRoles] = useState([]);
    const [sites, setSites] = useState([]);
    const [selectedRoles, setSelectedRoles] = useState([]);
    const [selectedSites, setSelectedSites] = useState([]);
    const [list, setList] = useState([]);

    const [startDate, setStartDate] = useState(
        dayjs().startOf("month").format("YYYY-MM-DD"),
    );
    const [endDate, setEndDate] = useState(
        dayjs().endOf("month").format("YYYY-MM-DD"),
    );

    const getCore = async () => {
        // Get single shift
        await getAllRoles().then((response) => {
            setRoles(response.jobRoles);
        });

        await getBusinessSites().then((response) => {
            setSites(response.sites);
        });
        renderList();
        setIsLoading(false);
    };

    const shiftAPI = new ShiftsAPI();
    const renderList = () => {
        setIsLoading(true);
        shiftAPI
            .getHistory(startDate, endDate, selectedRoles, selectedSites, 1)
            .then((response) => {
                setList(response);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    // Handlers
    const handleRoleChange = (newSelectedIds) => {
        setSelectedRoles(newSelectedIds);
    };
    const handleSiteChange = (newSelectedIds) => {
        setSelectedSites(newSelectedIds);
    };

    const load = async () => {
        try {
            setIsError(false);
            await getCore();
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
            setIsError(true);
        }
    };

    useEffect(() => {
        document.title = "Slinger Business | Shift Invoices";
        load();
    }, []);

    if (isError) {
        return (
            <SlingerContainer title="" type="registration">
                <ErrorPage onErrorReload={load} />
            </SlingerContainer>
        );
    }

    return (
        <SlingerContainer title="Shift History">
            <Blob
                padded_inner={false}
                size="full"
                title="Shift History"
                subtitle="Breakdown the shifts in your account by the shift date, role & more."
                button={
                    <Button type="micro" onClick={renderList}>
                        <GridFilterListIcon />
                    </Button>
                }
            >
                <div className={`${grid.row} ${grid.toolbelt}`}>
                    <div className={grid.inputcontrol}>
                        <label>Start Date:</label>
                        <input
                            type="date"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                            className={style.datefilter}
                        />
                    </div>
                    <div className={grid.inputcontrol}>
                        <label>End Date:</label>
                        <input
                            type="date"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                            className={style.datefilter}
                        />
                    </div>
                    <MultiSelect
                        options={roles}
                        selectedOptions={selectedRoles}
                        onChange={handleRoleChange}
                        label="Roles"
                        placeholder="Select roles"
                    />
                    <MultiSelect
                        options={sites}
                        selectedOptions={selectedSites}
                        onChange={handleSiteChange}
                        label="Sites"
                        placeholder="Select sites"
                    />
                    <Button type="green" onClick={renderList}>
                        Filter
                    </Button>
                </div>
                {isLoading ? (
                    <Loading title="Fetching Shift List"></Loading>
                ) : (
                    <div style={{ padding: "0px 24px 24px" }}>
                        <table className={style.micro_list}>
                            <thead>
                                <tr>
                                    <th className={style.width_100}>
                                        Shift Date
                                    </th>

                                    <th>Slinger</th>
                                    <th className={style.width_100}>Role</th>
                                    <th className={style.width_100}>Status</th>
                                    <th className={style.width_150}>Paid</th>
                                    <th className={style.width_150}>Cost</th>
                                    <th
                                        className={`${style.width_50} ${style.actionable}`}
                                    >
                                        ...
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {list.map((item) => {
                                    return (
                                        <tr key={item.id}>
                                            <td className={style.width_100}>
                                                <span>
                                                    {dayjs(
                                                        item.start_time,
                                                    ).format("DD/MM/YYYY")}
                                                </span>
                                                <small>
                                                    {" "}
                                                    {dayjs(
                                                        item.start_time,
                                                    ).format("HH:mm")}
                                                    {" - "}
                                                    {dayjs(
                                                        item.end_time,
                                                    ).format("HH:mm")}
                                                </small>
                                            </td>
                                            <td>
                                                <div className={style.aligned}>
                                                    {item.has_slinger ? (
                                                        <>
                                                            <Avatar
                                                                size="tiny"
                                                                image={getImageURL(
                                                                    item.slinger
                                                                        .profile_picture,
                                                                )}
                                                            ></Avatar>
                                                            <span>
                                                                {
                                                                    item.slinger
                                                                        .first_name
                                                                }{" "}
                                                                {
                                                                    item.slinger
                                                                        .last_name
                                                                }
                                                                <small>
                                                                    {
                                                                        item
                                                                            .site
                                                                            .name
                                                                    }
                                                                </small>
                                                            </span>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <Avatar size="tiny"></Avatar>
                                                            <span>
                                                                No Taker
                                                                <small>
                                                                    {
                                                                        item
                                                                            .site
                                                                            .name
                                                                    }
                                                                </small>
                                                            </span>
                                                        </>
                                                    )}
                                                </div>
                                            </td>
                                            <td className={style.width_100}>
                                                {item.role.name}
                                            </td>
                                            <td className={style.width_100}>
                                                <Pill
                                                    type="secondary"
                                                    label="Pending"
                                                />
                                            </td>

                                            <td className={style.width_150}>
                                                <Pill
                                                    type="secondary"
                                                    label="22/08/2024"
                                                />
                                            </td>
                                            <td className={style.width_150}>
                                                {priceFromPennies(
                                                    item.total_price,
                                                )}
                                            </td>
                                            <td
                                                className={`${style.width_50} ${style.actionable}`}
                                            >
                                                <a href="/">XXXX</a>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                )}
            </Blob>
        </SlingerContainer>
    );
}
