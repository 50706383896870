import style from "./Blob.module.scss";
import { CSSProperties, PropsWithChildren } from "react";
import grid from "../../../styles/partials/_grid.module.scss";
import Button from "../Button";
interface BlobPropsInterface {
    title: string;
    subtitle: string;
    size: string;
    padded_inner: boolean;
    button: any;
    style: CSSProperties;
}

Blob.defaultProps = {
    size: "normal",
    padded_inner: true,
    style: {},
    subtitle: "",
    title: "",
    button: null,
};
function Blob(props: PropsWithChildren<BlobPropsInterface>) {
    const classes = [style.blob];
    if (props.size == "full") {
        classes.push(style.full);
    }

    if (props.size == "quarter") {
        classes.push(style.quarter);
    }
    if (props.size == "threeQuarter") {
        classes.push(style.threeQuarter);
    }
    if (props.size == "half") {
        classes.push(style.half);
    }
    if (props.size == "third") {
        classes.push(style.third);
    }
    if (props.size == "twoThird") {
        classes.push(style.twoThird);
    }

    if (!props.padded_inner) {
        classes.push(style.flat);
    }
    return (
        <div className={classes.join(" ")} style={props.style}>
            <div className={style.blobInner}>
                {props.title !== "" && props.subtitle == "" ? (
                    <h5 className={style.blobTitle}>{props.title}</h5>
                ) : props.title !== "" && props.subtitle !== "" ? (
                    <div className={grid.blob_header}>
                        <div className={grid.content}>
                            <h3>{props.title}</h3>
                            <small>{props.subtitle}</small>
                        </div>
                        {props.button ? props.button : ""}
                    </div>
                ) : (
                    ""
                )}
                {props.children}
            </div>
        </div>
    );
}
export default Blob;
