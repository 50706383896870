import style from "./Pill.module.scss";
interface PillInterface {
    type: "primary" | "secondary" | "tertiary" | "hollow" | "small";
    label: string;
}

export default function Pill(props: PillInterface) {
    // Construct the class name dynamically. Ensure `props.type` is a valid class.
    const className = `${style.pill} ${style[props.type]}`;

    return <div className={className}>{props.label}</div>;
}
