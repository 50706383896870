import { PreloadedDataInterface } from "./preload";
import { setNamespace } from "../api";
import { setTranslations } from "./translations";

/**
 * Bootstrap the application with the provided data.
 */
export default function bootstrap(bootstrapData: PreloadedDataInterface) {
    setNamespace(bootstrapData.api_namespace);
    setTranslations(bootstrapData.translations);
}
