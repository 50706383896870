import { useEffect, useMemo, useState } from "react";

import Loading from "../../common/Loading";
import style from "./JDList.module.scss";
import table from "../../../styles/_table.module.scss";
import JDListItem from "./ListItem";
import dayjs from "dayjs";
import Typography from "../../Typography";
import Button from "../../common/Button";

interface JDListPropsInterface {
    jobs: [];
    loading: boolean;
    filter: string;
}

function JDList(props: JDListPropsInterface) {
    const [isLoading, setIsLoading] = useState(props.loading);
    const groupedShifts = useMemo(() => {
        const groups = new Map();
        props.jobs.forEach((job) => {
            const start_date = job.start_date;
            if (!groups.has(start_date)) {
                groups.set(start_date, []);
            }
            groups.get(start_date).push(job);
        });

        return Array.from(groups.entries());
    }, [props.jobs]);

    const load = () => {
        setIsLoading(false);
    };
    useEffect(() => {
        load();
    }, []);

    if (isLoading) {
        return <Loading />;
    }
    if (groupedShifts.length === 0) {
        return (
            <div className={style.shift_list}>
                <div style={{ textAlign: "center" }}>
                    {(() => {
                        switch (props.filter) {
                            case "all":
                                return (
                                    <>
                                        <Typography
                                            variant="small"
                                            className={["mb-32", "mt-16"]}
                                        >
                                            No active job descriptions - why not
                                            create one!
                                        </Typography>
                                    </>
                                );
                            default:
                                return (
                                    <>
                                        <Typography
                                            variant="small"
                                            className={["mb-32", "mt-32"]}
                                        >
                                            No job descriptions found for this
                                            filter
                                        </Typography>
                                    </>
                                );
                        }
                    })()}
                </div>
            </div>
        );
    }
    return (
        <div className={style.shift_list}>
            {groupedShifts.map(([, jobs]) => (
                <>
                    {jobs.map((job) => (
                        <>
                            <JDListItem key={job.uuid} job={job} />
                        </>
                    ))}
                </>
            ))}
        </div>
    );
}

JDList.defaultProps = {
    job: [],
};

export default JDList;
