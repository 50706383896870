import LoginValues from "../entities/values/LoginValues";
import ApiService from "./api.service";

class AuthAPI {
    private apiService = new ApiService();

    async attemptLogin(values: LoginValues): Promise<Object> {
        const responseData = await this.apiService.post<LoginValues>(
            "auth/login",
            values,
        );
        return responseData;
    }

    async requestEmailCode(): Promise<Object> {
        const responseData = await this.apiService.post<Object>(
            "user/request-email-validation",
            null,
        );
        return responseData;
    }

    async verifyEmail(code: string): Promise<Object> {
        const body = { code: code };
        const responseData = await this.apiService.post<Object>(
            "user/verify-code",
            body,
        );
        return responseData;
    }

    async requestPasswordReset(email: string): Promise<Object> {
        const body = { email: email };
        const responseData = await this.apiService.post<Object>(
            "auth/request-password-reset",
            body,
        );
        return responseData;
    }

    async updatePassword(body: object): Promise<Object> {
        const responseData = await this.apiService.post<Object>(
            "user/update/password",
            body,
        );
        return responseData;
    }

    async submitPasswordReset(values: Object): Promise<Object> {
        const responseData = await this.apiService.post<Object>(
            "auth/reset-password",
            values,
        );
        return responseData;
    }

    async attemptLogout(): Promise<{}> {
        const responseData = await this.apiService.post<Object>(
            "user/logout",
            null,
        );

        return responseData;
    }
}

export default AuthAPI;
