import { Alert, MenuItem, Select, TextField } from "@mui/material";
import Typography from "../../components/Typography";
import Blob from "../../components/common/Blob";
import grid from "../../styles/partials/_grid.module.scss";
import { useState } from "react";
import Button from "../../components/common/Button";
import BusinessAPI from "../../api/business/business.client";

interface InviteProps {
    setup: {};
    business_id: string;
}

export default function Invite(props: InviteProps) {
    const [isError, setIsError] = useState("");
    const [isSuccess, setIsSuccess] = useState("");
    const [siteSetup, setSiteSetup] = useState([]);
    const [email, setEmail] = useState("");
    const [firstName, setName] = useState("");
    const businessAPI = new BusinessAPI();
    const updateSiteInfo = (site_id: string, role: string) => {
        const updatedSites = {
            ...siteSetup,
            [site_id]: role,
        };

        setSiteSetup(updatedSites);
    };

    const submitForm = () => {
        setIsError("");
        setIsSuccess("");
        const body = {
            sites: siteSetup,
            email: email,
            first_name: firstName,
            business_id: props.business_id,
        };

        businessAPI
            .sendInvite(body)
            .then((response) => {
                setIsSuccess(response.message);
            })
            .catch((err) => {
                setIsError(err.message);
            })
            .finally(() => {
                document.getElementById("scrollable").scrollTo(0, 0);
            });
    };

    return (
        <div className={grid.fakeForm}>
            {isSuccess ? (
                <Alert severity="success" style={{ marginBottom: "8px" }}>
                    {isSuccess}
                </Alert>
            ) : (
                ""
            )}
            {isError ? (
                <Alert severity="error" style={{ marginBottom: "8px" }}>
                    {isError}
                </Alert>
            ) : (
                ""
            )}
            <Blob title={"Who are you inviting?"} size={"full"}>
                <Typography variant="small" className={["mt-8"]}>
                    Enter the info for the team member you're inviting:
                </Typography>

                <div className={grid.row}>
                    <TextField
                        required
                        id="first_name"
                        name="first_name"
                        label="First name"
                        fullWidth={true}
                        style={{ marginTop: "16px", marginBottom: "16px" }}
                        onChange={function (e) {
                            setName(e.target.value);
                        }}
                    />
                    <TextField
                        required
                        fullWidth={true}
                        id="email_address"
                        name="email_address"
                        type="email"
                        label="Email Address"
                        style={{ marginTop: "16px", marginBottom: "16px" }}
                        onChange={function (e) {
                            setEmail(e.target.value);
                        }}
                    />
                </div>
            </Blob>
            <Blob title={"Inviting someone to your team"} size={"full"}>
                <Typography variant="small" className={["mt-8"]}>
                    There's 3 levels when inviting a member of staff to your
                    team:
                    <ul>
                        <li>
                            <strong>Team Member:</strong> Someone who can view
                            shifts in the site's they're invited to.
                        </li>
                        <li>
                            <strong>Manager:</strong> Someone who can
                            create/edit shifts in all site's they're invited to.
                        </li>
                        <li>
                            <strong>Owner:</strong> Someone who can edit
                            business information & someone who can create/edit
                            all sites and all of the benefits for other roles.
                        </li>
                    </ul>
                </Typography>
                <Typography variant="small" className={["gutter"]}>
                    All roles and permissions are set per site basis.
                </Typography>
            </Blob>
            <Blob title={"Site access"} size={"full"}>
                {props.setup.map((site) => {
                    return (
                        <div
                            className={[grid.row, grid.centreVertically].join(
                                " ",
                            )}
                            key={site.uuid}
                        >
                            <div style={{ width: "100%" }}>
                                <Typography variant="h4">
                                    {site.name}
                                </Typography>
                                <Typography variant="small">
                                    {site.address}
                                </Typography>
                            </div>
                            <Select
                                fullWidth={true}
                                id="role"
                                name="role"
                                type="email"
                                label="Level"
                                defaultValue="0"
                                style={{
                                    width: "300px",

                                    marginBottom: "16px",
                                }}
                                onChange={function (e) {
                                    updateSiteInfo(site.uuid, e.target.value);
                                }}
                            >
                                <MenuItem value="0">
                                    <strong style={{ color: "#cecece" }}>
                                        No Access
                                    </strong>
                                </MenuItem>
                                <MenuItem value="4">
                                    <span>Team Member</span>
                                </MenuItem>
                                <MenuItem value="8">
                                    <span>Manager</span>
                                </MenuItem>
                                <MenuItem value="10">
                                    <span>Owner</span>
                                </MenuItem>
                            </Select>
                        </div>
                    );
                })}
                <div className={grid.row}>
                    <Button type="hollow" href="/sites/">
                        Back to sites
                    </Button>
                    <Button style={{ marginLeft: "auto" }} onClick={submitForm}>
                        Invite {firstName} to your business
                    </Button>
                </div>
            </Blob>
        </div>
    );
}
