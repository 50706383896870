import ApiService from "./api.service";
import LSS from "../helpers/localStorageService";
class JobDescriptionAPI {
    static apiService = new ApiService();
    private static cacheKey = "jobDescriptionEndorsements";
    private static cacheTimeKey = "jobDescriptionEndorsementsTimestamp";
    private static cacheDuration = 10 * 60 * 1000; // 10 minutes in milliseconds

    static async create(data): Promise<Object> {
        const body = data;

        const responseData = await this.apiService.post<Object>(
            "job-descriptions/create",
            body,
        );
        return responseData;
    }

    static async offerJob(data): Promise<Object> {
        const body = data;

        const responseData = await this.apiService.post<Object>(
            "job-descriptions/offer-job",
            body,
        );
        return responseData;
    }

    static async closeJob(job_uuid): Promise<Object> {
        const body = { jd_uuid: job_uuid };

        const responseData = await this.apiService.post<Object>(
            "job-descriptions/close-job",
            body,
        );

        return responseData;
    }

    static async rejectCandidate(data): Promise<Object> {
        const body = data;

        const responseData = await this.apiService.post<Object>(
            "job-descriptions/reject-candidate",
            body,
        );
        return responseData;
    }

    static async get(job_uuid): Promise<Object> {
        const currentTime = Date.now();
        const storedTime = localStorage.getItem("jd_" + job_uuid);
        const storedData = localStorage.getItem("jdc_" + job_uuid);

        if (
            storedTime &&
            storedData &&
            currentTime - parseInt(storedTime, 10) < this.cacheDuration
        ) {
            return JSON.parse(storedData);
        } else {
            const body = {
                business_uuid: LSS().get("business_uuid"),
                uuid: job_uuid,
            };
            const responseData = await this.apiService.get<Object>(
                "job-descriptions/get/" + job_uuid,
                body,
            );
            localStorage.setItem(this.cacheKey, JSON.stringify(responseData));
            localStorage.setItem(this.cacheTimeKey, currentTime.toString());

            return responseData;
        }
    }

    static async getEndorsements(): Promise<object> {
        const currentTime = Date.now();
        const storedTime = localStorage.getItem("endorsements_time");
        const storedData = localStorage.getItem("endorsements");

        if (
            storedTime &&
            storedData &&
            currentTime - parseInt(storedTime, 10) < this.cacheDuration
        ) {
            return JSON.parse(storedData);
        } else {
            const responseData = await this.apiService.get<object>(
                "job-descriptions/get-endorsements/",
                {},
            );

            localStorage.setItem("endorsements", JSON.stringify(responseData));
            localStorage.setItem("endorsements_time", currentTime.toString());

            return responseData;
        }
    }

    static async getOffer(oid, uid): Promise<object> {
        const responseData = await this.apiService.post<object>(
            "job-descriptions/get-offer/",
            { offer_uuid: oid, user_uuid: uid },
        );

        return responseData;
    }

    static async getAllOffers(jd_uuid): Promise<object> {
        const responseData = await this.apiService.post<object>(
            "job-descriptions/get-all-offers",
            { jd_uuid: jd_uuid },
        );

        return responseData;
    }

    static async list(filter): Promise<object> {
        const responseData = await this.apiService.post<object>(
            "job-descriptions/all",
            { business_uuid: LSS().get("business_uuid"), filter: filter },
        );

        return responseData;
    }

    static async accept(oid, uid): Promise<object> {
        const body = {
            offer_uuid: oid,
            user_uuid: uid,
        };
        const responseData = await this.apiService.post<object>(
            "job-descriptions/accept-job",
            body,
        );

        return responseData;
    }

    static async reject(oid, uid, feedback): Promise<object> {
        const body = {
            offer_uuid: oid,
            user_uuid: uid,
            feedback: feedback,
        };
        const responseData = await this.apiService.post<object>(
            "job-descriptions/reject-job",
            body,
        );

        return responseData;
    }
}
export default JobDescriptionAPI;
