import { createTheme } from "@mui/material/styles";
const themeOptions = createTheme({
    typography: {
        fontFamily: '"Poppins", "Helvetica", "Arial", sans-serif',
        fontSize: 14,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
    },
    components: {
        MuiTextField: {
            defaultProps: {
                InputLabelProps: {
                    shrink: true,
                },
            },
        },
    },
    palette: {
        primary: {
            main: "#019e3a",
        },
        secondary: {
            main: "#019e3a",
        },
    },
});
export default themeOptions;
