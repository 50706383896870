import React from "react";
import { createRoot } from "react-dom/client";
import App from "./containers/App";
import reportWebVitals from "./reportWebVitals";
import makeServer from "./helpers/makeServer";
import preload from "./helpers/preload";
import bootstrap from "./helpers/bootstrap";
import "react-big-calendar/lib/css/react-big-calendar.css";
import * as dotenv from "dotenv";
import {
    ThemeOptions,
    ThemeProvider,
    createMuiTheme,
    createTheme,
} from "@mui/material/styles";
import themeOptions from "./themeOptions";

const data = preload();
bootstrap(data);

// if (process.env.NODE_ENV === 'development') {
//   makeServer('development');
// }

const container = document.getElementById("slinger");
const root = createRoot(container!);

root.render(
    <React.StrictMode>
        <ThemeProvider theme={themeOptions}>
            <App />
        </ThemeProvider>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
