import dayjs from "dayjs";
import cookies from "./cookies";
import relativeTime from "dayjs/plugin/relativeTime";
import duration from "dayjs/plugin/duration";

import LSS from "./localStorageService";
export const isActivePage = (check) => {
    const fullPath =
        window.location.pathname +
        window.location.search +
        window.location.hash;
    return fullPath.indexOf(check) > -1;
};

export const priceFromPennies = (amount) => {
    return "£" + (amount / 100).toFixed(2);
};

/* Dates & Times */
export const isPast = (date, comparison_date = dayjs()) => {
    return dayjs(date).isBefore(dayjs(comparison_date));
};
export const isFuture = (date, comparison_date = dayjs()) => {
    return dayjs(date).isAfter(dayjs(comparison_date));
};

dayjs.extend(relativeTime);
dayjs.extend(duration);
export const getTimeDifference = (date1: Date, date2: Date): string => {
    const d1 = dayjs(date1);
    const d2 = dayjs(date2);

    const years = d1.diff(d2, "year");
    const months = d1.diff(d2, "month");
    const weeks = d1.diff(d2, "week");

    if (Math.abs(years) > 0) {
        return d1.from(d2);
    } else if (Math.abs(months) > 0) {
        return d1.from(d2);
    } else if (Math.abs(weeks) > 0) {
        return d1.from(d2);
    } else {
        return d1.from(d2);
    }
};

export const getYearsAndMonthsDifference = (
    date1: Date,
    date2: Date,
): string => {
    const d1 = dayjs(date1);
    const d2 = dayjs(date2);

    const totalMonths = d1.diff(d2, "month");
    const years = Math.floor(totalMonths / 12);
    const months = totalMonths % 12;

    let result = "";
    if (years > 0) {
        result += `${years} year${years !== 1 ? "s" : ""}`;
    }
    if (months > 0) {
        if (result) result += ", ";
        result += `${months} month${months !== 1 ? "s" : ""}`;
    }

    return result || "0 months";
};
export const shiftDate = (date, longform = false) => {
    if (longform) {
        return dayjs(date).format("dddd D MMM");
    }
    return dayjs(date).format("DD/MM/YYYY");
};

export const getTime = (date) => {
    return dayjs(date).format("H:mm");
};

export const clearLocalStorage = () => {
    cookies.setCookie("bearer", "");
    cookies.setCookie("refresh", "");
    cookies.setCookie("active_business", "");
    LSS().remove("first_name");
    LSS().remove("last_name");
    LSS().remove("image_url");
    LSS().remove("business_name");
    LSS().remove("uuid");
    LSS().remove("business_id");
    LSS().remove("business_uuid");
    LSS().remove("profile_picture");
    LSS().remove("email_verified");
    LSS().remove("business_permissions");
    LSS().remove("business");
    LSS().remove("businesses");
};

export const updateBusinessData = (response) => {
    LSS().set("business", response.data);
    LSS().set("business_name", response.data.name);
    LSS().set("business_id", response.data.id);
    LSS().set("image_url", LSS().set("image_url", response.file));
    LSS().set("businesses", [response.data]);
};
export const setupSystemData = (data) => {
    cookies.setCookie("bearer", data.access_token);
    cookies.setCookie("refresh", data.refresh_token);
    cookies.setCookie("active_business", data.business.uuid);
    LSS().set("first_name", data.first_name);
    LSS().set("profile_picture", data.profile_picture);
    LSS().set("last_name", data.last_name);
    LSS().set("business_permissions", data.permissions);
    LSS().set("image_url", data.file);
    LSS().set("uuid", data.uuid);
    LSS().set("user_id", data.user_id);
    LSS().set("email", data.email);
    LSS().set("unread_notifications", data.unread_notifications);
    LSS().set("email_verified", data.email_verified);
    LSS().set("business_name", data.business.name);
    LSS().set("business_id", data.business.id);
    LSS().set("business_uuid", data.business.uuid);
    LSS().set("business", data.business);
    LSS().set("businesses", data.businesses);

    return true;
};

export const currentUserUUID = () => {
    return LSS().get("uuid");
};

// Check for actual sites are in London. Could potentially do that for users.
export const isWithinLondon = (
    latitude: number,
    longitude: number,
    maxDistanceMiles = 50,
) => {
    // Define the coordinates of London (e.g., London Eye)
    const londonLat = 51.5033;
    const londonLon = -0.1195;

    // Radius of the Earth in miles
    const earthRadiusMiles = 3958.8; // Mean radius

    // Convert latitude and longitude from degrees to radians
    const lat1: number = (latitude * Math.PI) / 180;
    const lon1: number = (longitude * Math.PI) / 180;
    const lat2: number = (londonLat * Math.PI) / 180;
    const lon2: number = (londonLon * Math.PI) / 180;

    // Haversine formula
    const dlon: number = lon2 - lon1;
    const dlat: number = lat2 - lat1;
    const a: number =
        Math.sin(dlat / 2) ** 2 +
        Math.cos(lat1) * Math.cos(lat2) * Math.sin(dlon / 2) ** 2;
    const c: number = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance: number = earthRadiusMiles * c;

    // Check if the distance is within the specified maximum distance
    return distance <= maxDistanceMiles;
};

export const allowedAccess = () => {
    if (!LSS().get("uuid")) {
        // Force login.
        clearLocalStorage();
        window.location.href = "/login";
        return;
    }
    if (!LSS().get("email_verified")) {
        // Force email validation if they're trying to sneak around.
        window.location.href = "/business/verify-email?to_dashboard=true";
    }
    if (!LSS().get("business").name) {
        window.location.href = "/business/setup";
    }
    return;
};

export const canUser = (action) => {
    return LSS().get("business_permissions")[action];
};

export const intelligentTime = (shift_date, start_time, end_time) => {
    const start_hours = dayjs(start_time).hour();
    const start_mins = dayjs(start_time).minute();
    const start_datetime = dayjs(shift_date)
        .hour(start_hours)
        .minute(start_mins);

    const end_hours = dayjs(end_time).hour();
    const end_mins = dayjs(end_time).minute();
    let end_datetime = dayjs(shift_date).hour(end_hours).minute(end_mins);

    // Check if end time is before start time, indicating a next day shift
    if (end_datetime.isBefore(start_datetime)) {
        end_datetime = end_datetime.add(1, "day"); // Add a day to the end_datetime
    }

    return [start_datetime, end_datetime];
};

export const humanReadAbleTimeAway = (timestamp) => {
    const now = dayjs();
    const eventTime = dayjs(timestamp);
    const diffHours = eventTime.diff(now, "hour");

    // Decide the format based on the difference in hours
    if (diffHours < 0) {
        return `This shift has passed`;
    } else if (diffHours < 24) {
        // If the event is less than 24 hours away
        return `Starts in ${diffHours} hours`;
    } else {
        // Calculate the difference in days
        const diffDays = eventTime.diff(now, "day");
        return `Starts in ${diffDays} days`;
    }
};
