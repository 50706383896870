import { useEffect, useMemo, useState } from "react";

import Loading from "../../common/Loading";
import style from "./ShiftList.module.scss";
import Blob from "../../common/Blob";
import ShiftListItem from "./ShiftListItem";
import dayjs from "dayjs";
import Typography from "../../Typography";
import Button from "../../common/Button";

interface ShiftListPropsInterface {
    shifts: Shift[];
}

function ShiftList(props: ShiftListPropsInterface) {
    const [isLoading, setIsLoading] = useState(true);
    const groupedShifts = useMemo(() => {
        const groups = new Map();

        props.shifts.forEach((shift) => {
            const date = dayjs(shift.start_time)
                .hour(0)
                .minute(0)
                .format("YYYY-MM-DD");
            console.log(date);
            if (!groups.has(date)) {
                groups.set(date, []);
            }
            groups.get(date).push(shift);
        });
        return Array.from(groups.entries());
    }, [props.shifts]);

    const load = () => {
        setIsLoading(false);
    };
    useEffect(() => {
        load();
    }, []);

    if (isLoading) {
        return <Loading />;
    }
    if (groupedShifts.length == 0) {
        return (
            <Blob size={"full"} padded_inner={false}>
                <div className={style.shift_list}>
                    <div style={{ textAlign: "center", padding: "16px 0" }}>
                        <Typography variant="small" className={["mb-16"]}>
                            No shifts upcoming - why not create one!
                        </Typography>
                        <Button href="/shifts/create">
                            Create New Shift +
                        </Button>
                    </div>
                </div>
            </Blob>
        );
    }
    return (
        <div className={style.shift_list}>
            {groupedShifts.map(([date, shifts]) => (
                <div key={date}>
                    <span className={style.date_title}>
                        {dayjs(date).format("DD/MM/YYYY")}
                    </span>
                    {shifts.map((shift) => (
                        <ShiftListItem
                            key={shift.id}
                            shift={shift}
                            withDate={false}
                            withWage={false}
                        />
                    ))}
                </div>
            ))}
        </div>
    );
}

ShiftList.defaultProps = {
    shifts: [],
};

export default ShiftList;
