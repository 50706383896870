/* eslint-disable unused-imports/no-unused-vars */
import { useRecoilState } from "recoil";
import BigBoard from "../../../components/JobDescription/BigBoard";
import Typography from "../../../components/Typography";
import Blob from "../../../components/common/Blob";
import grid from "../../../styles/partials/_grid.module.scss";
import { jobCreationState } from "../../../state/atoms/jobCreationAtom";
import style from "./RoundUp.module.scss";
import JobDescriptionAPI from "../../../api/jobDescriptions.service";
import { useEffect, useState } from "react";
import { priceFromPennies } from "../../../helpers/general";
import Pill from "../../../components/common/Pill";
import SitesAPI from "../../../api/sites/sites.client";
import getOneSite from "../../../api/sites/getOne";
import getAllRoles from "../../../api/roles/getAllRoles";
import EndorsementAPI from "../../../api/endorsement.service";
function JERoundUp() {
    // eslint-disable-next-line unused-imports/no-unused-vars
    const [jobData, setJobData] = useRecoilState(jobCreationState);

    const [isLoading, setIsLoading] = useState(false);
    const [endorsementGroups, setEndorsementGroups] = useState<any[]>([]);
    const [site, setSite] = useState([]);
    const [role, setRole] = useState([]);
    const [openSections, setOpenSections] = useState<{
        [key: string]: boolean;
    }>({});

    useEffect(() => {
        getOneSite(jobData.site_id).then((response) => {
            setSite(response.site);
        });
        getAllRoles().then((response) => {
            const role = response.jobRoles.find(
                (r) => r.id === jobData.role_id,
            );
            setRole(role);
        });
        EndorsementAPI.getGrouped()
            .then((response) => {
                setEndorsementGroups(response);
                // Initialize open sections state
                const initialOpenSections = response.reduce(
                    (acc: any, group: any) => {
                        acc[group.type] = true; // Set default open/close state
                        return acc;
                    },
                    {},
                );
                setOpenSections(initialOpenSections);
            })
            .catch((error) => {
                console.error("Error fetching endorsement groups:", error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    function getMatchingNamesInSection(data: any[], ids: number[]): string[] {
        return data
            .filter((item) => ids.includes(item.value))
            .map((item) => item.label);
    }

    return (
        <>
            <Blob
                title="Confirm the details"
                size={"full"}
                padded_inner={false}
            >
                <div className={grid.form_inner}>
                    <div
                        className={grid.form_header}
                        style={{ marginBottom: "0" }}
                    >
                        <Typography variant="small">
                            Check over the details you've entered and click
                            {jobData.uuid ? " Save" : " Create"}. From there you
                            will be able to attach this job description to your
                            future trial shifts!
                        </Typography>
                    </div>
                </div>
            </Blob>

            <Blob title="Job Description" size={"full"} padded_inner={false}>
                <div className={grid.form_inner}>
                    <div className={grid.row}>
                        <div className={grid.quarter}>
                            <label>Site</label>
                            {site.name ?? "Fetching..."}
                        </div>
                        <div className={grid.quarter}>
                            <label>Role</label>
                            {role.name ?? "Fetching..."}
                        </div>
                        <div className={grid.quarter}>
                            <label>Salary</label>
                            {priceFromPennies(jobData.wage * 100)} (
                            {jobData.wage_type})
                        </div>
                        <div className={grid.quarter}>
                            <label>Start Date</label>
                            {jobData.start_date.format("dddd DD MMMM")}
                        </div>
                    </div>

                    <div className={grid.full}>
                        <div>
                            <label>Description</label>
                            <div
                                style={{
                                    width: "100%",
                                    wordBreak: "break-word",
                                }}
                            >
                                <Typography variant="p" className={["mt-0"]}>
                                    {jobData.description}
                                </Typography>
                            </div>
                        </div>
                    </div>
                </div>
            </Blob>
            <div className={grid.row}>
                <div className={grid.half}>
                    <Blob
                        size="full"
                        title="Screening Questions"
                        padded_inner={false}
                    >
                        <div className={grid.form_inner}>
                            {jobData.screening_questions.map((question, i) => {
                                return (
                                    <div key={i} className={style.job_question}>
                                        <label>Ques. {i + 1}</label>{" "}
                                        {question.question}
                                    </div>
                                );
                            })}
                        </div>
                    </Blob>
                </div>
                <div className={grid.half}>
                    <Blob size="full" title="Endorsements" padded_inner={false}>
                        <div className={grid.form_inner}>
                            {isLoading ? (
                                <p>Loading...</p>
                            ) : Array.isArray(endorsementGroups) &&
                              endorsementGroups.length > 0 ? (
                                endorsementGroups.map((group) => {
                                    // Filter selected endorsements
                                    const selectedEndorsements =
                                        group.endorsements.filter(
                                            (endorsement: any) =>
                                                jobData.endorsements.includes(
                                                    endorsement.id,
                                                ),
                                        );

                                    return (
                                        <div
                                            key={group.type}
                                            className={style.groupType}
                                        >
                                            <div
                                                className={style.header}
                                                onClick={() =>
                                                    toggleSection(group.type)
                                                }
                                            >
                                                <div>
                                                    <h3>{group.title}</h3>
                                                </div>
                                            </div>
                                            {openSections[group.type] && (
                                                <div className={style.content}>
                                                    <div
                                                        className={
                                                            style.pill_list
                                                        }
                                                    >
                                                        {selectedEndorsements.length >
                                                        0 ? (
                                                            selectedEndorsements.map(
                                                                (
                                                                    endorsement: any,
                                                                ) => (
                                                                    <Pill
                                                                        key={
                                                                            endorsement.id
                                                                        }
                                                                        label={
                                                                            endorsement.emoji +
                                                                            " " +
                                                                            endorsement.name
                                                                        }
                                                                    />
                                                                ),
                                                            )
                                                        ) : (
                                                            <small>
                                                                None selected
                                                            </small>
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    );
                                })
                            ) : (
                                ""
                            )}
                        </div>
                    </Blob>
                </div>
            </div>
        </>
    );
}

export default JERoundUp;
