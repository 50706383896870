/* eslint-disable @typescript-eslint/no-unused-vars */
import { useRecoilState } from "recoil";
import RoleSelector from "../../../components/Role/RoleSelector";
import SiteSelector from "../../../components/Site/SiteSelector";
import Typography from "../../../components/Typography";
import Blob from "../../../components/common/Blob";
import grid from "../../../styles/partials/_grid.module.scss";
import { jobCreationState } from "../../../state/atoms/jobCreationAtom";
import ButtonList from "../../../components/common/ButtonsList";
import { DatePicker } from "@mui/x-date-pickers";
import { InputAdornment, MenuItem, Select, TextField } from "@mui/material";
import dayjs from "dayjs";

function JESalary() {
    const [jobData, setJobData] = useRecoilState(jobCreationState);

    const handleWageChange = (wage) => {
        setJobData((prevJobData) => ({
            ...prevJobData,
            wage,
        }));
    };
    const handleWageTypeChange = (wage_type) => {
        setJobData((prevJobData) => ({
            ...prevJobData,
            wage_type,
        }));
    };

    return (
        <>
            <div className={grid.row}>
                <div className={grid.full}>
                    <Blob title="Salary" size={"full"} padded_inner={false}>
                        <div className={grid.form_inner}>
                            <div className={grid.form_header}>
                                <Typography variant="small">
                                    What is the likely salary or hourly rate for
                                    this role. This information is presented to
                                    slingers when they apply.
                                </Typography>
                            </div>
                            <label>Wage</label>
                            <div className={grid.row}>
                                <div className={grid.half}>
                                    <div className={grid.row}>
                                        <div className={grid.half}>
                                            <TextField
                                                onKeyDown={(e) => {
                                                    if (
                                                        e.key === "e" ||
                                                        e.key === "E" ||
                                                        e.key === "-" ||
                                                        e.key === "+"
                                                    ) {
                                                        e.preventDefault();
                                                    }
                                                }}
                                                onChange={function (e) {
                                                    if (e.target.value < 0) {
                                                        e.target.value = 0.0;
                                                    }
                                                    handleWageChange(
                                                        e.target.value,
                                                    );
                                                }}
                                                onBlur={function (e) {
                                                    console.log(e);
                                                    handleWageChange(
                                                        e.target.value,
                                                    );
                                                }}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            £
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                type="number"
                                                defaultValue={jobData.wage}
                                                inputProps={{
                                                    inputMode: "numeric",
                                                    pattern: "[0-9]*",
                                                }}
                                            />
                                        </div>
                                        <div className={grid.half}>
                                            <Select
                                                id="slinger_number"
                                                style={{ width: "100%" }}
                                                defaultValue={jobData.wage_type}
                                                disabled={jobData.wage <= 0}
                                                onChange={function (e) {
                                                    handleWageTypeChange(
                                                        e.target.value,
                                                    );
                                                }}
                                            >
                                                <MenuItem disabled={true}>
                                                    Select Wage Period
                                                </MenuItem>
                                                <MenuItem value={"Per hour"}>
                                                    Per Hour
                                                </MenuItem>
                                                <MenuItem value={"Annually"}>
                                                    Annually
                                                </MenuItem>
                                            </Select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Blob>
                </div>
            </div>
        </>
    );
}

export default JESalary;
