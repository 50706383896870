import { CSSProperties, useEffect, useState } from "react";
import style from "./AvatarUpload.module.scss";
import getImageURL from "../../../helpers/imageUtils";
interface AvatarUploadContainerPropsInterface {
    name: string;
    id: string;
    style: CSSProperties;
    text: string;
    accept: string;
    onChange: any;
    type: string;
    default: string;
}

AvatarUpload.defaultProps = {
    text: "Upload",
    name: "file_upload",
    onChange: null,
    id: "file_upload",
    style: {},
    type: "circle",
    accept: ".jpg, .jpeg, .png",
};
export default function AvatarUpload(
    props: AvatarUploadContainerPropsInterface,
) {
    const [selectedImage, setSelectedImage] = useState();

    const divID = props.id + "-avatar";
    useEffect(() => {
        if (selectedImage) {
            const tmp_path = URL.createObjectURL(selectedImage);
            document.getElementById(divID).style.background =
                "url('" + tmp_path + "')";
        } else {
            document.getElementById(divID).style.background =
                "url('" + getImageURL(props.default) + "')";
        }
    }, [selectedImage, props.default]);

    let avatar_type = style.circle;
    if (props.type == "square") {
        avatar_type = style.square;
    }

    return (
        <div style={{ maxWidth: "200px" }}>
            {selectedImage ? (
                <div
                    id={divID}
                    className={[style.image, avatar_type].join(" ")}
                    style={{ backgroundImage: props.default }}
                ></div>
            ) : (
                <div
                    id={divID}
                    className={[style.image, avatar_type].join(" ")}
                    style={{}}
                ></div>
            )}

            <label htmlFor={props.id} className={style.uploadButton}>
                <input
                    id={props.id}
                    type="file"
                    name={props.name}
                    accept={props.accept}
                    style={{
                        right: "0%",
                        bottom: "0%",
                        display: "none",
                    }}
                    onChange={(event) => {
                        setSelectedImage(event.target.files[0]);
                        props.onChange(event.target.files[0], props.name);
                    }}
                />
                <span>{props.text}</span>
            </label>
        </div>
    );
}
