import ApiService from "../api.service";
import LSS from "../../helpers/localStorageService";
import dayjs from "dayjs";
class ShiftsAPI {
    // Make sure that ApiService is correctly instantiated.
    private apiService: ApiService;

    constructor() {
        this.apiService = new ApiService();
    }

    async cancelShift(shift_id): Promise<Object> {
        const body = { shift_id: shift_id };
        try {
            const responseData = await this.apiService.post<Object>(
                "shifts/cancel",
                body,
            );
            return responseData;
        } catch (error) {
            console.error("Error in post request:", error);
            throw error;
        }
    }

    async getHistory(
        start_date,
        end_date,
        role_ids,
        site_ids,
        page = 1,
    ): Promise<Object> {
        const body = {
            role_ids: role_ids,
            business_uuid: LSS().get("business_uuid"),
            sites: site_ids,
            start_date: dayjs(start_date)
                .startOf("day")
                .format("YYYY-MM-DD 00:00:00"),
            end_date: dayjs(end_date)
                .endOf("day")
                .format("YYYY-MM-DD 23:59:59"),
        };
        try {
            const responseData = await this.apiService.post<Object>(
                "shifts/history?" + page,
                body,
            );
            return responseData;
        } catch (error) {
            console.error("Error in post request:", error);
            throw error;
        }
    }

    async getAll(active_business): Promise<Object> {
        const body = { business_id: active_business };
        try {
            const responseData = await this.apiService.post<Object>(
                "shifts",
                body,
            );
            return responseData;
        } catch (error) {
            console.error("Error in post request:", error);
            throw error;
        }
    }

    async getUpcoming(body): Promise<Shift> {
        try {
            const responseData = await this.apiService.post<Object>(
                "shifts/get-upcoming",
                body,
            );
            return <Shift>responseData;
        } catch (error) {
            console.error("Error in post request:", error);
            throw error;
        }
    }

    async update(shiftData): Promise<Object> {
        const body = {
            id: shiftData.id,
            tasks: shiftData.tasks,
            staff_meal: shiftData.staff_meal,
            break: shiftData.break,
            trial_shift: shiftData.trial_shift,
        };
        try {
            const responseData = await this.apiService.post<Object>(
                "shifts/update",
                body,
            );
            return <Shift>responseData;
        } catch (error) {
            console.error("Error in post request:", error);
            throw error;
        }
    }

    async getShift(shift_id): Promise<Shift> {
        const body = {
            shift_id: shift_id,
        };
        try {
            const responseData = await this.apiService.post<Object>(
                "shifts/get",
                body,
            );
            return <Shift>responseData;
        } catch (error) {
            console.error("Error in post request:", error);
            throw error;
        }
    }

    async requestNewSlinger(shift_uuid, reason): Promise<Shift> {
        const body = {
            shift_id: shift_uuid,
            reason: reason,
        };
        try {
            const responseData = await this.apiService.post<Object>(
                "shifts/refresh-slinger",
                body,
            );
            return <Shift>responseData;
        } catch (error) {
            console.error("Error in post request:", error);
            throw error;
        }
    }

    async createShift(
        shiftData,
        payment_id,
        attachment_id,
        subscription = false,
    ): Promise<Object> {
        try {
            const shiftCleanData = this.splitShiftInfo(
                shiftData,
                payment_id,
                attachment_id,
                subscription,
            );

            const responseData = await this.apiService.upload<Object>(
                "shifts/create",
                shiftCleanData,
            );
            return responseData;
        } catch (error) {
            console.error("Error in post request:", error);
            throw error;
        }
    }

    async convertPayload(shift, job_uuid): Promise<Object> {
        const body = {
            job_uuid: job_uuid,
            site_id: shift.site_id,
            role_id: shift.role_id,
            shifts: shift.shifts,
            tasks: shift.tasks,
            staff_meal: shift.staff_meal,
            trial_shift: shift.trial_shift,
            break: shift.break,
            notes: shift.notes,
        };
        try {
            const responseData = await this.apiService.post<Object>(
                "shifts/convert-payload",
                body,
            );
            return responseData;
        } catch (error) {
            console.error("Error in post request:", error);
            throw error;
        }
    }

    /**
     * Processes and structures shift information for proper API consumption.
     *
     * @param {Object[]} shiftData - Array of shift data objects.
     * @param {string} payment_id - The payment ID to be associated with the shifts.
     * @param {string} attachment_id - The attachment ID to be associated with the shifts - either invoice ID or the subscription ID.
     * @param {boolean} subscription - Indicates if the shift is tied to a subscription - should be false if Invoice being used.
     * @returns {Object} The structured data ready for API submission.
     */
    private splitShiftInfo(
        shiftData,
        payment_id: string,
        attachment_id: string,
        subscription: boolean,
    ): Object {
        const cleanData = {
            shifts: [],
            attachment_id: attachment_id,
            subscription: subscription,
            payment_id: payment_id,
        };

        for (let i = 0; i < shiftData.length; i++) {
            const shift = {
                site_id: shiftData[i].site_id,
                per_hour_price: shiftData[i].per_hour_price,
                start_time: shiftData[i].start_time,
                end_time: shiftData[i].end_time,
                shift_date: shiftData[i].start_time,
                invoice_line_id: shiftData[i].wage_fee_line_id,
                total_price: shiftData[i].total_price,
                number_person: 1,
                role_id: shiftData[i].role_id,
                role_name: shiftData[i].role_name,
                notes: shiftData[i].notes,
                staff_meal: shiftData[i].staff_meal,
                favorite: "0",
                break: shiftData[i].break,
                tasks: shiftData[i].tasks,
                payment_id: payment_id,
                invoice_id: attachment_id,
                trial_shift: shiftData[i].trial_shift
                    ? shiftData[i].trial_shift
                    : 0,
            };
            cleanData.shifts.push(shift);
        }
        return cleanData;
    }
}

export default ShiftsAPI;
