import cookies from "../helpers/cookies";
let namespace: string | null = null;

export const DEFAULT_NAMESPACE = process.env.REACT_APP_API_HOST
    ? process.env.REACT_APP_API_HOST
    : "https://staging.slingerstaff.com/api";

export function setNamespace(value?: string): void {
    namespace = value || DEFAULT_NAMESPACE;
}

export function resetNamespace(): void {
    namespace = null;
}
export const getOptions = {
    method: "GET",
    headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${cookies.getCookie("bearer")}`,
    },
};

export const postOptions = {
    method: "POST",
    headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${cookies.getCookie("bearer")}`,
    },
    body: {},
};

export const multiPostOptions = {
    method: "POST",
    headers: {
        Accept: "application/json",
        Authorization: `Bearer ${cookies.getCookie("bearer")}`,
    },
    body: {},
};

export function url(path: string) {
    if (namespace === null) {
        throw new Error("API namespace has not been set.");
    }

    return `${namespace}/${path}`;
}
