import style from "./SiteCard.module.scss";
import Button from "../../common/Button";
import getImageURL from "../../../helpers/imageUtils";
import { Rating } from "@mui/material";
import Typography from "../../Typography";
import dayjs from "dayjs";
interface SiteCardPropsInterface {
    site: Site;
    edit: boolean;
    view: boolean;
}
function SiteCard(props: SiteCardPropsInterface) {
    const viewSite = function () {
        // Get single shift
        window.location.href = "/sites/view/" + props.site.uuid;
    };

    const background = "url(" + getImageURL(props.site.file1) + ")";
    return (
        <div className={style.site_card}>
            {props.view ? (
                <div className={style.float}>
                    <Button onClick={viewSite} type="micro">
                        Manage
                    </Button>
                </div>
            ) : (
                ""
            )}
            <div
                className={style.site_image}
                style={{ backgroundImage: background }}
            ></div>
            <div className={style.site_info}>
                <span className={style.site_name}>{props.site.name}</span>
                <span className={style.rating}>
                    <Rating
                        value={parseFloat(props.site.rating)}
                        readOnly
                    ></Rating>
                </span>
                <span className={style.address}>
                    <strong>Address: </strong>
                    {props.site.address_line1} {props.site.city}{" "}
                    {props.site.post_code}
                </span>
            </div>
        </div>
    );
}

SiteCard.defaultProps = {
    site: {},
    edit: false,
    view: false,
};

export default SiteCard;
