/* eslint-disable unused-imports/no-unused-vars */

import { Alert, Rating, Tooltip } from "@mui/material";
import Avatar from "../../common/Avatar";
import Pill from "../../common/Pill";
import style from "./Profile.module.scss";
import getImageURL from "../../../helpers/imageUtils";
import dayjs from "dayjs";
import {
    getTimeDifference,
    getYearsAndMonthsDifference,
} from "../../../helpers/general";
import Typography from "../../Typography";
import { isArray } from "util";

interface SlingerHistoryInterface {
    slinger: {};
}

function Profile(props: SlingerHistoryInterface) {
    return (
        <div className={style.profile}>
            <div className={style.header}>
                <div className={style.backdrop}>
                    <span className={style.favourite}>★</span>
                </div>
                <div className={style.content}>
                    <div
                        className={style.avatar}
                        style={{
                            backgroundImage:
                                'url("' +
                                getImageURL(props.slinger.profile_picture) +
                                '")',
                        }}
                    >
                        <svg width="400" height="400">
                            <rect width="400" height="400" />
                        </svg>
                    </div>
                    <div className={style.breakdown}>
                        <div className={style.headline}>
                            <h1>
                                {props.slinger.first_name}{" "}
                                {props.slinger.last_name}
                            </h1>
                            <small>Joined {props.slinger.joined_date}</small>
                            <div className={style.rating}>
                                <Rating
                                    size="medium"
                                    readOnly={true}
                                    value={props.slinger.rating}
                                ></Rating>{" "}
                                {props.slinger.shift_count > 0 ? (
                                    <i>
                                        ({props.slinger.shift_count} shifts
                                        completed)
                                    </i>
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>
                        <div className={style.accolades}>
                            {props.slinger.endorsements.map(
                                (endorsement, i) => {
                                    return (
                                        <Tooltip
                                            key={i}
                                            placement={"bottom"}
                                            arrow
                                            title={endorsement.description}
                                        >
                                            <span
                                                className={
                                                    style[endorsement.type]
                                                }
                                            >
                                                {endorsement.value}
                                                {endorsement.description ? (
                                                    <i>?</i>
                                                ) : (
                                                    ""
                                                )}
                                            </span>
                                        </Tooltip>
                                    );
                                },
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {props.slinger.question_answers.length > 0 ? (
                <div className={style.shift_specific}>
                    <div className={style.references}>
                        <h3>Interview Answers</h3>
                        <small className={style.subtext}>
                            The responses this slinger gave to your interview
                            questions for this t
                        </small>
                        <div className={style.question_list}>
                            <ul>
                                {Object.keys(
                                    props.slinger.question_answers,
                                ).map(function (id, i) {
                                    const qa =
                                        props.slinger.question_answers[id];

                                    return (
                                        <li className={style.question} key={i}>
                                            <span
                                                className={style.question_num}
                                            >
                                                Q{i + 1}
                                            </span>
                                            <div className={style.qcontent}>
                                                <span className={style.q}>
                                                    {qa.question}
                                                </span>
                                                <span className={style.answer}>
                                                    {qa.answer}
                                                </span>
                                            </div>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            ) : (
                ""
            )}
            <div className={style.system_specific}>
                <div className={style.references}>
                    <h3>References</h3>
                    <small className={style.subtext}>
                        Every slinger has the chance to submit references for
                        their previous work. These are sent to the business they
                        nominate for confirmation, and also validated by us
                        before being shown here
                    </small>
                    <ul>
                        {props.slinger.references.length ? (
                            props.slinger.references.map((reference) => {
                                return (
                                    <li key={reference.id}>
                                        <img
                                            className={style.avatar}
                                            src={
                                                "/assets/images/icons/" +
                                                reference.role.icon
                                            }
                                        />
                                        <div className={style.content}>
                                            <strong>
                                                {reference.role.name}
                                            </strong>
                                            <small>{reference.business}</small>
                                        </div>
                                        <div className={style.dates}>
                                            <strong>
                                                {getYearsAndMonthsDifference(
                                                    new Date(
                                                        reference.end_date,
                                                    ),
                                                    new Date(
                                                        reference.start_date,
                                                    ),
                                                )}{" "}
                                            </strong>
                                            <small>
                                                {dayjs(
                                                    reference.start_date,
                                                ).format("MMM YYYY")}{" "}
                                                -{" "}
                                                {dayjs(
                                                    reference.end_date,
                                                ).format("MMM YYYY")}
                                            </small>
                                        </div>
                                        <div className={style.validation}>
                                            <Tooltip
                                                placement={"bottom"}
                                                arrow
                                                title={
                                                    reference.slinger_approved
                                                        ? "We've checked this reference ourselves and have confirmed it's validity"
                                                        : "A business has confirmed this reference on the slinger's behalf"
                                                }
                                            >
                                                <span className={style.green}>
                                                    {reference.slinger_approved
                                                        ? "Slinger Approved"
                                                        : "Business Approved"}
                                                </span>
                                            </Tooltip>
                                        </div>
                                    </li>
                                );
                            })
                        ) : (
                            <Alert severity="info">
                                <Typography variant="small">
                                    We are still checking{" "}
                                    {props.slinger.first_name}
                                    's references, but we've completed a video
                                    interview with this slinger to ensure they
                                    are eligible to be on our platform and apply
                                    for relevant roles such as this one.
                                </Typography>
                            </Alert>
                        )}
                    </ul>
                </div>
                <div className={style.references}>
                    <h3>Shift History</h3>
                    <small className={style.subtext}>
                        We show up to the last 5 shifts that this slinger has
                        completed so you can see their most recent work.
                    </small>
                    <ul>
                        {props.slinger.shifts.length ? (
                            props.slinger.shifts.map((shift) => {
                                return (
                                    <li key={shift.uuid}>
                                        <Avatar
                                            size="small"
                                            image={getImageURL(
                                                shift.site.file1,
                                            )}
                                        />
                                        <div className={style.content}>
                                            <strong>{shift.role.name}</strong>
                                            <small>{shift.site.name}</small>
                                        </div>
                                        <div className={style.dates}>
                                            <Rating
                                                value={
                                                    shift.rating?.total_rating
                                                }
                                                readOnly
                                            ></Rating>
                                            <small className={style.smallest}>
                                                {getTimeDifference(
                                                    new Date(shift.start_date),
                                                    new Date(),
                                                )}
                                            </small>
                                        </div>
                                        <div className={style.validation}>
                                            <Tooltip
                                                placement={"bottom"}
                                                arrow
                                                title={
                                                    "We've checked this shift top to bottom, confirmed the hours and signed off on the rating!"
                                                }
                                            >
                                                <span className={style.green}>
                                                    Verified
                                                </span>
                                            </Tooltip>
                                        </div>
                                    </li>
                                );
                            })
                        ) : (
                            <Alert severity="success">
                                <Typography variant="small">
                                    This is {props.slinger.first_name}'s first
                                    job on our platform! Rest assured - they've
                                    been through our rigourous background
                                    checks.
                                </Typography>
                            </Alert>
                        )}
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Profile;
