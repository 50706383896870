import { useEffect } from "react";
import UpdatePasswordForm from "../../forms/UpdatePassword";
import SlingerContainer from "../SlingerContainer";
import UpdatePictureForm from "../../forms/UpdatePicture";

interface UserDetailsUpdateContainerPropsInterface {
    onResetScroll: () => void;
    step: number;
}

// Load Container with all the relevant pieces for the page component to load
export default function UserDetailsUpdateContainer(
    props: UserDetailsUpdateContainerPropsInterface,
) {
    useEffect(() => {
        document.title = "Slinger Business | Profile";
    }, []);
    if (props.step == 1) {
        return (
            <SlingerContainer title="Business" type="registration">
                <UpdatePasswordForm />
            </SlingerContainer>
        );
    } else if (props.step == 2) {
        return (
            <SlingerContainer title="Business" type="registration">
                <UpdatePictureForm />
            </SlingerContainer>
        );
    }
}
